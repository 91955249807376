import React from "react";
import { connect } from "react-redux";
import {
  Tabs,
  Icon,
  List,
  // Collapse,Message
  Button,
  Accordion,
  ListView,
  Flex
} from "@geetemp/gee-ui-mobile";
import "./infointroduction.scss";
import DynamicDetail from "./dynamicdetail.js";
import { Route } from "react-router-dom";
import infointroductionModal from "store/reducers/infointroductionModel";
// import userModel from "store/reducers/userModel";
import ClampLine from "components/clamp-line.js";
import * as infoSevice from "services/infointroductionService";
import CustomIcon from "components/custom-icon/custom-icon";
import { openwindow } from "utils/util";
import { StickyContainer, Sticky } from "react-sticky";
import HomeTopHeader from "pages/home/home-top-header";
import BottomFiexdPanel from "components/bottom-fied-panel/bottom-fied-panel";

@connect(
  ({ infointroductionModal, user }) => {
    return { ...infointroductionModal, user };
  },
  {
    ...infointroductionModal.actions
  }
)
export default class InfoIntroduction extends React.PureComponent {
  switchTab = val => {
    const { setUpdateState } = this.props;
    this.props.history.push(val.key);
    //获取动态列表
    if (val.key === "/infointroduction/dynamic") {
      const { getDynamicList, statusParam } = this.props;
      getDynamicList(statusParam);
    } else {
      //恢复初始值
      setUpdateState({
        filed: "statusParam",
        value: { page: 1, pages: 10 }
      });
    }
  };
  componentWillUnmount() {
    this.props.setUpdateState({
      filed: "statusParam",
      value: { page: 1, pages: 10 }
    });
  }

  render() {
    const { location, history, user } = this.props;
    const tabsInfo = [
      { title: "即派简介", key: "/infointroduction" },
      { title: "即派动态", key: "/infointroduction/dynamic" },
      { title: "加入即派", key: "/infointroduction/join" },
      { title: "常见问题", key: "/infointroduction/question" }
    ];
    let { pathname } = location;
    let activekey =
      pathname.indexOf("/infointroduction/dynamicdetail") != -1
        ? "/infointroduction/dynamic"
        : pathname;

    return (
      <StickyContainer className="info-introduction">
        <HomeTopHeader history={history} user={user} nosticky />
        <div className="head">
          <div className="bg-img-1" />
        </div>
        <div className="container">
          <div className="tab">
            <Tabs page={activekey} onChange={this.switchTab} tabs={tabsInfo} />
            <div className="tab-name">
              <Route path="/infointroduction" exact component={Introduction} />
              <Route path="/infointroduction/dynamic" component={Dynamic} />
              <Route path="/infointroduction/join" component={Join} />
              <Route path="/infointroduction/question" component={Question} />
            </div>
          </div>
          <div className="company-name">
            ©2014-2016科锐数字科技（苏州）有限公司 <br />
            苏ICP备14059286号-2
          </div>
          <BottomFiexdPanel history={this.props.history} />
        </div>
      </StickyContainer>
    );
  }
}

export class Introduction extends React.Component {
  render() {
    return (
      <div className="introduction">
        <div className="title">即派简介</div>
        <div className="title-el">company introduction</div>
        <p>
          即派隶属于科锐数字科技（苏州）有限公司，是中国首家登陆A股的人力资源服务企业——科锐国际的全资子公司，基于科锐国际22年的行业积淀，以技术驱动人力资本在组织中的变革，帮助企业降本增效，提升人才管理能力和组织弹性。
        </p>
        <p>
          截止目前，即派业务已经辐射全国，已覆盖上海，北京，深圳，广州，苏州，南京，宁波，西安，长春，成都等多个城市区域。
        </p>
        <p>
          即派——高效的青年白领灵活用工共享平台，即约即面，即约即派。采用大数据和智能推荐技术，搭建泛灵活用工，供需调度和SNS求职的三维平台，聚集优质企业200万家，白领求职人群500万，提供人才招聘，岗位外包，离岸招聘，求职就业等一站式灵活用工服务，为企业和职场人赋能。
        </p>
        <div className="title mt">联系我们</div>
        <div className="title-el">Contact us</div>
        <div className="contact">
          <div className="contact-info">
            <CustomIcon type="icondianhua-copy" />
            客服电话：400-600-6181
          </div>
          <div className="contact-info">
            <CustomIcon type="iconhezuowoshouyouyihexiehepinggongyingxianxing-copy" />
            商务合作：vickyhe@careerintlinc.com
          </div>
        </div>
        <div className="title" style={{ paddingTop: "10px" }}>
          公司地址
        </div>
        <div className="title-el">company address</div>
        <div className="address">
          <img src={require("assets/imgs/address-img.jpg")} />
        </div>
      </div>
    );
  }
}

function MyBody(props) {
  return <div>{props.children}</div>;
}
@connect(
  ({ infointroductionModal, user }) => {
    return { ...infointroductionModal, user };
  },
  {
    ...infointroductionModal.actions
  }
)
export class Dynamic extends React.Component {
  static namespace = "infointroductionModal";
  constructor(props) {
    super(props);
    const getRowData = (dataBlob, sectionID, rowID) => {
      return dataBlob[sectionID][rowID];
    };
    const dataSource = new ListView.DataSource({
      getRowData,
      rowHasChanged: (row1, row2) => {
        return true;
      }
    });
    this.state = {
      dataSource,
      isLoading: true
    };
  }
  // static async getInitialProps() {
  //   //这个请求要放在最顶层来请求
  //   const res = await infoSevice.dynamicList({ page: 1, pages: 10 });
  //   if (res.code === 0) return { dataList: res.data };
  // }
  // let dataSource = new ListView.DataSource({
  //   getRowData,
  //   rowHasChanged: (row1, row2) => row1 !== row2,
  // });

  componentWillReceiveProps(nextProps) {
    if (this.props.dataList.list !== nextProps.dataList.list) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(
          nextProps.dataList.list
        ),
        isLoading: true
      });
    }
  }
  componentDidMount() {
    const { getDynamicList, statusParam } = this.props;
    getDynamicList(statusParam).then(() => {
      this.setState({ isLoading: false });
    });
  }

  goDetail = id => {
    this.props.history.push("/dynamicdetail/" + id);
  };
  /**
   * @description 下一页
   */
  onEndReached = event => {
    const {
      setUpdateState,
      statusParam,
      getDynamicList,
      dataList = {}
    } = this.props;
    const { page = {} } = dataList;
    const hasMore = page.page !== page.countpage;
    if (!hasMore) return;
    if (this.loading) return;
    this.loading = true;
    let param = {
      ...statusParam,
      page: page.page + 1
    };
    setUpdateState({
      filed: "statusParam",
      value: param
    });
    //触发分页nextPage
    getDynamicList(param, "nextPage").then(res => {
      this.loading = false;
    });
  };

  render() {
    const { dataList = {} } = this.props;
    const { page = {} } = dataList;
    const { dataSource, isLoading } = this.state;
    const { list } = dataList;
    const row = (rowData, sectionID, rowID) => {
      return (
        <DynamicList
          key={rowID}
          goDetail={this.goDetail.bind(this, rowData.id)}
          item={rowData}
        />
      );
    };
    return (
      <div className="dynamic">
        <div className="title mt">最新动态</div>
        <div className="title-el">Latest News</div>
        {list && list.length !== 0 ? (
          <ListView
            ref={el => (this.lv = el)}
            dataSource={dataSource || {}}
            renderBodyComponent={() => <MyBody />}
            renderRow={row}
            useBodyScroll
            renderFooter={() => (
              <Flex align="center" justify="center">
                {page.countpage != page.page && isLoading ? (
                  <Icon type="loading" />
                ) : null}
              </Flex>
            )}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            scrollEventThrottle={20}
            onEndReachedThreshold={1000}
          />
        ) : (
          <Flex className="no-data-view" align="center" justify="center">
            <div style={{ marginTop: "50px", color: "#b3b3b3" }}>
              {isLoading && (
                <div>
                  <Icon type="loading" />
                </div>
              )}
              {list && list.length === 0 && !isLoading && (
                <div>
                  <div style={{ textAlign: "center" }}>
                    抱歉，还没有动态消息！
                  </div>
                </div>
              )}
            </div>
          </Flex>
        )}
      </div>
    );
  }
}
//即派动态列表组件
export class DynamicList extends React.Component {
  go = id => {
    this.props.goDetail && this.props.goDetail(id);
  };
  render() {
    const { item = {} } = this.props;
    return (
      <div className="card">
        <div className="item-box clear" onClick={this.go.bind(this, item.id)}>
          <div className="item-left">
            <img src={item.cover} />
          </div>
          <div className="item-right">
            <div className="title">
              <span className="active-name">
                [<span className="type">{item.type_name}</span>]
              </span>
              {item.title}
              {/* <ClampLine className="clamp" text={ item.title} buttons={false} lines={2} /> */}
            </div>
            <div className="time clear">
              <div className="left ellipsis">
                {item.date} {item.source_name}
              </div>
              <div className="right">
                <i className="icon icon-page-view" />
                {item.hits}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class Join extends React.Component {
  callback = e => {
    // console.log(e);
  };
  render() {
    const data = [
      {
        position: "AE 招聘顾问",
        time: "长期",
        cityAll: "全国",
        city: "北京、深圳、苏州、长春、西安、咸阳、成都、杭州、天津",
        position_des:
          "利用创新的业务模式和技术手段，积累候选人资源；\n 与客户方HR以及用人经理进行职位详细沟通；\n通过各种渠道搜寻合适人选，进行简历筛选、评估，电话面试候选人，撰写简历报告进行推荐，与客户沟通并确定面试流程及面试时间；\n与客户及候选人进行有效并及时的沟通，确保整体流程进展顺利；\n进行候选人薪资谈判，薪资报价，候选人入职后工作状态跟进，客户反馈跟进；\n现场员工关系管理，与SSC中心顾问协作，例如合同签署，入职培训，劳动风险管理等。",
        ask_des:
          "本科及以上学历；\n1年或以上招聘模块工作经验，熟悉财务，人事，行政类型职位的优先；\n较强的推动执行力，积极主动的工作意识；\n较快的学习能力，适应能力和出色的沟通能力；\n自主性强，能够承受较大的工作压力，善于自我管理；\n良好的团队合作精神。",
        email: "maxgu@careerintlinc.com"
      },
      {
        position: "财务专业人才运营顾问",
        time: "长期",
        cityAll: "苏州",
        city: "苏州",
        email: "984455255@qq.com",
        position_des:
          "负责辖区内的企业财务职位的专业人员信息收集\n	在公司平台上上线有求职需求的候选人\n第一时间掌握辖区内的企业财务专业人员变动；从而获取商机。\n	通晓辖区内的企业财务专业人员的各类需求，\n	为辖区内的企业财务专业人员提供职业生涯规划咨询，培训方案咨询，就业咨询等等。\n定期为辖区内的财务专业人员举办培训，论坛会，联谊会等社交活动。",
        ask_des:
          "1-3年工作经验。本科以上学历。\n对人力资源服务领域有兴趣，接受业务型的工作方式。\n能够承受一定压力，可以接受不定期短时加班，勇于挑战和坚\n性格开朗，口齿伶俐，积极主动，待人热情\n接受其他行业人才转型。",
        email: "maxgu@careerintlinc.com"
      },
      {
        position: "管培生",
        time: "长期",
        cityAll: "苏州市",
        city: "苏州市",
        position_des:
          "完成一年的轮岗，完成各岗位的业绩要求（比如业务和BD岗位）。\n管培生的培训是直接走向管理层的，需能体现一定的自我约束能力及合理 的时间管理能力，在轮岗过程中须完成培训任务、团队的领导任务及各项考核。",
        ask_des:
          "全日制统招本科及以上19届应届生，品行端正；\n 英语专长者优先，无专业限制；\n一定的自我约束能力及合理的时间管理能力；\n思维敏捷，具有良好的责任意识和团队协作精神；\n乐于接受挑战，勇于挑战收入极限，敢于规划未来，有创业经验者优先；\n自我驱动，简单阳光，有良好的谈判能力及创意能力；\n主动学习，逻辑思维清晰，组织协调表达能力强，有团队影响力；\n良好的心理素质和工作习惯，合理管控情绪及良好的抗压能力，吃苦耐劳",
        email: "maxgu@careerintlinc.com"
      },
      {
        position: "猎头顾问助理实习生",
        time: "长期",
        cityAll: "全国",
        city: "杭州、苏州、成都、西安、北京、长春、深圳、武汉均可",
        position_des:
          "在Leader的指导下，分析客户需求、制定人才寻访计划并付诸实施\n通过各种途径及方法（人才库，网站，Cold Call等）进行候选人访寻工作\n协助顾问对候选人进行初步筛选和评估\n候选人面试安排及协调，协助顾问跟踪项目进度\n针对目标行业，协助团队拓展新的客户",
        ask_des:
          "全日制本科及以上学历，热爱猎头行业，愿意在人力资源领域长期发展\n有较强的分析判断能力，较强的语言表达能力，学习能力佳\n目标意识明确，结果导向，主动性强，具有强烈的进取心\n善于与人沟通，有较好的客户服务意识\n每周保证4天及以上出勤，实习周期至少保证三个月及以上",
        email: "maxgu@careerintlinc.com"
      },
      {
        position: "HR助理实习生",
        time: "长期",
        cityAll: "全国",
        city: "苏州、成都、西安",
        position_des:
          "协助招聘经理进行简历筛选、职位刷新等 \n维护更新人力数据 \n与候选人进行专业的电话沟通 \n理解职位要求，能够从各种渠道访寻到候选人并进行电话面试，渠道包括但不限于社交媒体/网站/猎头/推荐等\n协调内部团队和候选人的面试安排，面试反馈",
        ask_des:
          "本科及以上学历，专业不限；\n主动沟通意识强，具备较强的沟通协调能力、抗压能力、学习能力；\n性格阳光open，良好的团队合作意识，且对于自我的成长有较高的要求；\n对人力资源领域有归属感，意愿在人力资源领域深耕发展。\n每周保证3天及以上出勤，实习周期至少保证三个月及以上。",
        email: "maxgu@careerintlinc.com"
      },
      {
        position: "运营实习生",
        time: "长期",
        cityAll: "全国",
        city: "苏州、西安",
        position_des:
          "收集整理素材，编辑公众号和微博文案，负责公众号日常推送及推广\n负责社群运营、文案编辑、活动策划推广等工作，提高社群用户数量及粘性\n根据运营数据分析并发现工作问题，并优化推广方法和计划\n完成上级领导交代的其它工作；",
        ask_des:
          " 对互联网运营工作感兴趣，看待问题有自己的见解，有创意；\n学习能力、责任心、执行力和表达能力强；\n对写作有兴趣，有一定的文案创作能力\n每周实习时间4-5天,能稳定实习3个月以上者优先；",
        email: "vickyhe@careerintlinc.com"
      },
      {
        position: "HR共享服务中心主管",
        time: "长期",
        cityAll: "天津市",
        city: "天津市",
        position_des:
          "负责团队搭建、培训和文化建设\n负责团队在薪资、福利、员工关系的日常运行及管理\n负责制作员工的工资、奖金、福利及各类津贴的薪资管理工作\n负责完成每月人力成本分析与各类薪酬福利统计报表工资统计报表\n负责持续改进政策、制度、流程，从用户体验角度优化完善\n上级安排的工作",
        ask_des:
          "本科及以上学历，人力资源、社会保障相关专业\n	具有3年-5年薪资福利、员工关系工作经验，有人力资源共享服务中心工作经验优先\n	熟悉劳动法、社保、公积金等相关政策法规和操作流程\n	基本能运用英语并精通使用office（Excel, Word）办公软件\n	良好的职业习惯，沟通协调能力优秀，具有较强的逻辑思维能力、数据处理能力、组织协调能力、冲突和突发事件处理能力",
        email: "wendyping@careerintlinc.com"
      }
    ];

    return (
      <div className="join">
        <div className="title">加入即派</div>
        <div className="title-el">Join Us</div>
        <div className="panel-box">
          <div className="title-panel clear">
            <div className="name">职位名称</div>
            <div className="name">招聘时间</div>
            <div className="name">工作地点</div>
          </div>
          <Accordion
            accordion
            openAnimation={{}}
            className="my-accordion"
            onChange={this.callback}
          >
            {data &&
              data.map((item, index) => (
                <Accordion.Panel
                  key={index}
                  header={
                    <div className="title-panel clear">
                      <div className="name">{item.position}</div>
                      <div className="name">{item.time}</div>
                      <div className="name">{item.cityAll}</div>
                    </div>
                  }
                >
                  <div className="show">
                    <div className="position-info">
                      <div className="row-box">
                        <div className="mb40 clear">
                          <div className="info-name">
                            岗位名称：{item.position}
                          </div>
                        </div>
                        <div className="mb40 clear">
                          <div className="info-name">工作地点：{item.city}</div>
                        </div>
                        <div className="mb40 clear">
                          <div className="info-name">
                            联系邮箱：{item.email}
                          </div>
                        </div>
                      </div>
                      <div className="mb40 clear">
                        <div className="info-name">岗位描述：</div>
                        <div className="info-des">
                          {item.position_des &&
                            item.position_des
                              .split("\n")
                              .map((info, indexPosition) => (
                                <p key={indexPosition}>
                                  {indexPosition + 1}.{info}
                                </p>
                              ))}
                        </div>
                      </div>
                      <div className="mb40 clear">
                        <div className="info-name">任职要求：</div>
                        <div className="info-des">
                          {item.ask_des &&
                            item.ask_des.split("\n").map((info, indexAsk) => (
                              <p key={indexAsk}>
                                {indexAsk + 1}.{info}
                              </p>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Accordion.Panel>
              ))}
          </Accordion>
        </div>
      </div>
    );
  }
}

export class Question extends React.Component {
  callback = e => {
    console.log("666");
  };
  render() {
    const data = [
      {
        title: "为什么要进行企业认证？",
        des:
          "通过认证的企业可以获得即派一对一专属顾问服务，是享受更多专业级的企业服务的必要条件。"
      },
      {
        title: "如何进行认证企业认证？",
        des:
          "进入【个人中心】，然后点击【企业信息】，上传企业营业执照，企业名称须与营业执照上公司名称保持一致，点击【提交】。 \n提交认证成功，即派客服将对您上传执照进行审核。审核通过后，您在通知栏会收到认证成功通知，就表明认证成功啦~如果审核不通过，您需核对营业执照，重新上传即可。"
      },
      {
        title: "注册时收到不短信验证码怎么办？",
        des:
          " 手机短信拦截中找一下是否被屏蔽， 如果还是没有解决，重新获取验证码一次。以上均不能解决请联系即派客服。"
      },
      {
        title: "无法注册/登录即派怎么办？",
        des:
          "建议更换浏览器（谷歌、火狐等），如依然不能解决，请联系即派客服解决。"
      },
      {
        title: "即派可以免费发布招聘信息吗？",
        des:
          "可以，企业注册即派后进入【个人中心】，编辑职位信息，发布到招聘小程序【即派优职】，分享并推广职位二维码，精准触达候选人社交圈，快速吸引候选人投递。"
      },
      {
        title: "即派使用流程是什么？",
        des:
          "注册即派账户后-----预约人才或提交需求---专属顾问2h内沟通详细需求--确认需求后24h内推荐人才并安排面试。"
      },
      {
        title: "使用即派你能做什么？",
        des:
          "招聘信息免费发布：借助招聘小程序【即派优职】，免费发布职位并推广，精准触达候选人社交圈，员工内推一键转发，提升招聘效率。人才招聘：流动率大、基础性、通用性岗位人才访寻和招聘，快速帮你找到最想要的人。员工管理：帮你搞定员工入离职管理、日常考勤、薪资发放、社保公积金代缴等事务性管理工作，解放HR的双手。风险控制：即派全方位承担雇主责任，用工风险即派全承担，帮你最大程度降低风险。"
      },
      {
        title: "为什么那么多企业选择即派？",
        des:
          "交付更快：即派打破传统的从企业-候选人的访寻模式，率先建立庞大、真实、活跃的灵活用人才库，候选人接受实习、兼职、短期和中长期的工作机会，均能快速面试到岗，企业在线挑人才，24h面试，1周内快速到岗。风险更低：即派提供一站式全风险外包服务，包含人员招聘、员工管理、薪资社保代发代缴、风险控制等，让企业真正做到“零风险”用工。性价比更高：相比其它平台的灵活用工服务，即派在保证交付效率、服务质量的同时，服务费用更低，帮助企业合理控制用工成本。"
      }
    ];
    return (
      <div className="question">
        <div className="title mt">常见问题</div>
        <div className="title-el">FAQ</div>
        <div className="panel-box">
          <Accordion accordion openAnimation={{}} onChange={this.callback}>
            {data &&
              data.map((item, index) => (
                <Accordion.Panel header={item.title} key={index}>
                  <div className="des-box">
                    {item.des &&
                      item.des.split("\n").map((info, index) => (
                        <p key={index}>
                          {index + 1}.{info}
                        </p>
                      ))}
                  </div>
                </Accordion.Panel>
              ))}
          </Accordion>
        </div>
      </div>
    );
  }
}
