import React, { Component } from "react";
import { connect } from "react-redux";
import "./send-serve.scss";
import {
  Button,
  Flex,
  List,
  InputItem,
  Picker,
  TextareaItem,
  Toast
} from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import RadioBtn from "components/radio-btn/radio-btn";
import myPositionModel from "store/reducers/myPositionModel";
import BottomPanel from "pages/talentPool/BottomPanel";
import { convertCity } from "utils/util";
import * as positionService from "services/positionService";
import { getUserInfo } from "services/userService";
import CustomIcon from "components/custom-icon/custom-icon";

const Item = List.Item;

@connect(
  ({ myPositionModel }) => {
    return { ...myPositionModel };
  },
  {
    ...myPositionModel.actions
  }
)
export default class SendServe extends Component {
  componentDidMount() {
    getUserInfo();
    this.props.getCommonData(["cities"]);
    this.props.getTime(["index_estimated_time"]);
  }

  /**
   * @description 提交派服务
   */
  submit = val => {
    const { sendPositionParams = {} } = this.props;
    if (this.noFilled(sendPositionParams)) {
      const { city = [] } = sendPositionParams;
      const [province_id, city_id, region_id] = city;
      let dataParams = {
        ...sendPositionParams,
        province_id,
        city_id,
        region_id
      };
      delete dataParams.city;

      positionService.positionAdd(dataParams).then(res => {
        if (res.code === 0) {
          const { params = {} } = this.props.match;
          this.props.history.push("/position");
        } else {
          console.log(res.transformError, "pp", res);
          Toast.info(res.transformError, 2);
        }
      });
    }
  };
  onChangeVal = (name, val) => {
    console.log(name, "checkbox", val);
    const { setUpdateState, sendPositionParams } = this.props;
    setUpdateState({
      filed: "sendPositionParams",
      value: {
        ...sendPositionParams,
        [name]: Array.isArray(val) && name === "service_type" ? val[0] : val
      }
    });
  };
  noFilled = nameObj => {
    const { post_name, require_num, jd_desc, city = [] } = nameObj;
    const [province_id] = city;
    if (!post_name) {
      Toast.info("请输入职位名称", 2);
      return;
    }
    if (!require_num) {
      Toast.info("请输入招聘人数", 2);
      return;
    }
    if (!province_id) {
      Toast.info("工作城市至少选到市", 2);
      return;
    }
    if (!jd_desc) {
      Toast.info("请输入职位描述", 2);
      return;
    }
    return true;
  };
  serveOption = [
    { value: 3, label: "岗位外包" },
    { value: 2, label: "离岸招聘" },
    { value: 1, label: "人才招聘" }
  ];
  render() {
    //   const{getFieldProps}=this.props.form;
    const {
      sendPositionParams = {},
      commonData = {},
      index_estimated_time
    } = this.props;
    return (
      <div className="send-position-box">
        <TopNavBar title="派服务" />
        <div className="form-box">
          <List>
            <InputItem
              value={sendPositionParams.post_name}
              onChange={this.onChangeVal.bind(this, "post_name")}
              placeholder="请输入"
            >
              <span className="required">*</span>职位名称
            </InputItem>
            <InputItem
              type="money"
              value={sendPositionParams.require_num}
              onChange={this.onChangeVal.bind(this, "require_num")}
              placeholder="请输入"
            >
              <span className="required">*</span>招聘人数
            </InputItem>
            <div className="city-sel">
              <Picker
                value={sendPositionParams.city}
                data={convertCity((commonData.cities || {}).cityList)}
                title="工作城市"
                onOk={this.onChangeVal.bind(this, "city")}
                extra={
                  <div className="do-sel">
                    请选择
                    <CustomIcon className="iconright" type="iconright" />
                  </div>
                }
              >
                <List.Item arrow="horizontal">
                  <span className="required">*</span>工作城市
                </List.Item>
              </Picker>
            </div>
            <InputItem
              value={sendPositionParams.address}
              onChange={this.onChangeVal.bind(this, "address")}
              placeholder="请输入"
            >
              工作地址
            </InputItem>
            <div className="city-sel">
              <Picker
                cols={1}
                value={[sendPositionParams.service_type]}
                data={this.serveOption}
                title="服务类型"
                extra={
                  <div className="do-sel">
                    请选择
                    <CustomIcon className="iconright" type="iconright" />
                  </div>
                }
                onOk={this.onChangeVal.bind(this, "service_type")}
              >
                <List.Item arrow="horizontal">
                  <span className="required">*</span>服务类型
                </List.Item>
              </Picker>
            </div>
            <div className="position-des">
              <Item>
                <div className="row">
                  <span className="required">*</span>职位描述
                </div>
              </Item>
            </div>
            <TextareaItem
              className="nobottom"
              value={sendPositionParams.jd_desc}
              onChange={this.onChangeVal.bind(this, "jd_desc")}
              placeholder="请详细填写职位描述..."
            />
            <div className="position-des">
              <Item>
                <div className="row">
                  任职要求
                </div>
              </Item>
            </div>
            <TextareaItem
              value={sendPositionParams.requirements}
              onChange={this.onChangeVal.bind(this, "requirements")}
              placeholder="请详细填写任职要求..."
            />
          </List>
        </div>
        <div className="predict">预计{index_estimated_time}推荐候选人</div>
        <BottomPanel onClick={this.submit}>提交需求</BottomPanel>
      </div>
    );
  }
}
