import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./position.scss";
import { ListView, Flex, Menu, Icon } from "@geetemp/gee-ui-mobile";
import { StickyContainer, Sticky } from "react-sticky";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import TopNavBar from "components/top-navbar/navbar";
import myPositionModel from "store/reducers/myPositionModel";
import PositionCard from "./position-card";
const menuFilter = [
  {
    value: "0",
    label: "全部职位"
  },
  {
    value: "1",
    label: "待核实"
  },
  {
    value: "2",
    label: "进行中"
  },
  {
    value: "3",
    label: "已暂停"
  },
  {
    value: "4",
    label: "已完成"
  },
  {
    value: "5",
    label: "已取消"
  }
];



@connect(
  ({ myPositionModel }) => {
    return {
      position: myPositionModel,
      positionList: myPositionModel.positionList,
      positionPagination: myPositionModel.positionPagination
    };
  },
  {
    ...myPositionModel.actions
  }
)
class MyPosition extends Component {
  state = {
    menuShow: false,
    menuSel: ["0"],
    menuLabel: "全部职位",
    dataSource: new ListView.DataSource({
      getRowData: (dataBlob, sectionID, rowID) => {
        return dataBlob[sectionID][rowID];
      },
      rowHasChanged: (row1, row2) => {
        return row1 !== row2;
      }
    }),
    hasNext: false
  };

  componentDidMount() {
    const { getPositionList, match } = this.props;
    let currentType = match.params.type ? match.params.type : "0";
    let positionType = sessionStorage.getItem("positionType");
    if (!match.params.type && positionType) {
      currentType = positionType;
    }
    getPositionList({ status: currentType }, true);
    this.setState({
      menuSel: [currentType + ""],
      menuLabel: menuFilter.filter(item => item.value === currentType)[0].label
    });
  }

  componentDidUpdate(prevProps) {
    const { positionList, positionPagination } = this.props;
    if (prevProps.positionList !== positionList) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(positionList)
      });
    }
    if (prevProps.positionPagination !== positionPagination) {
      this.setState({
        hasNext:
          positionPagination.page != positionPagination.countpage &&
          positionPagination.countpage != 0
      });
    }
  }

  menuShow = () => {
    this.setState({
      menuShow: !this.state.menuShow
    });
  };

  onMaskClick = () => {
    this.setState({
      menuShow: false
    });
  };

  menuChange = item => {
    const { getPositionList } = this.props;
    let currentMenuLabel = "";
    menuFilter.forEach(menu => {
      if (menu.value === item[0]) {
        currentMenuLabel = menu.label;
      }
    });
    this.loading = true;
    this.setState({
      menuShow: false,
      menuSel: item,
      menuLabel: currentMenuLabel
    });
    sessionStorage.setItem("positionType", item[0]);
    getPositionList({ status: item[0] }, true).then(res => {
      this.loading = false;
    });
  };

  onEndReached = event => {
    const { getPositionList, positionPagination } = this.props;
    const { hasNext, menuSel } = this.state;
    if (!hasNext) return;
    if (this.loading) return;
    this.loading = true;
    getPositionList({
      status: menuSel[0],
      page: positionPagination.page + 1
    }).then(res => {
      this.loading = false;
    });
  };


  render() {
    const {
      loginType,
      menuShow,
      menuSel,
      menuLabel,
      dataSource,
      hasNext
    } = this.state;
    const { history, positionList, positionPagination } = this.props;
    const row = (rowData, sectionID, rowID) => {
      console.log("row", rowData, sectionID, rowID);
      return <PositionCard positionData={rowData} history={history} />;
    };
    let MyBody = (props) => {
      return (
        <div className="am-list-body my-body">
          {props.children}
          {positionList && positionList.length === 0 && (
            <Flex className="no-data-view" align="center" justify="center">
              <div>
                <img src={require("assets/imgs/empty.png")} />
                <div style={{ textAlign: "center" }}>抱歉,您还没有发布职位！</div>
              </div>
            </Flex>
          )}
        </div>
      );
    }
    return (
      <div className="position-container">
        <div className="position-list">
          <StickyContainer>
            <ListView
              dataSource={dataSource}
              className="position-list-view"
              renderRow={row}
              renderHeader={() => (
                <Sticky>
                  {({ style }) => (
                    <TopNavBar
                      style={style}
                      className="topbar"
                      title={
                        <React.Fragment>
                          <div className="top-filter" onClick={this.menuShow}>
                            {menuLabel}
                            <CustomIcon type="icondown" />
                          </div>
                        </React.Fragment>
                      }
                    />
                  )}
                </Sticky>
              )}
              renderFooter={() => (hasNext ? <Icon type="loading" /> : null)}
              renderBodyComponent={() => <MyBody />}
              onEndReached={this.onEndReached}
            />
            {menuShow && (
              <Menu
                className="top-filter-menu"
                onChange={this.menuChange}
                data={menuFilter}
                value={menuSel}
                level={1}
              />
            )}
            {menuShow ? (
              <div className="menu-mask" onClick={this.onMaskClick} />
            ) : null}
          </StickyContainer>
        </div>
      </div>
    );
  }
}

export default MyPosition;
