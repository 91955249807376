import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./talent-card.scss";
import { Button, Flex, Menu, Accordion, Icon } from "@geetemp/gee-ui-mobile";
import ImgContainer from "components/img";

export default class TalentCard extends Component {
  onGo = () => {
    this.props.goDetail();
  };

  render() {
    const { data = {} } = this.props;
    let imgSrc = require("assets/imgs/man.jpg");
    if (data.pic) {
      imgSrc = data.pic;
    } else if (data.is_intern == 0) {
      imgSrc =
        data.sex == "男"
          ? require("assets/imgs/man.jpg")
          : require("assets/imgs/woman.jpg");
    } else if (data.is_intern == 1) {
      imgSrc =
        data.sex == "男"
          ? require("assets/imgs/student-man.jpg")
          : require("assets/imgs/student-woman.jpg");
    }

    // 基础信息1
    let baseFirsts = [];
    data.education && baseFirsts.push(data.education);
    if (data.is_intern == 0) {
      data.worktime &&
        baseFirsts.push(data.worktime ? data.worktime + "年经验" : "0年经验");
    } else {
      data.major && baseFirsts.push(data.major);
    }

    let baseSeconds = [];
    if (data.is_intern == 0) {
      data.salary_month_expect &&
        baseSeconds.push(
          data.salary_month_expect
            ? `${
                data.salary_month_expect == "面议"
                  ? "面议"
                  : "期望薪资" + data.salary_month_expect + "K/月"
              }`
            : ""
        );
    } else {
      data.graduation_time && baseSeconds.push(data.graduation_time + "毕业");
    }
    return (
      <div className="talent-card-container" onClick={this.onGo}>
        <div className="card-left">
          <ImgContainer src={imgSrc} />
        </div>
        <div className="card-right">
          <div className="name">
            {data.name} · {data.position}
          </div>
          {baseSeconds.join(" · ") != "" && (
            <div className="row">{baseSeconds.join(" · ")}</div>
          )}
          {baseFirsts.join(" · ") != "" && (
            <div className="row">{baseFirsts.join(" · ")}</div>
          )}
          <div className="status">
            {data.tag == "即约即面" && (
              <img src={require("assets/imgs/gee-jimian.png")} alt="" />
            )}
            {data.tag == "即约即派" && (
              <img src={require("assets/imgs/gee-jipai.png")} alt="" />
            )}
          </div>
        </div>
      </div>
    );
  }
}
