import React, { Component } from "react";
import { connect } from "react-redux";
import "./bottom-fied-panel.scss";
import { Button } from "@geetemp/gee-ui-mobile";
import { openwindow } from "utils/util";
import * as commonService from "services/commonService";
import ActivityModal from "components/modal/activityModal.js"
import CustomIcon from "components/custom-icon/custom-icon";

// 底部操作
@connect(({ user }) => {
  return { ...user };
})
export default class BottomFiexdPanel extends React.PureComponent {
  state = {
    info: {},
    activityVisible: false,
  };
  componentDidMount() {
    const {history} = this.props;
    commonService.getCommonDataWithoutCache(["new_msg"]).then(res => {
      if (res.code === 0) {
        this.setState({ info: { ...res.data } });
      }
    });
    let isOpenActive = sessionStorage.getItem("isOpenActive");
    if (history.location && history.location.pathname === "/" && isOpenActive !== "1") {
      this.setState({
        activityVisible: true,
      })
      sessionStorage.setItem("isOpenActive", "1")
    }
  }
  go = type => {
    const { history = {}, info = {} } = this.props;
    if (type === 1) {
      openwindow(
        "https://p.qiao.baidu.com/cps/chat?siteId=13265485&userId=25613354",
        800,
        500
      );
    }
    if (type === 2) {
      // if (info.id) {
      history.push("/center");
      // } else {
      //   history.push("/login?redirect=" + encodeURIComponent("/center"));
      // }
    }
    if (type === 3) {
      this.setState({activityVisible: true});
      // history.push("/sendserve");
    }
  };
  confirmCancel = () => {
    this.setState({
      activityVisible: false,
    })
  }
  render() {
    const { info = {}, activityVisible } = this.state;
    const { history } = this.props;
    return (
      <div className="bottom-fied-panel">
        <div className="bottom-panel">
          <div className="consult" onClick={this.go.bind(this, 1)}>
          <CustomIcon className="iconduihua-copy" type="iconduihua-copy" />
            <br />
            在线咨询
          </div>
          <div className="center" onClick={this.go.bind(this, 2)}>
            <CustomIcon className="iconwode-copy-copy" type="iconwode-copy-copy" />
            <br />
            个人中心
            {!!info.new_msg && <span className="badge">{info.new_msg}</span>}
          </div>
          <div className="send-position">
            <Button type="primary" onClick={this.go.bind(this, 3)}>
              派服务
            </Button>
          </div>
          <ActivityModal history={history} visible={activityVisible} onCancel={this.confirmCancel}/>
        </div>
      </div>
    );
  }
}
