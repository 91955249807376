import React, { Component } from "react";
import { connect } from "react-redux";
import "./introduce-detail.scss";
import { Flex } from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import HomeTopHeader from "pages/home/home-top-header";
import BottomFiexdPanel from "components/bottom-fied-panel/bottom-fied-panel";
import CollapseMenu from "components/collapse-menu/collapse-menu";

@connect(
  ({ user }) => {
    return { user };
  }
)
class IntroduceOffshore extends Component {
  render() {
    const { history, user } = this.props;
    return (
      <div className="introduce-detail-page">
        {/* <TopNavBar title="服务介绍" /> */}
        <HomeTopHeader history={history} user={user} nosticky/>
        <div className="detail-1-cross">
          <div className="title">离岸招聘</div>
          <div className="spliter"></div>
        </div>
        <div className="intro">
        针对临时性，大批量的用人需求，预算有限，招聘人手紧张等问题，通过“招聘专员”远程离岸的形式，支持客户方的招聘相关工作，帮助客户提升组织灵活性，降低用人风险和成本。
        </div>
        <div className="detail-2-cross"></div>
        <div className="content">
          <Flex className="title" align="center"><span className="line"></span>服务内容</Flex>
          <div className="content-detail">
          通过远程离岸形式为企业提供人才访寻，电话面试，信息分析，人才数据库盘活和渠道管理等招聘工作，帮助客户解决临时，大批量的弹性用工需求。
          </div>
        </div>
        <div className="detail-3-cross"></div>
        <div className="content service-value">
          <Flex className="title" align="center" justify="end"><span className="line"></span>服务价值</Flex>
          <div className="content-detail">
            <div className="content-detail-p content-detail-1">
              <span className="p-start">提高管理效率：</span>减轻人事部门在招聘方面的工作，聚焦到核心管理中去，提高企业的管理效率。
            </div>
            <div className="content-detail-p content-detail-2">
              <span className="p-start">缓解旺季招聘压力：</span>根据季节性招聘需求，200+离岸招聘专员随时用，解决临时性，大批量招聘需求。
            </div>
            <div className="content-detail-p content-detail-3">
              <span className="p-start">节约成本：</span>根据需求“按月租赁”人员，减少人员冗余，超高性价比，降低企业综合用工成本。
            </div>
            <div className="content-detail-p content-detail-4">
              <span className="p-start">提升组织灵活性：</span>灵活调整离岸招聘专员人数，人员随时调换,启停，快捷灵活
            </div>
          </div>
        </div>
        <div className="detail-4-cross"></div>
        <div className="content">
          <Flex className="title" align="center"><span className="line"></span>案例分析</Flex>
          <div className="content-detail">
            <div className="content-detail-p content-detail-1">
              <span className="p-start">挑战：</span>全球领先的AOI厂商，急需一批技术人员到岗。
            </div>
            <div className="content-detail-p content-detail-2">
              <span className="p-start">方案：</span>通过离岸招聘及岗位外包突破人员编制限制，提供外包雇员替代产期员工休假；了解客户需求及职位核心要素，从即派人才库配送符合客户需求的候选人
            </div>
            <div className="content-detail-p content-detail-3">
              <span className="p-start">成果：</span>平均入职25人/月，大大减轻客户方招聘经理的工作负荷，荣获2017年客户方颁发的最佳人力资源合作伙伴奖。
            </div>
          </div>
        </div>
        <CollapseMenu status="singleCode"/>
        <div className="icp">
          <div>©2014-2016科锐数字科技（苏州）有限公司 </div>
          <div>苏ICP备14059286号-2 </div>
          <div className="report">朝阳区人力资源与社会保障局 监督电话:57596212，65090445</div>
          <div>
            <a href="https://www.geetemp.com/geetemp/default/business-license.jpg">营业执照 </a>| 
            <a href="https://www.geetemp.com/geetemp/default/hrs-license.jpg"> 服务许可证名称320510190032</a>
          </div>
        </div>
        <BottomFiexdPanel history={this.props.history} />
      </div>
    );
  }
}

export default IntroduceOffshore;
