import React, { Component } from "react";
import { connect } from "react-redux";
import "./my-collect-order.scss";
import { ListView, Flex, Icon } from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import user from "store/reducers/userModel";

@connect(
  ({ user }) => {
    return { ...user };
  },
  {
    ...user.actions
  }
)
export default class SystemMessage extends Component {
  constructor(props) {
    super(props);
    const getRowData = (dataBlob, sectionID, rowID) => {
      return dataBlob[sectionID][rowID];
    };
    const dataSource = new ListView.DataSource({
      getRowData,
      rowHasChanged: (row1, row2) => {
        return true;
      }
    });
    this.state = {
      dataSource,
      isLoading: true
    };
  }
  componentDidMount() {
    this.props
      .getSystemMessage(this.props.systemLIstParam)
      .then(() => this.setState({ isLoading: false }));
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.systemList.list !== nextProps.systemList.list) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(
          nextProps.systemList.list
        ),
        isLoading: true
      });
    }
  }
  onEndReached = event => {
    const { setUpdateState, getSystemMessage, systemLIstParam ,systemList} = this.props;
    const { page = {} } = this.props.systemLIstParam;
    if (
      (systemList.page || {}).page >=
      (systemList.page || {}).countpage
    )
      return;
    const params = {
      ...systemLIstParam,
      page: page + 1
    };
    setUpdateState({
      filed: "systemLIstParam",
      value: params
    });
    getSystemMessage(params, "nextPage").then(() => {
      this.setState({ isLoading: false });
    });
  };
  /**
   * @description 去其他页面或外联
   * @param {string} patch  路径
   */
  goDetail = patch => {
    if (patch && patch !== "") {
      if (patch.indexOf("http") !== -1) {
        window.open(patch, "_blank");
        return;
      } else {
        this.props.history.push(patch);
      }
    }
  };
  componentWillUnmount() {
    const { setUpdateState } = this.props;
    setUpdateState({
      filed: "systemLIstParam",
      value: { page: 1, pages: 10 }
    });
  }
  render() {
    const { systemList = {} } = this.props;
    const { list, page = {} } = systemList;
    const { dataSource, isLoading } = this.state;
    const row = (rowData, sectionID, rowID) => {
      return (
        <div
          className="item-box"
          onClick={this.goDetail.bind(this, rowData.url)}
        >
          <div className="info">
            <div className="clear">
              <div className="title left">{rowData.type_name}</div>
              <div className=" right">{rowData.create_time_str}</div>
            </div>
            <div className="des">
              {rowData.title && `[${rowData.title}]`} {rowData.content}
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="system-message-box">
        <TopNavBar title="系统通知" />
        <div className="message-show">
          {list && list.length !== 0 ? (
            <ListView
              ref={el => (this.lv = el)}
              dataSource={dataSource}
              renderRow={row}
              useBodyScroll
              renderFooter={() => (
                <Flex align="center" justify="center">
                  {page.countpage !== page.page ? (
                    <Icon type="loading" />
                  ) : null}
                </Flex>
              )}
              scrollRenderAheadDistance={500}
              onEndReached={this.onEndReached}
              scrollEventThrottle={20}
              onEndReachedThreshold={10}
            />
          ) : (
            <Flex className="no-data-view" align="center" justify="center">
              <div style={{ marginTop: "100px", color: "#b3b3b3" }}>
                {isLoading && (
                  <div>
                    <Icon type="loading" />
                  </div>
                )}
                {list && list.length === 0 && !isLoading && (
                  <div>
                    <div style={{ textAlign: "center" }}>暂时没有消息哦~</div>
                  </div>
                )}
              </div>
            </Flex>
          )}
        </div>
      </div>
    );
  }
}
