import React, { Component } from "react";
import { connect } from "react-redux";
import "./my-collect-order.scss";
import {
  Button,
  Flex,
  List,
  InputItem,
  ImagePicker,
  ListView,
  Icon
} from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import myPositionModel from "store/reducers/myPositionModel";
import BottomPanel from "pages/talentPool/BottomPanel";
import TalentCard from "components/talent-card/talent-card";
import talentModel from "store/reducers/talentModel";

function MyBody(props) {
  return <div>{props.children}</div>;
}
@connect(
  ({ talentModel }) => {
    return { ...talentModel };
  },
  {
    ...talentModel.actions
  }
)
export default class MyCollectOrder extends Component {
  constructor(props) {
    super(props);
    const getRowData = (dataBlob, sectionID, rowID) => {
      return dataBlob[sectionID][rowID];
    };
    const dataSource = new ListView.DataSource({
      getRowData,
      rowHasChanged: (row1, row2) => {
        return true;
      }
    });
    this.state = {
      dataSource,
      isLoading: true,
      titleName: ""
    };
  }

  componentDidMount() {
    const name = { 1: "预约", 2: "收藏" };
    const { params = {} } = this.props.match;
    this.setState({ titleName: name[params.type] });
    this.getList(params.type); //初始化加载列表
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.collectOrderList.list !== nextProps.collectOrderList.list) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(
          nextProps.collectOrderList.list
        ),
        isLoading: true
      });
    }
    // if (
    //   (nextProps.collectOrderList.list || []).length === 0 ||
    //   !(nextProps.collectOrderList.page || {}).is_more
    // ) {
    //   this.setState({ isLoading: false });
    // }
  }
  /**
   * @description 获取列表数据
   * @param {object} keyword
   */
  getList = (type, nextPage) => {
    const { getCollectOrder, collectOrderParam = {} } = this.props;
    let param = {
      ...collectOrderParam,
      type: type === "1" ? "book" : "collection"
    };
    getCollectOrder(param, nextPage).then(() =>
      this.setState({ isLoading: false })
    );
  };
  /**
   * @description 去详情
   * @param {number} id
   */
  goDetail = id => {
    this.props.history.push("/resumedetail/" + id);
  };
  onEndReached = event => {
    const { params = {} } = this.props.match;
    const {
      setUpdateState,
      collectOrderParam,
      collectOrderList = {}
    } = this.props;
    const { _meta = {} } = collectOrderList;
    const { page = {} } = this.props.collectOrderParam;
    if (this.state.isLoading && _meta.pageCount >= _meta.currentPage) return;
    setUpdateState({
      filed: "collectOrderParam",
      value: { ...collectOrderParam, page: page + 1 }
    });
    this.setState({ isLoading: true });
    this.getList(params.type, "nextPage");
  };
  componentWillUnmount() {
    this.props.setUpdateState({
      filed: "collectOrderParam",
      value: { page: 1, "per-page": 10 }
    });
  }
  render() {
    const { personInfoParams = {}, collectOrderList } = this.props;
    const { list, _meta = {} } = collectOrderList;
    const { dataSource, isLoading } = this.state;
    const row = (rowData, sectionID, rowID) => {
      return (
        <TalentCard
          key={rowID}
          goDetail={this.goDetail.bind(this, rowData.id)}
          data={rowData}
        />
      );
    };
    return (
      <div className="my-collect-order-box">
        <TopNavBar title={`我的${this.state.titleName}人选`} />
        <div className="info-show">
          {list && list.length ? (
            <ListView
              dataSource={dataSource}
              renderBodyComponent={() => <MyBody />}
              renderRow={row}
              useBodyScroll
              renderFooter={() => (
                <Flex align="center" justify="center">
                  {_meta.pageCount != _meta.currentPage && isLoading ? (
                    <Icon type="loading" />
                  ) : null}
                </Flex>
              )}
              scrollRenderAheadDistance={500}
              scrollEventThrottle={20}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={10}
            />
          ) : (
            <Flex className="no-data-view" align="center" justify="center">
              <div style={{ marginTop: "100px", color: "#b3b3b3" }}>
                {isLoading && (
                  <div>
                    <Icon type="loading" />
                  </div>
                )}
                {list && list.length === 0 && !isLoading && (
                  <div>
                    <img src={require("assets/imgs/empty.png")} />
                    <div style={{ textAlign: "center" }}>暂无数据！</div>
                  </div>
                )}
              </div>
            </Flex>
          )}
        </div>
      </div>
    );
  }
}
