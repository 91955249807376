import Model from "../model";
import { Toast } from "@geetemp/gee-ui-mobile";
import dotProp from "dot-prop-immutable";
import * as positionService from "services/positionService";
import * as commonService from "services/commonService";

const history = process.env.BROWSER ? require("utils/history").default : null;

export default Model.getInstance(
  class extends Model {
    namespace = "myPositionModel";
    state = {
      createPositionParams: {
        work_type: 1,
        service_type: 3
      }, //创建职位参数
      sendPositionParams: {}, //派服务参数
      positionList: [],
      positionPagination: {},
      positionDetail: {},
      commonData: {},
      index_estimated_time: "", //预计时间
      positionProgress: [],
      positionProfiles: [],
      profilePagination: {},
      recommendProfiles: [],
      recommendPagination: {},
    };
    actions = {
      async getCommonData(dataName) {
        let commonData = await commonService.commonData(dataName);
        this.dispatch({
          type: "setUpdateState",
          payload: { filed: "commonData", value: commonData }
        });
      },
      async getTime(dataName) {
        let res = await commonService.getCommonDataWithoutCache(dataName);
        this.dispatch({
          type: "setUpdateState",
          payload: {
            filed: "index_estimated_time",
            value: (res.data||{}).index_estimated_time
          }
        });
      },
      async getPositionList(param, isOverride) {
        param.page = param.page || 1;
        param.pages = param.pages || 6;
        const res = await positionService.getPositions(param);
        if (res.code === 0) {
          this.dispatch({
            type: "updatePositionList",
            payload: res.data.list || [],
            isOverride: isOverride
          });
          this.dispatch({
            type: "setUpdateState",
            payload: {
              filed: "positionPagination",
              value: res.data.page || {}
            }
          });
        }
        return res;
      },
      async getPositionDetail(param) {
        const res = await positionService.getPositionDetail(param);
        if (res.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: {
              filed: "positionDetail",
              value: res.data || {}
            }
          });
        }
        return res;
      },
      async getPositionProgress(param) {
        const res = await positionService.getPositionProgress(param);
        if (res.code === 0) {
          if (res.data.status === 3) {
            res.data.progressNum = res.data.last_status + 1;
          } else if (res.data.status === 4 || res.data.status === 5) {
            res.data.progressNum = res.data.last_status + 2;
          } else {
            res.data.progressNum = res.data.status;
          }
          
          this.dispatch({
            type: "setUpdateState",
            payload: {
              filed: "positionProgress",
              value: res.data || [],
            }
          });
        }
        return res;
      },
      async getPositionProfiles(param, isOverride) {
        param.page = param.page || 1;
        param.pages = param.pages || 8;
        const res = await positionService.getPositionProfiles(param);
        if (res.code === 0) {
          this.dispatch({
            type: "updateProfiles",
            payload: res.data.list || {},
            isOverride: isOverride
          });
          this.dispatch({
            type: "setUpdateState",
            payload: {
              filed: "profilePagination",
              value: res.data.page || {},
            }
          });
        }
        return res;
      },
      async getRecommendProfiles(param, isOverride) {
        param.page = param.page || 1;
        param.pages = param.pages || 8;
        const res = await positionService.getPositionProfiles(param);
        if (res.code === 0) {
          this.dispatch({
            type: "updateRecommendProfiles",
            payload: res.data.list || {},
            isOverride: isOverride
          });
          this.dispatch({
            type: "setUpdateState",
            payload: {
              filed: "recommendPagination",
              value: res.data.page || {},
            }
          });
        }
        return res;
      },
      async stopPosition(param) {
        const res = await positionService.pauseJD(param);
        if (res.code === 0) {
          this.dispatch({
            type: "updateProgressPaused",
            payload: param.type
          });
        }
        return res;
      },
      async editPosition(param) {
        const res = await positionService.editJD(param);
        if (res.code === 0) {
          // this.dispatch({
          //   type: "updateProgressPaused",
          //   payload: param.type
          // });
        }
        return res;
      },
    };
    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      updatePositionList(state, { payload, isOverride }) {
        if (isOverride) {
          state.positionList = payload;
        } else {
          state.positionList = state.positionList.concat(payload);
        }
        return { ...state };
      },
      updateProfiles(state, { payload, isOverride }) {
        if (isOverride) {
          state.positionProfiles = payload;
        } else {
          state.positionProfiles = state.positionProfiles.concat(payload);
        }
        return { ...state };
      },
      updateRecommendProfiles(state, { payload, isOverride }) {
        if (isOverride) {
          state.recommendProfiles = payload;
        } else {
          state.recommendProfiles = state.recommendProfiles.concat(payload);
        }
        return { ...state };
      },
      updateProgressPaused(state, { payload }) {
        // state.positionProgress.can_paused = payload === 1 ? 2 : 1;
        return { ...state };
      },
    };
  }
);
