import React, { Component } from "react";
import "./feedback.scss";
import { Toast, TextareaItem, InputItem, Flex } from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import { feedback } from "services/commonService";
import {mobileReg, emailReg} from "utils/constant.js";
import underscore from "underscore";

class FeedBack extends Component {
  
  state = {
    feedbackParam: {}
  }

  onFieldChange = (field, val, obj) => {
    let stateObj = {};
    if (obj) {
      this.state[obj][field] = val;
    } else {
      stateObj[field] = val;
    }  
    this.setState({...this.state, ...stateObj});
  }

  submitFeedBack = () => {
    const {feedbackParam} = this.state;
    if (underscore.isEmpty(feedbackParam.content)) {
      Toast.info("请输入反馈意见", 1);
      return;
    }
    if (underscore.isEmpty(feedbackParam.contact)) {
      Toast.info("请输入联系方式", 1);
      return;
    }
    if (!mobileReg.test(feedbackParam.contact) && !emailReg.test(feedbackParam.contact)) {
      Toast.info("联系方式为手机号或邮箱", 1);
      return;
    }
    feedback(feedbackParam).then(res => {
      if (res.code === 0) {
        Toast.info("反馈已提交", 1);
        this.setState({
          feedbackParam: {}
        })
      } else {
        Toast.info(res.transformError, 1);
      }
    })
  }

  render() {
    const {feedbackParam} = this.state;
    return (
      <div className="feedback-page" direction="column">
        <TopNavBar className="" title="意见反馈" />
        <TextareaItem 
          value={feedbackParam.content}
          onChange={(val) => this.onFieldChange("content", val, "feedbackParam")}
          className="feedback" rows={10} placeholder="请输入您对即派服务体验有任何意见，或者使用中遇到任何问题..."/>
        <InputItem
          value={feedbackParam.contact}
          onChange={(val) => this.onFieldChange("contact", val, "feedbackParam")}
          className="contact" placeholder="请联系您的手机号码或邮箱">联系方式</InputItem>
        <div className="white-space"></div>
        <div className="submit-btn" onClick={this.submitFeedBack}>提交反馈</div>
      </div>
    );
  }
}

export default FeedBack;
