import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./position-edit.scss";
import {
  Menu,
  Flex,
  TextareaItem,
  Steps,
  InputItem,
  Toast,
  List,
  Picker,
  ActivityIndicator
} from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import TopNavBar from "components/top-navbar/navbar";
import userModel from "store/reducers/userModel";
import myPositionModel from "store/reducers/myPositionModel";
import { commonData } from "services/commonService";
import underscore from "underscore";

const Step = Steps.Step;

@connect(
  ({ myPositionModel }) => {
    return { ...myPositionModel };
  },
  {
    ...myPositionModel.actions
  }
)
class PositionDetail extends Component {
  state = {
    service_type: [],
    jd_work_type: [],
    jd_salary: [],
    jd_education: [],
    positions: [],
    jd_benefit: [],
    cities: [],
    menuShow: false,
    positionParam: {},
    benefit_label: [],
  };

  transformArray = source => {
    return source.map(item => {
      let desData = {
        value: item.id,
        label: item.name
      };
      if (item.list) desData["children"] = this.transformArray(item.list);
      return desData;
    });
  };
  transformChild = source => {
    return source.map(item => {
      let desData = {
        value: item.value,
        label: item.label
      };
      if (item.list) desData["children"] = this.transformChild(item.list);
      return desData;
    });
  };

  componentDidMount() {
    const { getPositionDetail, match } = this.props;    
    commonData([
      "service_type",
      "cities",
      "jd_work_type",
      "jd_work_time",
      "jd_salary",
      "jd_education",
      "positions",
      "jd_benefit"
    ]).then(res => {
      this.setState({
        service_type: res.service_type,
        jd_work_type: res.jd_work_type,
        jd_salary: res.jd_salary,
        jd_work_time: res.jd_work_time,
        jd_education: res.jd_education,
        jd_benefit: res.jd_benefit,
        positions: this.transformChild(res.positions),
        cities: this.transformArray(res.cities ? res.cities.cityList : [])
      });
    });
    getPositionDetail({jd_id: match.params.id}).then(res => {
      if (res.code === 0) {
        let positionDetail = res.data;
        this.onFieldChange("name", positionDetail.post_name, "positionParam");
        this.onFieldChange("benefits", positionDetail.benefit_ids, "positionParam");
        this.onFieldChange("benefit_label", positionDetail.benefits ? positionDetail.benefits.map(item => item.name) : "");

        this.onFieldChange("service_type", [positionDetail.service_type], "positionParam");
        this.onFieldChange("require_num", positionDetail.require_num ? positionDetail.require_num : "", "positionParam");
        this.onFieldChange("cities", [positionDetail.province_id + "", positionDetail.city_id + "", positionDetail.region_id + ""], "positionParam");
        this.onFieldChange("address", positionDetail.address, "positionParam");

        this.onFieldChange("work_type", [positionDetail.work_type], "positionParam");
        this.onFieldChange("position", [positionDetail.position_pid, positionDetail.position], "positionParam");
        this.onFieldChange("education", [positionDetail.education], "positionParam");
        this.onFieldChange("salary", [positionDetail.salary], "positionParam");
        this.onFieldChange("work_time", [positionDetail.work_time], "positionParam");
        this.onFieldChange("jd_desc", positionDetail.jd_desc, "positionParam");
        this.onFieldChange("requirements", positionDetail.requirements, "positionParam");
      } else if (res.code === 5112) {
        // Toast.info(res.transformError);
      } else {
        Toast.info(res.transformError);
      }
    })
  }

  onMaskClick = () => {
    this.setState({
      menuShow: false
    });
    window.document.body.style.overflow = "auto";
  };

  menuShow = () => {
    console.log(this.state)
    this.setState({
      menuShow: !this.state.menuShow
    });
    window.document.body.style.overflow = "hidden";
  };

  onFieldChange = (field, val, obj) => {
    let stateObj = {};
    if (obj) {
      this.state[obj][field] = val;
    } else {
      stateObj[field] = val;
    }
    this.setState({...this.state, ...stateObj});
  }

  benefitChange = (val) => {
    console.log("change", val)

  }

  benefitOK = (val) => {
    console.log("changepok", val)
    const {positionDetail} = this.props;
    const {jd_benefit} = this.state;
    let currentLabel = [];
    let currentValue = [];
    val.map(item => {
      jd_benefit.forEach(benefit => {
        if (item === benefit.value) {
          currentLabel.push(benefit.label);
          currentValue.push(benefit.value);
        }
      })
    })

    this.setState({
      menuShow: !this.state.menuShow,
      benefit_label: currentLabel,
      positionParam: {
        ...this.state.positionParam,
        benefits: currentValue,
      }
    });
    window.document.body.style.overflow = "auto";
  }

  benefitCancel = () => {
    this.setState({
      menuShow: !this.state.menuShow
    });
    window.document.body.style.overflow = "auto";
  }

  saveClick = () => {
    const {editPosition, match, history} = this.props;
    const {positionParam} = this.state;
    let postParam = {...positionParam}
    postParam.jd_id = match.params.id;
    postParam.education = postParam.education && postParam.education[0];
    postParam.position = postParam.position && postParam.position[postParam.position.length - 1] ? postParam.position[postParam.position.length - 1] : undefined;
    postParam.salary = postParam.salary && postParam.salary[0];
    postParam.service_type = postParam.service_type && postParam.service_type[0];
    postParam.work_type = postParam.work_type && postParam.work_type[0];
    postParam.work_time = postParam.work_time && postParam.work_time[0];
    postParam.province_id = postParam.cities && postParam.cities[0];
    postParam.city_id = postParam.cities && postParam.cities[1];
    postParam.region_id = postParam.cities && postParam.cities[2];
    console.log(postParam)
    if (!postParam.service_type) {
      Toast.info("服务类型不能为空", 1);
      return;
    }
    if (!postParam.require_num) {
      Toast.info("招聘人数不能为空", 1);
      return;
    }
    if (!/^\d+(\.{0,1}\d+){0,1}$/.test(postParam.require_num)) {
      Toast.info("招聘人数只能为数字", 1);
      return;
    }
    if (parseInt(postParam.require_num) == 0) {
      Toast.info("招聘人数不能为0", 1);
      return;
    }
    if (!postParam.province_id) {
      Toast.info("工作城市不能为空", 1);
      return;
    }
    if (!postParam.address) {
      Toast.info("工作地址不能为空", 1);
      return;
    }
    if (!postParam.jd_desc) {
      Toast.info("职位描述不能为空", 1);
      return;
    }
    if (!postParam.work_time) {
      Toast.info("工作经验不能为空", 1);
      return;
    }
    editPosition(postParam).then(res => {
      if (res.code === 0) {
        Toast.info("修改成功！", 1);
        if (match.params.type === "list") {
          history.push("/position");
        } else {
          history.push("/position-detail/" + match.params.id);
        }
        
      } else {
        Toast.info(res.transformError, 1);
      }
    });
  }

  render() {
    const {
      service_type,
      jd_work_type,
      jd_work_time,
      jd_salary,
      jd_education,
      positions,
      jd_benefit,
      cities,
      menuShow,
      benefit_label,
      positionParam
    } = this.state;
    const { history } = this.props;
    return (
      <div className="position-edit">
        {/* <ActivityIndicator toast text="正在加载" /> */}
        <TopNavBar className="topbar" title="编辑职位信息" />
        <List className="edit-form">
          {/* <InputItem placeholder="请输入" onChange={(val) => this.onFieldChange("contact_no", val, "positionParam")}>
            <span className="require">*</span>职位名称
          </InputItem> */}
          <List.Item
            className="upload-logo"
            extra={positionParam.name}
          >
            职位名称
          </List.Item>
          <Picker
            data={service_type} value={positionParam.service_type}
            cols={1} onChange={(val) => this.onFieldChange("service_type", val, "positionParam")}
            extra={
              <div className="do-sel">
                请选择
              </div>
            }
          >
            <List.Item>
              <span className="require">*</span>服务类型
            </List.Item>
          </Picker>
          <InputItem placeholder="请输入" value={positionParam.require_num}
            onChange={(val) => this.onFieldChange("require_num", val, "positionParam")}>
            <span className="require">*</span>招聘人数
          </InputItem>
          <Picker
            data={cities} value={positionParam.cities}
            cols={3} onChange={(val) => this.onFieldChange("cities", val, "positionParam")}
            extra={
              <div className="do-sel">
                请选择
              </div>
            }
          >
            <List.Item>
              <span className="require">*</span>工作城市
            </List.Item>
          </Picker>
          <InputItem value={positionParam.address}
            onChange={(val) => this.onFieldChange("address", val, "positionParam")}
            placeholder="请输入" className="bottom-input">
            <span className="require">*</span>工作地址
          </InputItem>
        </List>
        <List className="edit-form">
          <Picker
            data={jd_work_type} value={positionParam.work_type}
            cols={1} onChange={(val) => this.onFieldChange("work_type", val, "positionParam")}
            extra={
              <div className="do-sel">
                请选择
              </div>
            }
          >
            <List.Item>职位性质</List.Item>
          </Picker>
          <Picker
            data={positions}  value={positionParam.position}
            cols={2} onChange={(val) => this.onFieldChange("position", val, "positionParam")}
            extra={
              <div className="do-sel">
                请选择
              </div>
            }
          >
            <List.Item>职能类别</List.Item>
          </Picker>
          <Picker
            data={jd_education} value={positionParam.education}
            cols={1} onChange={(val) => this.onFieldChange("education", val, "positionParam")}
            extra={
              <div className="do-sel">
                请选择
              </div>
            }
          >
            <List.Item>学历</List.Item>
          </Picker>
          <Picker
            data={jd_salary} value={positionParam.salary}
            cols={1} onChange={(val) => this.onFieldChange("salary", val, "positionParam")}
            extra={
              <div className="do-sel">
                请选择
              </div>
            }
          >
            <List.Item>薪资</List.Item>
          </Picker>
          {/* <InputItem placeholder="请输入">福利</InputItem> */}
          <List.Item
            className="upload-logo"
            onClick={this.menuShow}
            extra={
              benefit_label && benefit_label.length !== 0 ?
              benefit_label.join(",")
              :
              <div className="do-sel">
                请选择
              </div>
            }
          >
            福利
          </List.Item>
          <Picker
            data={jd_work_time} value={positionParam.work_time}
            cols={1} onChange={(val) => this.onFieldChange("work_time", val, "positionParam")}
            extra={
              <div className="do-sel">
                请选择
              </div>
            }
          >
            <List.Item><span className="require">*</span>工作经验</List.Item>
          </Picker>
          {/* <InputItem  value={positionParam.work_time}
            onChange={(val) => this.onFieldChange("work_time", val, "positionParam")}
            placeholder="请输入">工作经验</InputItem> */}
          <div className="textarea">
            <div className="textarea-label">
              <span className="require">*</span>职位描述
            </div>
            <TextareaItem value={positionParam.jd_desc}
              onChange={(val) => this.onFieldChange("jd_desc", val, "positionParam")}
              className="des-textarea"
              rows={10}
              placeholder="请详细填写职位描述..."
            />
          </div>
          <div className="textarea">
            <div className="textarea-label">
              任职要求
            </div>
            <TextareaItem value={positionParam.requirements}
              onChange={(val) => this.onFieldChange("requirements", val, "positionParam")}
              className="des-textarea"
              rows={10}
              placeholder="请详细填写任职要求..."
            />
          </div>
        </List>
        <div className="save-btn" onClick={this.saveClick}>保 存</div>
        {menuShow && (
          <Menu
            className="benefit-menu" onChange={this.benefitChange}
            level={1} value={positionParam.benefits.concat()}
            multiSelect onOk={this.benefitOK} onCancel={this.benefitCancel}
            data={jd_benefit}
          />
        )}
        {menuShow ? (
          <div className="menu-mask" onClick={this.onMaskClick} />
        ) : null}
      </div>
    );
  }
}

export default PositionDetail;
