/*
 * @Descripttion: 
 * @Date: 2021-06-21 14:53:06
 * @LastEditors: ci25578
 * @LastEditTime: 2021-06-22 21:40:06
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./introduce-detail.scss";
import { Flex } from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import HomeTopHeader from "pages/home/home-top-header";
import BottomFiexdPanel from "components/bottom-fied-panel/bottom-fied-panel";
import CollapseMenu from "components/collapse-menu/collapse-menu";

@connect(
  ({ user }) => {
    return { user };
  }
)
class IntroduceJob extends Component {
  render() {
    const { history, user } = this.props;
    return (
      <div className="introduce-detail-page">
        {/* <TopNavBar title="服务介绍" /> */}
        <HomeTopHeader history={history} user={user} nosticky/>
        <div className="detail-1-cross">
          <div className="title">岗位外包</div>
          <div className="spliter"></div>
        </div>
        <div className="intro">
        针对招聘旺季，新项目，员工短期替补，非核心业务员工流动性大及编制紧带来的人才短缺，提供短，中，长期项目及人员外包，支持客户提升组织灵活性，降低风险。
        </div>
        <div className="detail-2-cross"></div>
        <div className="content">
          <Flex className="title" align="center"><span className="line"></span>服务内容</Flex>
          <div className="content-detail">
          通过定制化的灵活用工解决方案，为企业提供人才招聘，人才测评，培训，入离职管理，薪酬管理与劳动风险管控等全风险外包服务。
          </div>
        </div>
        <div className="detail-3-cross"></div>
        <div className="content service-value">
          <Flex className="title" align="center" justify="end"><span className="line"></span>服务价值</Flex>
          <div className="content-detail">
            <div className="content-detail-p content-detail-1">
              <span className="p-start">增加组织弹性：</span>突破组织人员编制的限制，满足因项目，季节性业务需求，员工产假，病假等短期用工需求。
            </div>
            <div className="content-detail-p content-detail-2">
              <span className="p-start">提升运营效率：</span>帮助企业快速获取，甄选，雇佣及管理人才，减轻人力资源部门管理负担，使企业更专注于管理核心业务。
            </div>
            <div className="content-detail-p content-detail-3">
              <span className="p-start">节省人力成本：</span>根据企业的灵活用工需求，弹性配置人员，减少人员冗余，降低非核心支持类岗位的高流动率和人员替岗带来的管理成本。
            </div>
            <div className="content-detail-p content-detail-4">
              <span className="p-start">降低用工风险：</span>员工与即派签署劳动合同，即派全面承担雇佣风险，帮助企业降低因人员流动带来的用工风险。
            </div>
          </div>
        </div>
        <div className="detail-4-cross"></div>
        <div className="content">
          <Flex className="title" align="center"><span className="line"></span>案例分析</Flex>
          <div className="content-detail">
            <div className="content-detail-p content-detail-1">
              <span className="p-start">挑战：</span>全球知名特殊化学品制造商，多名支持部门员工休产假，团队工作压力变大。
            </div>
            <div className="content-detail-p content-detail-2">
              <span className="p-start">方案：</span>通过灵活招聘及岗位外包突破人员编制限制，提供外包雇员替代产期员工休假；了解客户需求及职位核心要素，从即派灵活用工人才库配送符合客户需求的候选人。
            </div>
            <div className="content-detail-p content-detail-3">
              <span className="p-start">成果：</span>1周时间内推荐并提供，维系了部门日常工作的正常运营，减少了其他成员可能因工作饱和而导致的工作效率降低与抱怨。
            </div>
          </div>
        </div>
        <CollapseMenu status="singleCode"/>
        <div className="icp">
          <div>©2014-2016科锐数字科技（苏州）有限公司 </div>
          <div>苏ICP备14059286号-2 </div>
          <div className="report">朝阳区人力资源与社会保障局 监督电话:57596212，65090445</div>
          <div>
            <a href="https://www.geetemp.com/geetemp/default/business-license.jpg">营业执照 </a>| 
            <a href="https://www.geetemp.com/geetemp/default/hrs-license.jpg"> 服务许可证名称320510190032</a>
          </div>
        </div>
        <BottomFiexdPanel history={this.props.history} />
      </div>
    );
  }
}

export default IntroduceJob;
