import React, { Component } from "react";
import "./interview.scss";
export default class BottomPanel extends Component {
  onBtn = () => {
    this.props.onClick && this.props.onClick();
  };
  render() {
    const { style={} } = this.props;
    return (
      <div className="bottom-panel-box" onClick={this.onBtn} style={style}>
        {this.props.children}
      </div>
    );
  }
}
