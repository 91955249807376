import React, { Component } from "react";
import { connect } from "react-redux";
import "./position-talent.scss";
import { Menu, ListView, Icon, Flex } from "@geetemp/gee-ui-mobile";
import { StickyContainer, Sticky } from "react-sticky";
import CustomIcon from "components/custom-icon/custom-icon";
import TopNavBar from "components/top-navbar/navbar";
import myPositionModel from "store/reducers/myPositionModel";
import ImgContainer from "components/img";
const menuFilter = [
  {
    value: "99",
    label: "全部人才"
  },
  // {
  //   value: "0",
  //   label: "已推荐"
  // },
  {
    value: "1",
    label: "已预约"
  },
  {
    value: "2",
    label: "安排面试"
  },
  {
    value: "3",
    label: "面试通过"
  },
  {
    value: "4",
    label: "offer协调"
  },
  {
    value: "5",
    label: "onboard"
  },
  {
    value: "6",
    label: "已淘汰"
  }
];

@connect(
  ({ myPositionModel }) => {
    return {
      position: myPositionModel,
      profilePagination: myPositionModel.profilePagination,
      profiles: myPositionModel.positionProfiles,
      recommendPagination: myPositionModel.recommendPagination,
      recomemnds: myPositionModel.recommendProfiles,
    };
  },
  {
    ...myPositionModel.actions
  }
)
class PositionDetail extends Component {
  state = {
    menuShow: false,
    menuSel: ["99"],
    menuLabel: "全部人才",
    dataSource: new ListView.DataSource({
      getRowData: (dataBlob, sectionID, rowID) => {
        return dataBlob[sectionID][rowID];
      },
      rowHasChanged: (row1, row2) => {
        return row1 !== row2;
      }
    }),
    recommendDataSource: new ListView.DataSource({
      getRowData: (dataBlob, sectionID, rowID) => {
        return dataBlob[sectionID][rowID];
      },
      rowHasChanged: (row1, row2) => {
        return row1 !== row2;
      }
    }),
    hasNext: false,
    hasRecommendNext: false
  };

  componentDidMount() {
    const { getPositionProfiles, getRecommendProfiles, match } = this.props;
    if (match.params.type === "all") {
      getPositionProfiles({
        status: 99,
        jd_id: match.params.id
      }, true);
    } else if (match.params.type === "reserve") {
      getPositionProfiles({
        status: 1,
        jd_id: match.params.id
      }, true);
      this.setState({
        menuSel: ["1"],
        menuLabel: "已预约",
      });
    } else if (match.params.type === "interview") {
      getPositionProfiles({
        status: 2,
        jd_id: match.params.id
      }, true);
      this.setState({
        menuSel: ["2"],
        menuLabel: "安排面试",
      });
    } else {
      getRecommendProfiles({
        status: 0,
        jd_id: match.params.id
      }, true);
      this.setState({
        menuSel: ["0"],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { profiles, profilePagination, recomemnds, recommendPagination } = this.props;
    if (prevProps.profiles !== profiles) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(profiles)
      });
    }
    if (prevProps.recomemnds !== recomemnds) {
      this.setState({
        recommendDataSource: this.state.dataSource.cloneWithRows(recomemnds)
      });
    }
    if (prevProps.profilePagination !== profilePagination) {
      this.setState({
        hasNext:
          profilePagination.page != profilePagination.countpage &&
          profilePagination.countpage != 0
      });
    }
    if (prevProps.recommendPagination !== recommendPagination) {
      this.setState({
        hasRecommendNext:
        recommendPagination.page != recommendPagination.countpage &&
        recommendPagination.countpage != 0
      });
    }
  }

  menuShow = () => {
    this.setState({
      menuShow: !this.state.menuShow
    });
  };

  onMaskClick = () => {
    this.setState({
      menuShow: false
    });
  };

  menuChange = item => {
    let currentMenuLabel = "";
    menuFilter.forEach(menu => {
      if (menu.value === item[0]) {
        currentMenuLabel = menu.label;
      }
    });
    this.setState({
      menuShow: false,
      menuSel: item,
      menuLabel: currentMenuLabel
    });
    const { getPositionProfiles, match } = this.props;
    this.loading = true;
    getPositionProfiles({ status: item[0], jd_id: match.params.id }, true).then(
      res => {
        this.loading = false;
      }
    );
  };

  onEndReached = event => {
    const { getPositionProfiles, profilePagination, match } = this.props;
    const { hasNext, menuSel } = this.state;
    console.log(hasNext, this.loading);
    if (!hasNext) return;
    if (this.loading) return;
    this.loading = true;
    getPositionProfiles({
      status: menuSel[0],
      jd_id: match.params.id,
      page: profilePagination.page + 1
    }).then(res => {
      this.loading = false;
    });
  };

  onRecommendEndReached = event => {
    const { getRecommendProfiles, recommendPagination, match } = this.props;
    const { hasRecommendNext, menuSel } = this.state;
    if (!hasRecommendNext) return;
    if (this.loading) return;
    this.loading = true;
    getRecommendProfiles({
      status: menuSel[0],
      jd_id: match.params.id,
      page: recommendPagination.page + 1
    }).then(res => {
      this.loading = false;
    });
  };

  render() {
    const { menuShow, menuSel, menuLabel, dataSource, hasNext, hasRecommendNext, recommendDataSource } = this.state;
    const { history, match, profiles, recomemnds } = this.props;
    const row = (rowData, sectionID, rowID) => {
      return <PositionTalentCard talentData={rowData} history={history} match={match}/>;
    };
    let list = [];
    if (match.params.type !== "recommend") {
      list = profiles;
    } else {
      list = recomemnds;
    }
    let MyBody = (props) => {
      return (
        <div className="am-list-body my-body">
          {props.children}
          {list && list.length === 0 && (
            <Flex className="no-data-view" align="center" justify="center">
              <div>
                <img src={require("assets/imgs/empty.png")} />
                <div style={{ textAlign: "center" }}>{props.type === "recommend" ? "您当前还没有预约面试的人选" : "抱歉,您还没有发布职位！"}</div>
              </div>
            </Flex>
          )}
        </div>
      );
    }

    return (
      <div className="position-talent-detail">
        {match.params.type !== "recommend" ? (
          <StickyContainer>
            <ListView
              dataSource={dataSource}
              className="talent-card-list"
              renderRow={row}
              renderBodyComponent={() => <MyBody />}
              renderHeader={() => (
                <Sticky>
                  {({ style }) => (
                    <TopNavBar
                      style={style}
                      className="topbar"
                      title={
                        <div className="top-filter" onClick={this.menuShow}>
                          {menuLabel} <CustomIcon type="icondown" />
                        </div>
                      }
                    />
                  )}
                </Sticky>
              )}
              renderFooter={() => (hasNext ? <Icon type="loading" /> : null)}
              onEndReached={this.onEndReached}
            />
            {menuShow && (
              <Menu
                className="top-filter-menu"
                onChange={this.menuChange}
                data={menuFilter}
                value={menuSel}
                level={1}
              />
            )}
            {menuShow ? (
              <div className="menu-mask" onClick={this.onMaskClick} />
            ) : null}
          </StickyContainer>
        ) : (
          <ListView
            dataSource={recommendDataSource}
            className="talent-card-list"
            renderRow={row}
            renderBodyComponent={() => <MyBody type="recommend"/>}
            renderHeader={() => (
              <TopNavBar
                className="topbar"
                title="为您推荐"
              />
            )}
            renderFooter={() => (hasRecommendNext ? <Icon type="loading" /> : null)}
            onEndReached={this.onRecommendEndReached}
          />
        )}
      </div>
    );
  }
}

class PositionTalentCard extends Component {
  render() {
    const { history, talentData, match } = this.props;
    // let imgSrc = require("assets/imgs/man.jpg");
    // if (talentData.avatar) {
    //   imgSrc = talentData.avatar;
    // } else if (talentData.is_intern == 0) {
    //   imgSrc =
    //   talentData.sex == "男"
    //       ? require("assets/imgs/man.jpg")
    //       : require("assets/imgs/woman.jpg");
    // } else if (talentData.is_intern == 1) {
    //   imgSrc =
    //   talentData.sex == "男"
    //       ? require("assets/imgs/student-man.jpg")
    //       : require("assets/imgs/student-woman.jpg");
    // }
    return (
      <div
        className="position-talent-card"
        onClick={() =>
          history.push("/resumedetail/" + talentData.profile_id + "/" + match.params.id)
        }
      >
        <div className="card-content" align="start">
          <ImgContainer src={talentData.avatar} alt="" className="avatar" />
          <div className="talent-info">
            <div className="name"><span className="ellipsis">{talentData.name}</span>{talentData.is_new ? <CustomIcon type="iconzuixin" /> : ""}</div>
            <div className="id">ID：{talentData.profile_no}</div>
            {talentData.status === 0 && talentData.recommend_time && (
              <React.Fragment>
                <div className="time">
                  推荐时间：{talentData.recommend_time}
                </div>
              </React.Fragment>
            )}
            {talentData.status === 1 && talentData.appointment_time && (
              <React.Fragment>
                <div className="time">
                  预约面试：{talentData.appointment_time}
                </div>
              </React.Fragment>
            )}
            {talentData.status === 1 && talentData.recommend_time && (
              <React.Fragment>
                <div className="time">
                  推荐时间：{talentData.recommend_time}
                </div>
              </React.Fragment>
            )}
            {talentData.status === 2 && (
              <React.Fragment>
                <div className="time">
                  面试时间：{talentData.interview_time}
                </div>
              </React.Fragment>
            )}
            {talentData.status === 3 && (
              <React.Fragment>
                <div className="time">
                  面试时间：{talentData.interview_time}
                </div>
              </React.Fragment>
            )}
            {talentData.status === 4 && (
              <React.Fragment>
                <div className="time">
                  面试时间：{talentData.interview_time}
                </div>
                <div className="time">
                  offer时间：{talentData.offer_time}
                </div>
              </React.Fragment>
            )}
            {talentData.status === 5 && (
              <React.Fragment>
                <div className="time">
                  面试时间：{talentData.interview_time}
                </div>
                <div className="time">
                  offer时间：{talentData.offer_time}
                </div>
                <div className="time">
                  入职时间：{talentData.entry_time}
                </div>
              </React.Fragment>
            )}
            {talentData.status === 6 && (
              <React.Fragment>
                <div className="time">淘汰原因：{talentData.remark}</div>
              </React.Fragment>
            )}
          </div>
          <div
            className={`talent-status ${talentData.status === 1 && "reserve"} 
              ${talentData.status === 3 && "ok"} 
              ${talentData.status === 2 && "interview"} 
              ${talentData.status === 4 && "offer"} 
              ${talentData.status === 5 && "onboard"} 
              ${(talentData.status === 6 || talentData.status === 7) && "fail"}`}
          >
            {talentData.status_name}
          </div>
        </div>
        {talentData.status !== 6 && talentData.remark && (
          <Flex className="card-footer">备注：{talentData.remark}</Flex>
        )}
      </div>
    );
  }
}

export default PositionDetail;
