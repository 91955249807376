import React, { Component } from "react";
import "./radio-btn.scss";
import { Button } from "@geetemp/gee-ui-mobile";

// 单选按钮
export default class RadioBtn extends React.PureComponent {
  onClick = (name, val) => {
    if (!name) throw new Error("需要一个参数名字(paramName)");
    this.props.onClick(name, val);
  };
  render() {
    const {
      option = [],
      checked,
      paramName,
      className = "",
      fieldNames = {value:"value",label:"label"} 
    } = this.props;
    return (
      <div className="radio-sel-box">
        {option.map(item => (
          <Button
            className={`btn ${className ? className : null}`}
            type={`${checked === item[fieldNames.value] ? "primary" : null}`}
            key={item[fieldNames.value]}
            onClick={this.onClick.bind(this, paramName, item[fieldNames.value])}
          >
            {item[fieldNames.label]}
          </Button>
        ))}
      </div>
    );
  }
}
