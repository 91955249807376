import React from "react";
import { Switch, Route } from "react-router-dom";
import { createStaticRoutes, asyncLoad } from "@geetemp/nut";
import ErrorPage from "pages/500";
import NotFoundPage from "pages/404";
import LoginPage from "pages/user/login";
import RegisterPage from "pages/user/register";
import ResetPage from "pages/user/reset";
import CompleteInfoPage from "pages/user/complete-info";
import Home from "pages/home/home";
import Center from "pages/center/center";
import MyCompany from "pages/center/my-company";
import Introduce from "pages/introduce/introduce";
import IntroduceOffshore from "pages/introduce/introduce-offshore";
import IntroduceQuality from "pages/introduce/introduce-quality";
import IntroduceJob from "pages/introduce/introduce-job";
import Agreement from "pages/assist/agreement";
import Feedback from "pages/assist/feedback";
import Position from "pages/myposition/position";
import PositionDetail from "pages/myposition/position-detail";
import PositionTalent from "pages/myposition/position-talent";
import PositionEdit from "pages/myposition/position-edit";
import Talent from "pages/talentPool/talent";
import ResumeDetail from "pages/talentPool/resume-detail";
import CreatePosition from "pages/myposition/create-position";
import SendServe from "pages/myposition/send-serve";
import InfoIntroduction from "pages/infointroduction/infointroduction";
import DynamicDetail from "pages/infointroduction/dynamicdetail";
import Interview from "pages/talentPool/interview";
import PersonInfo from "pages/center/person-info";
import SystemMessage from "pages/center/system-message";
import MyCollectOrder from "pages/center/my-collect-order";
import Weixin from "pages/introduce/weixin";

export default createStaticRoutes(
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="/position/:type?" exact component={Position} needUser/>
    <Route path="/position-detail/:id" component={PositionDetail} needUser/>
    <Route path="/position-talent/:type/:id" component={PositionTalent} needUser/>
    <Route path="/position-edit/:id/:type?" component={PositionEdit} needUser/>
    <Route path="/center" component={Center} needUser/>
    <Route path="/mycompany/:status?" component={MyCompany} needUser/>
    <Route path="/weixin" component={Weixin} />
    <Route path="/introduce" component={Introduce} />
    <Route path="/introduce-offshore" component={IntroduceOffshore} />
    <Route path="/introduce-quality" component={IntroduceQuality} />
    <Route path="/introduce-job" component={IntroduceJob} />
    <Route path="/agreement" component={Agreement} />
    <Route path="/feedback" component={Feedback} />
    <Route path="/login" component={LoginPage}/>
    <Route path="/register" component={RegisterPage} />
    <Route path="/reset" component={ResetPage} />
    <Route path="/talent" component={Talent} needUser/>
    <Route path="/resumedetail/:id/:jd_id?" component={ResumeDetail} needUser/>
    <Route path="/interview/:id?/:jd_id?" component={Interview} needUser/>
    <Route path="/createposition/:profile_id?" component={CreatePosition} needUser/>
    <Route path="/sendserve" component={SendServe} needUser/>
    <Route path="/infointroduction" component={InfoIntroduction} />
    <Route path="/dynamicdetail/:id?" component={DynamicDetail} />
    <Route path="/personinfo" component={PersonInfo} needUser/>
    <Route path="/systemmessage" component={SystemMessage} needUser/>
    <Route path="/mycollectorder/:type?" component={MyCollectOrder} needUser/>
    <Route path="/complete" component={CompleteInfoPage} needUser/>
    <Route
      path="/tictactoe"
      component={asyncLoad({
        loader: () => import("pages/tic-tac-toe"),
        placeholder: () => <p>加载中...</p>
      })}
    />
    <Route path="/500" component={ErrorPage} />
    <Route component={NotFoundPage} />
  </Switch>
);
