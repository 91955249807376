import React, { PureComponent } from "react";
import { StickyContainer, Sticky } from "react-sticky";
import { Button, Flex, NoticeBar } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import HomeMenu from "./home-menu.js";
import "./home-top-header.scss";
import ImgContainer from "components/img";
// import {history} from "client.js"
export default class HomeTopHeader extends PureComponent {
  state = {
    homeNavMenuVisible: false
  };

  openNavMenu = () => {
    this.setState({
      homeNavMenuVisible: !this.state.homeNavMenuVisible
    })
  };

  goHref = (href, hasLogin) => {
    const { history, user } = this.props;
    // if (!user.info.id && hasLogin) {
    //   history.push("/login?redirect=" + encodeURIComponent(history.location.pathname));
    // } else {
      history.push(href);
    // }
  }

  render() {
    const { homeNavMenuVisible } = this.state;
    const { history, user, nosticky } = this.props;
    const navMenuData = [
      { label: "找人才", click: () => this.goHref("/talent", true) },
      { label: "派服务", click: () => this.goHref("/sendserve", true) },
      { label: "服务介绍", click: () => this.goHref("/introduce") },
      {
        label: "关于即派",
        child: [
          { label: "即派简介", click: () => this.goHref("/infointroduction") },
          { label: "即派动态", click: () => this.goHref("/infointroduction/dynamic") },
          { label: "加入即派", click: () => this.goHref("/infointroduction/join") },
          { label: "常见问题", click: () => this.goHref("/infointroduction/question") }
        ]
      }
    ];
    return (
      <React.Fragment>
         {
           nosticky ?
           <Flex className="home-header nosticky" justify="between" >
                <Flex className="header-left">

                  <CustomIcon
                    className={homeNavMenuVisible ? "iconguanbi" : "iconcaidan"}
                    type={homeNavMenuVisible ? "iconguanbi" : "iconcaidan"}
                    onClick={this.openNavMenu}
                  />

                  <ImgContainer
                    className="logo" onClick={() => history.push("/")}
                    src={require("assets/imgs/logo.png")}
                    alt=""
                  />
                </Flex>
                <Flex className="header-right">
                  <CustomIcon type="icondianhua1" />
                  <span><a href="tel:400-600-6181">400-600-6181</a></span>
                </Flex>
              </Flex>
           :
           <Sticky >
            {({ style }) => (
              <Flex className="home-header" justify="between" style={style}>
                <Flex className="header-left">

                  <CustomIcon
                    className={homeNavMenuVisible ? "iconguanbi" : "iconcaidan"}
                    type={homeNavMenuVisible ? "iconguanbi" : "iconcaidan"}
                    onClick={this.openNavMenu}
                  />

                  <ImgContainer
                    className="logo" onClick={() => history.push("/")}
                    src={require("assets/imgs/logo.png")}
                    alt=""
                  />
                </Flex>
                <Flex className="header-right">
                  <CustomIcon type="icondianhua1" />
                  <span><a href="tel:400-600-6181">400-600-6181</a></span>
                </Flex>
              </Flex>
            )}
          </Sticky>
         }
        
        <HomeMenu
          className="home-nav-menu"
          visible={homeNavMenuVisible}
          menuData={navMenuData}
          showArrow
          onClose={() => this.setState({ homeNavMenuVisible: false })}
        />
      </React.Fragment>
    );
  }
}
