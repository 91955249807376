import React, { Component } from "react";
import { connect } from "react-redux";
import "./talent.scss";
import {
  Button,
  Flex,
  Menu,
  Accordion,
  Icon,
  InputItem,
  ListView,
  PullToRefresh
} from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import TalentCard from "components/talent-card/talent-card";
import talentModel from "store/reducers/talentModel";
// import JRoll from "JRoll";
import { resolve } from "url";

const internOption = [{ value: "1", label: "实习生" }];
function MyBody(props) {
  return <div>{props.children}</div>;
}

@connect(
  ({ talentModel }) => {
    return { ...talentModel };
  },
  {
    ...talentModel.actions
  }
)
class Talent extends Component {
  constructor(props) {
    super(props);
    const getRowData = (dataBlob, sectionID, rowID) => {
      return dataBlob[sectionID][rowID];
    };
    const dataSource = new ListView.DataSource({
      getRowData,
      rowHasChanged: (row1, row2) => {
        return true;
      }
    });
    this.state = {
      dataSource,
      isLoading: true,
      refreshing: true
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.talentListInfo.list !== nextProps.talentListInfo.list) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(
          nextProps.talentListInfo.list
        ),
        isLoading: true,
        refreshing: false
      });
    }
  }
  setInit = initCity => {
    return new Promise((resolve, reject) => {
      const { setUpdateState, searchParams } = this.props;
      setUpdateState({
        filed: "searchParams",
        value: {
          ...searchParams,
          city: [initCity.value],
          cityName: initCity.label
        }
      });
      resolve("ok");
    });
  };
  componentDidMount() {
    const { getCommonData } = this.props;
    getCommonData(["index_cities", "profile_position"]);
    const initCity = JSON.parse(localStorage.getItem("curCity") || {});
    if (initCity.value) {
      this.setInit(initCity).then(info => {
        this.getList();
      });
    } else {
      this.getList();
    }
  }
  /**
   * @description 获取列表数据
   * @param {string} keyword 只有在搜索的时候会有值（由于更新redux为上个旧值才这样写）
   * @param {string} nextpage 上拉加载下一页
   * @param {string} downConnect 下拉加载下一页
   */
  getList = (keyword, nextPage, downConnect) => {
    const { getTalentList, searchParams = {} } = this.props;
    let params = { ...searchParams, ...keyword };
    params.city = (searchParams.city || [])[0];
    params.position =
      searchParams.position[(searchParams.position || []).length - 1];
    params.is_intern = (searchParams.is_intern || [])[0];
    delete params.positionName,
      delete params.internName,
      delete params.cityName,
      getTalentList(
        params,
        nextPage ? nextPage : undefined,
        downConnect ? downConnect : undefined
      ).then(() => this.setState({ isLoading: false }));
  };
  /**
   * @description 点击遮罩层关闭所有菜单
   */
  onMaskClick = () => {
    const { setUpdateState, searchStatus } = this.props;
    setUpdateState({
      filed: "searchStatus",
      value: { show1: false, show2: false, show3: false }
    });
    document.body.classList.remove("status-fiexd");
  };
  /**
   * @description 搜索面板和蒙版是否开关
   * @param {string} name  改变的名字
   * @param {string} statusName  redux上的名字
   */
  onOpen = (name, statusName) => {
    const { setUpdateState } = this.props;
    setUpdateState({
      filed: [statusName],
      value: { [name]: !this.props[statusName][name] }
    });
    //处理蒙版开启下面滚动条禁用
    if (!this.props[statusName][name]) {
      document.body.classList.add("status-fiexd");
    } else {
      document.body.classList.remove("status-fiexd");
    }
  };
  /**
   * @description 选择搜索条件
   * @param {string} type  改变的名字
   * @param {number} value   改变的值
   */
  onChangeMenu = (type, value) => {
    const { index_cities, profile_position } = this.props.commonData;
    const dataName = {
      show1: index_cities,
      show2: profile_position,
      show3: internOption
    };
    let label = "";
    let selId = [];
    dataName[type].forEach(dataItem => {
      if (dataItem.value === value[0]) {
        label = dataItem.label;
        selId.push(dataItem.value);
        if (dataItem.children && value[1]) {
          dataItem.children.forEach(cItem => {
            if (cItem.value === value[1]) {
              label += ` ${cItem.label}`;
              selId.push(cItem.value);
              return;
            }
          });
        }
      }
    });
    this.onOpen(type, "searchStatus"); //关闭选择面板
    this.updateFuc(type, value, label, selId);
    document.body.classList.remove("status-fiexd"); //清除禁止滚动
    console.log(selId, label);
  };
  /**
   * @description 更新searchParams redux
   * @param {string} type  改变的名字
   */
  updateFuc = (type, value, label, selId) => {
    const { setUpdateState, searchParams } = this.props;
    const stateKeyName = {
      show1: "cityName",
      show2: "positionName",
      show3: "internName"
    };
    const stateKeyId = {
      show1: "city",
      show2: "position",
      show3: "is_intern"
    };
    //特殊处理实习生字段不清除
    if (type === "show3" && JSON.stringify(value) === "[]") {
      label = "实习生";
      selId = [];
    }
    setUpdateState({
      filed: "searchParams",
      value: {
        ...searchParams,
        [stateKeyName[type]]: label,
        [stateKeyId[type]]: selId,
        page: 1 //初始第一页
      }
    });
    this.getList();
  };
  /**
   * @description 去详情
   * @param {number} id
   */
  goDetail = id => {
    this.props.history.push("/resumedetail/" + id);
  };

  /**
   * @description 蒙版虚拟键盘输入框更新数据
   * @param {number} val
   */
  updateData = (name, val) => {
    const { setUpdateState, searchParams } = this.props;
    setUpdateState({
      filed: "searchParams",
      value: {
        ...searchParams,
        [name]: val,
        page: 1 //初始第一页
      }
    });
    this.getList({ [name]: val });
  };
  /**
   * @description 蒙版虚拟键盘输入框完成
   * @param {number} val
   */
  completeInput = (e, val) => {
    if (e.keyCode !== 13) return;
    console.log("蒙版输入值", e.target.value);
    this.onOpen("pageMaskStatus", "topMask"); //触发完成事件关闭蒙版更新redux
    this.updateData("keyword", e.target.value);
  };
  /**
   * @description 上拉加载下一页
   */
  onEndReached = () => {
    if (!this.state.isLoading) {
      this.onUpDownLoading();
      this.setState({ isLoading: true });
    }
  };
  /**
   * @description 下拉加载下一页
   */
  // onRefresh = () => {
  //   this.onUpDownLoading("downConnect");
  // };
  /**
   * @description 下拉或下拉获取
   */
  onUpDownLoading = downConnect => {
    const { setUpdateState, searchParams } = this.props;
    const { page = {} } = this.props.talentListInfo;
    setUpdateState({
      filed: "searchParams",
      value: { ...searchParams, page: page.page + 1 }
    });
    //触发分页nextPage
    this.getList({}, "nextPage", downConnect);
  };
  componentWillUnmount() {
    document.body.classList.remove("status-fiexd");
    this.props.setUpdateState({
      filed: "talentListInfo",
      value: {}
    });
    this.props.setUpdateState({
      filed: "searchParams",
      value: { ...this.props.searchParams, "per-page": 10, page: 1 }
    });
  }
  render() {
    const {
      history,
      searchStatus = {},
      searchParams = {},
      topMask = {},
      commonData = {},
      talentListInfo
    } = this.props;
    const { pageMaskStatus } = topMask;

    const { dataSource, isLoading } = this.state;
    const { show1, show2, show3 } = searchStatus;
    const { list, page = {} } = talentListInfo;
    const row = (rowData, sectionID, rowID) => {
      return (
        <TalentCard
          key={rowID}
          goDetail={this.goDetail.bind(this, rowData.id)}
          data={rowData}
        />
      );
    };
    return (
      <div className="talent-container">
        <TopNavBar
          className="nav"
          title="找人才"
          rightContent={[
            <Icon
              onClick={this.onOpen.bind(this, "pageMaskStatus", "topMask")}
              key="0"
              type="search"
              style={{ color: "#333333" }}
            />
          ]}
        />
        <div className="wrapper">
          <div className="serch-panel">
            <div className="flex-box">
              <Flex>
                <Flex.Item
                  onClick={this.onOpen.bind(this, "show1", "searchStatus")}
                >
                  <span className={`title ${show1 ? "active" : null}`}>
                    {searchParams.cityName}
                  </span>
                  <span
                    className={`arrow-pub ${show1 ? "arrow-up" : "arrow-down"}`}
                  />
                </Flex.Item>
                <Flex.Item
                  onClick={this.onOpen.bind(this, "show2", "searchStatus")}
                >
                  <span className={`title ${show2 ? "active" : null}`}>
                    {searchParams.positionName}
                  </span>
                  <span
                    className={`arrow-pub ${show2 ? "arrow-up" : "arrow-down"}`}
                  />
                </Flex.Item>
                <Flex.Item
                  onClick={this.onOpen.bind(this, "show3", "searchStatus")}
                >
                  <span
                    className={`title ${
                      JSON.stringify(searchParams.is_intern) !== "[]" || show3
                        ? "active"
                        : null
                    }`}
                  >
                    {searchParams.internName}
                  </span>
                  <span
                    className={`arrow-pub ${show3 ? "arrow-up" : "arrow-down"}`}
                  />
                </Flex.Item>
              </Flex>
            </div>
            {show1 ? (
              <Menu
                className="top-menu"
                data={commonData.index_cities}
                value={searchParams.city}
                level={1}
                onChange={this.onChangeMenu.bind(this, "show1")}
                height={document.documentElement.clientHeight * 0.7}
              />
            ) : null}
            {show2 ? (
              <Menu
                className="top-menu"
                data={commonData.profile_position}
                value={searchParams.position}
                onChange={this.onChangeMenu.bind(this, "show2")}
                height={document.documentElement.clientHeight * 0.6}
              />
            ) : null}
            {show3 ? (
              <Menu
                className="top-menu"
                data={internOption}
                value={searchParams.is_intern}
                level={1}
                onChange={this.onChangeMenu.bind(this, "show3")}
                height={46}
                multiSelect
                onOk={false}
              />
            ) : null}
          </div>

          {/* 搜索蒙版 */}
          {pageMaskStatus && (
            <div className="page-mask-box">
              <TopNavBar
                title={[
                  <InputItem
                    className="search"
                    type="search"
                    defaultValue={searchParams.keyword}
                    clear
                    placeholder="搜索关键词：如本科等"
                    ref={el => (this.inputRef = el)}
                    onKeyUp={this.completeInput.bind(this)}
                    onVirtualKeyboardConfirm={this.completeInput.bind(this)}
                  />
                ]}
                rightContent={[
                  <span
                    onClick={this.onOpen.bind(
                      this,
                      "pageMaskStatus",
                      "topMask"
                    )}
                    style={{ color: "#333333" }}
                  >
                    取消
                  </span>
                ]}
              />
              <div className="mask" />
            </div>
          )}
          {/* 人选列表 */}
          <div className="item-box">
            {list && list.length !== 0 ? (
              <ListView
                dataSource={dataSource}
                renderRow={row}
                renderBodyComponent={() => <MyBody />}
                useBodyScroll
                // renderScrollComponent={ process.env.BROWSER&&new JRoll("#wrapper", {scrollBarY:true})}
                renderFooter={() => (
                  <Flex align="center" justify="center">
                    {page.is_more && isLoading ? <Icon type="loading" /> : null}
                  </Flex>
                )}
                scrollRenderAheadDistance={500}
                // pullToRefresh={
                //   show1 || show2 || show3 || pageMaskStatus
                //     ? false
                //     : process.env.BROWSER && (
                //         <PullToRefresh
                //           refreshing={this.state.refreshing}
                //           onRefresh={this.onRefresh}
                //         />
                //       )
                // }
                onEndReached={this.onEndReached}
                scrollEventThrottle={80}
                onEndReachedThreshold={30}
              />
            ) : (
              <Flex className="no-data-view" align="center" justify="center">
                <div style={{ marginTop: "100px", color: "#b3b3b3" }}>
                  {isLoading && (
                    <div>
                      <Icon type="loading" />
                    </div>
                  )}
                  {list && list.length === 0 && !isLoading && (
                    <div>
                      <img src={require("assets/imgs/empty.png")} />
                      <div style={{ textAlign: "center" }}>
                        抱歉，没有符合条件的候选人！
                      </div>
                    </div>
                  )}
                </div>
              </Flex>
            )}
          </div>
        </div>
        {show1 || show2 || show3 ? (
          <div
            className="menu-mask"
            onClick={this.onMaskClick}
            onTouchMove={this.onMaskClick}
          />
        ) : null}
      </div>
    );
  }
}

export default Talent;
