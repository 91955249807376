import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./home.scss";
import { Button, Flex, NoticeBar, Carousel, ActivityIndicator } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import TopNavBar from "components/top-navbar/navbar";
import TalentCard from "components/talent-card/talent-card";
import HomeMenu from "./home-menu.js";
import { StickyContainer, Sticky } from "react-sticky";
import { commonData } from "services/commonService";
import userModel from "store/reducers/userModel";
import indexModel from "store/reducers/indexModel";
import { getnewsList, ads } from "services/indexService";
import HomeTopHeader from "pages/home/home-top-header";
import BottomFiexdPanel from "components/bottom-fied-panel/bottom-fied-panel";
import ImgContainer from "components/img";
import CollapseMenu from "components/collapse-menu/collapse-menu";
import { isWeChatBrowser, getDomain } from "utils/util";
import { urlEncode, parseQueryString } from "utils/url";

const proxies = require(process.env.PACKAGE_JSON).proxy;
const root = process.env.BROWSER ? require("../../client") : null;



@connect(
  ({ home, user }) => {
    return { ...home, user };
  },
  {
    ...userModel.actions,
    ...indexModel.actions
  }
)
class HomePage extends Component {
  static async getInitialProps({ req, res, match, history, location, ...ctx }) {
    return { whatever: "stuff" };
  }

  state = {
    index_positions: [],
    index_cities: [],
    currentPosIndex: 0,
    citySelMenuVisible: false,
    informs: [],
    ads: [],
    talentLoad: true,
    activityVisible: true,
  };

  componentDidMount() {
    const {location} = this.props;
    let search = parseQueryString(decodeURIComponent(location.search));
    // if (isWeChatBrowser()&&!search.state&&!localStorage.getItem("searchState")) { 
    //   window.location.href = getDomain() + "/wap/v2_0/weixin/login?currentUrl=" + encodeURIComponent(root.history.location.pathname.substring(1));
    // }
    if(isWeChatBrowser()&&search.state&&!localStorage.getItem("searchState")){
      localStorage.setItem("searchState", search.state);
    }
    const { getRecommendHome, setSelectedCity } = this.props;
    commonData(["index_cities", "index_positions"]).then(res => {
      let index_cities = res.index_cities.map(item => {
        return {label: item.name, value: item.id}
      })
      this.setState({
        index_cities: index_cities,
        index_positions: res.index_positions,
        currentPosIndex: res.index_positions[0].id
      });
      
      let curCity = localStorage.getItem("curCity");
      console.log("curCity",curCity)
      if (!curCity) {
        curCity = index_cities.filter(item => item.label === "全国")[0] || {};
        localStorage.setItem("curCity", JSON.stringify(curCity));
      } else {
        curCity = JSON.parse(curCity)
      }
      setSelectedCity(curCity);

      getRecommendHome({ position: res.index_positions[0].id, city: curCity.value}).then(res => {
        this.setState({
          talentLoad: false
        })
      });

      this.setInforms(curCity.value);
      ads({scene_id: 5}).then(res => {
        if (res.code === 0) {
          this.setState({
            ads: res.data
          })
        }
      })
    });
  }

  setInforms = (city) => {
    getnewsList({ city_id: city }).then(res => {
      if (res.code === 0) {
        const result = Object.values(res.data);
        // result.unshift(result[result.length - 1]);
        this.setState({ informs: result });
      }
    });
  };

  recommendPosTalent = item => {
    const { getRecommendHome, selectedCity } = this.props;
    this.setState({
      talentLoad: true
    })
    getRecommendHome({ position: item.id, city: selectedCity.value}).then(res => {
      this.setState({
        talentLoad: false
      })
    });
    this.setState({ currentPosIndex: item.id });
  };

  openSelCity = () => {
    this.setState({
      citySelMenuVisible: !this.state.citySelMenuVisible
    })
  };

  citySelClick = (item) => {
    const { setSelectedCity, getRecommendHome } = this.props;
    const { currentPosIndex } = this.state;
    setSelectedCity(item);
    localStorage.setItem("curCity", JSON.stringify(item));
    this.setState({
      talentLoad: true
    })
    getRecommendHome({ position: currentPosIndex, city: item.value}).then(res => {
      this.setState({
        talentLoad: false
      })
    });
    this.setInforms(item.value);
  }

  goTalent = () => {
    const { history, user } = this.props;
    console.log("sssssss")
    // if (user.info.id) {
      history.push("/talent");
    // } else {
    //   history.push("/login?redirect=" + encodeURIComponent("/talent"));
    // }
  }
  
  render() {
    const { index_positions, currentPosIndex, index_cities, citySelMenuVisible, informs, ads, talentLoad } = this.state;
    const { history, recommendTalents, selectedCity, user } = this.props;
    const CitySelHeader = (
      <TopNavBar title="选择城市" className='city-sel-bar' icon="iconguanbi" noBack onLeftClick={() => this.setState({citySelMenuVisible: false})}/>
    );
    return (
      <StickyContainer className="home-page">
        {!citySelMenuVisible && <HomeTopHeader history={history} user={user} nosticky/> }
        <HomeMenu value={selectedCity} header={CitySelHeader} 
          className="city-sel-menu" visible={citySelMenuVisible} onClick={this.citySelClick}
          menuData={index_cities} onClose={() => {this.setState({citySelMenuVisible: false})}}/>
        <div className="banner">
          <ImgContainer
            src={require("assets/imgs/banner-tip.png")}
            alt=""
            className="banner-tip"
          />
          <div className="banner-tip-info">
            优秀员工按月租用、即刻到岗、灵活方便
          </div>
          <div className="place" onClick={this.openSelCity}>
            <CustomIcon type="iconaddress" />
            <span className="place-name">{selectedCity.label}</span>
          </div>
          <div className="banner-notice">
            <CustomIcon type="iconhorn" />
            {informs.length > 0 &&
              <Carousel
                className="notice-carousel"
                vertical
                dots={false}
                dragging={false}
                swiping={false}
                autoplay
                infinite
              >
                {informs.map((item, index) =>(
                  <div key={index} className="v-item">{item}</div>
                ))}
              </Carousel>
            }
          </div>
        </div>
        <div className="single-function">
          <div className="left-function">
            <div className="pos-outsource function" onClick={() => history.push("/introduce-job")}>
              <div className="title">岗位外包</div>
              <div className="tip">短期用工支援，即用即停</div>
            </div>
            <div className="people-recru function" onClick={() => history.push("/introduce-offshore")}>
              <div className="title">离岸招聘</div>
              <div className="tip">远程招聘，按月租用</div>
            </div>
          </div>
          <div className="right-function function leave" onClick={() => history.push("/introduce-quality")}>
            <div className="title">人才招聘</div>
            <div className="tip">优质人员随意挑，即约即面</div>
          </div>
        </div>
        <div className="super-talent">
          <Flex className="talent-type" justify="between" align="center">
            <div className="talent">优质白领</div>
            <Flex className="type" justify="between">
              {index_positions &&
                index_positions.map((item, index) => (
                  <span
                    className={`${item.id === currentPosIndex ? "sel" : ""}`}
                    onClick={() => this.recommendPosTalent(item)}
                    key={item.id}
                  >
                    {item.name}
                  </span>
                ))}
            </Flex>
          </Flex>
          <div className="talent-list">
            {!talentLoad ? recommendTalents &&
              recommendTalents.map(item => (
                <TalentCard
                  key={item.id}
                  data={item}
                  goDetail={() => history.push("/resumedetail/" + item.id)}
                />
              ))
              :
              <ActivityIndicator className="talent-loader" animating={talentLoad}/>
            }
            {!talentLoad && 
              recommendTalents && recommendTalents.length === 0 && 
              <Flex className="no-data-view" align="center" justify="center">
                <div>
                  <img src={require("assets/imgs/empty.png")} />
                  <div style={{ textAlign: "center" }}>抱歉,暂没有数据！</div>
                </div>
              </Flex>
            }
          </div>
          <div className="more" onClick={this.goTalent}>
            查看更多<CustomIcon type="iconright-circle" />
          </div>
        </div>
        <div className="progress-des">
          <Flex className="header" justify="between" align="center">
            <div className="title">服务流程</div>
            <div className="des">智能推荐，在线预约</div>
          </Flex>
          <Flex className="progress-img" justify="between" align="center">
            <div className="main">
              <ImgContainer
                src={require("assets/imgs/home/commit.png")}
                alt=""
                className="commit"
              />
            </div>
            <div className="formain">8H</div>
            <div className="main">
              <ImgContainer
                src={require("assets/imgs/home/recommend.png")}
                alt=""
                className="recommend"
              />
            </div>
            <div className="formain">24H</div>
            <div className="main">
              <ImgContainer
                src={require("assets/imgs/home/go.png")}
                alt=""
                className="go"
              />
            </div>
            <div className="formain">7day</div>
            <div className="main">
              <ImgContainer
                src={require("assets/imgs/home/insite.png")}
                alt=""
                className="insite"
              />
            </div>
          </Flex>
          <Flex className="progress-text" justify="between" align="center">
            <div className="main">提交需求</div>
            <div className="formain">>></div>
            <div className="main">智能推荐</div>
            <div className="formain">>></div>
            <div className="main">到场面试</div>
            <div className="formain">>></div>
            <div className="main">入职管理</div>
          </Flex>
        </div>
        <div className="service-des">
          <Flex className="header" justify="between" align="center">
            <div className="title">服务优势</div>
            <div className="des">定制化灵活用工服务，帮助企业降本增效</div>
          </Flex>
          <Flex align="center" wrap="wrap" className="advatange-container">
            <div className="advantage">
              <ImgContainer
                src={require("assets/imgs/home/quick.png")}
                alt=""
                className="advantage-img"
              />
              <div className="title">快速交付</div>
              <div className="des">
                线上灵活用工人才库，<strong>24H</strong>
                面试，1周到岗，招聘效率提升<strong>75％</strong>{" "}
              </div>
            </div>
            <div className="advantage">
              <ImgContainer
                src={require("assets/imgs/home/zero-risk.png")}
                alt=""
                className="advantage-img"
              />
              <div className="title">零风险</div>
              <div className="des">
                即派全方位承担雇主责任，企业综合用工风险降低
                <strong> 85％</strong>{" "}
              </div>
            </div>
            <div className="advantage">
              <ImgContainer
                src={require("assets/imgs/home/smart.png")}
                alt=""
                className="advantage-img"
              />
              <div className="title">智能推荐</div>
              <div className="des">
                <strong>SaaS</strong>
                的人才管理平台，智能匹配推荐，人岗匹配率高达
                <strong> 93％</strong>{" "}
              </div>
            </div>
            <div className="advantage">
              <ImgContainer
                src={require("assets/imgs/home/cost.png")}
                alt=""
                className="advantage-img"
              />
              <div className="title">节约成本</div>
              <div className="des">
                “按月租赁”人员，避免人员冗余，降低综合用工成本
                <strong> 27％</strong>{" "}
              </div>
            </div>
          </Flex>
        </div>
        {
          this.state.ads && this.state.ads.length !== 0 &&
          <div className="advert">
            <Carousel
              autoplay={false}
              infinite swiping={false}
              dots={ads.length > 1 ? true : false}
              beforeChange={(from, to) => console.log(`slide from ${from} to ${to}`)}
              afterChange={index => console.log('slide to', index)}
            >
              {
                this.state.ads.map((item, index) => {
                  return (<a key={index}
                    href={item.url}
                    target="_blank"
                    onClick={e => {
                      if (!item.url) e.preventDefault();
                    }}
                  >
                    <img src={item.img} alt="广告" />
                  </a>)
                })
              }
            </Carousel>
          </div>
        }
        <div className="custom-company">
          <Flex className="header" justify="between" align="center">
            <div className="title">客户案列</div>
            <div className="des">1900+品牌客户，他们都选择即派</div>
          </Flex>
          <Flex className="custom-list" justify="between" wrap="wrap">
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="yiqi-logo"
                src={require("assets/imgs/home/yiqi.png")}
              />
            </Flex>
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="thermo-logo"
                src={require("assets/imgs/home/thermo.png")}
              />
            </Flex>
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="envision-logo"
                src={require("assets/imgs/home/envision.png")}
              />
            </Flex>
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="thy-logo"
                src={require("assets/imgs/home/thy.png")}
              />
            </Flex>
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="nut-logo"
                src={require("assets/imgs/home/nut.png")}
              />
            </Flex>
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="huaxing-logo"
                src={require("assets/imgs/home/huaxing.png")}
              />
            </Flex>
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="hupu-logo"
                src={require("assets/imgs/home/hupu.png")}
              />
            </Flex>
            <Flex justify="center" align="center" className="custom">
              <ImgContainer
                className="wifi-logo"
                src={require("assets/imgs/home/wifi.png")}
              />
            </Flex>
          </Flex>
        </div>
        <CollapseMenu status="singleCode"/>
        <div className="icp">
          <div>©2014-2016科锐数字科技（苏州）有限公司 </div>
          <div>苏ICP备14059286号-2 </div>
          <div className="report">朝阳区人力资源与社会保障局 监督电话:57596212，65090445</div>
          <div>
            <a href="https://www.geetemp.com/geetemp/default/business-license.jpg">营业执照 </a>| 
            <a href="https://www.geetemp.com/geetemp/default/hrs-license.jpg"> 服务许可证名称320510190032</a>
          </div>
        </div>
        <BottomFiexdPanel history={this.props.history} />
      </StickyContainer>
    );
  }
}

export default HomePage;
