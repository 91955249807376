import React, { Component } from "react";
import { connect } from "react-redux";
import "./person-info.scss";
import {
  Button,
  Flex,
  List,
  InputItem,
  ImagePicker,
  Toast
} from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import user from "store/reducers/userModel";
import BottomPanel from "pages/talentPool/BottomPanel";
import { modifyPersonInfo } from "services/userService";
import Upload from "rc-upload";
import ImgContainer from "components/img";
@connect(
  ({ user }) => {
    return { ...user };
  },
  {
    ...user.actions
  }
)
export default class PersonInfo extends Component {
  componentDidMount() {
    this.props.getPersonageInfo().then(res => {
      if (res.code === 0) {
        const { user_info = {} } = res.data;
        console.log('user_info',user_info)
        this.onChangeVal("name", user_info.name);
        this.onChangeVal("position_name", user_info.position_name);
        this.onChangeVal("avatar", user_info.avatar);
        this.onChangeVal("email", user_info.email);
      }
    });
  }
  /**
   * @description 上传成功后赋值
   */
  uploadLogoSuccess = file => {
    if (file.code === 0) {
      this.onChangeVal("avatar", file.data);
    }
  };
  /**
   * @description 设置参数数据
   * @param {string} name
   * @param {string} val
   */
  onChangeVal = (name, val) => {
    const { setUpdateState, personParam } = this.props;
    setUpdateState({
      filed: "personParam",
      value: { ...personParam, [name]: val }
    });
  };
  /**
   * @description 是否通过验证
   */
  noFilled = nameObj => {
    const { name, position_name,email } = nameObj;
    const emailReg = /^[a-zA-Z0-9!#$%&\'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\/=?^_`{|}~-]+)*@(?:[-_a-zA-Z0-9][-_a-zA-Z0-9]*\.)*(?:[a-zA-Z0-9][-a-zA-Z0-9]{0,62})\.(?:(?:[a-zA-Z]{2}\.)?[a-zA-Z]{2,})$/;
    if (!name) {
      Toast.info("请输入姓名", 2);
      return;
    }
    if (!position_name) {
      Toast.info("请输入担任职位", 2);
      return;
    }
    if (!emailReg.test(email)) {
      Toast.info("企业邮箱格式不正确", 2);
      return;
    }
    return true;
  };
  /**
   * @description 提交个人信息确定
   */
  submit = val => {
    console.log("提交了");
    const { personParam = {} } = this.props;
    if (this.noFilled(personParam)) {
      const dataParams = {
        ...personParam
      };
      modifyPersonInfo(dataParams).then(res => {
        if (res.code === 0) {
          this.props.history.push("/center");
        } else {
          Toast.info(res.transformError);
        }
      });
    }
  };
  render() {
    const { personParam = {}, centerInfo = {} } = this.props;
    const { user_info = {} } = centerInfo;

    return (
      <div className="person-info-box">
        <TopNavBar title="个人信息" />
        <div className="head">
          <Upload
            action="/wap/v2_0/common/uploadFile"
            data={{ type: "attachment" }}
            onSuccess={this.uploadLogoSuccess}
          >
            <div className="img-box">
              <ImgContainer
                src={
                  personParam.avatar
                    ? personParam.avatar
                    : require("assets/imgs/person-default.png")
                }
              />
            </div>
          </Upload>
        </div>
        <div className="form-box">
          <List>
            <InputItem
              value={personParam.name}
              onChange={this.onChangeVal.bind(this, "name")}
              placeholder="请输入"
            >
              姓名
            </InputItem>
            <InputItem
              value={personParam.position_name}
              onChange={this.onChangeVal.bind(this, "position_name")}
              placeholder="请输入"
            >
              担任职位
            </InputItem>
            <InputItem value={user_info.phone} editable={false} disabled={false}>
              联系电话
            </InputItem>
            <InputItem 
             value={personParam.email}
             onChange={this.onChangeVal.bind(this, "email")}
            >
              企业邮箱
            </InputItem>
          </List>
          <BottomPanel onClick={this.submit}>确定</BottomPanel>
        </div>
      </div>
    );
  }
}
