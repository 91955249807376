import React, { Component } from "react";
import { connect } from "react-redux";
import "./my-company.scss";
import { Toast, Flex, List, InputItem, Picker, TextareaItem } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import { Link } from "react-router-dom";
import userModel from "store/reducers/userModel";
import commonModel from "store/reducers/commonModel";
import { commonData } from "services/commonService";
import Upload from 'rc-upload';
import TopNavBar from "components/top-navbar/navbar";
import ImgContainer from "components/img";
import underscore from "underscore";

@connect(
  ({ user, common }) => {
    return { 
      common,
      user,
      userDetail: user.info,
      user_info: user.centerInfo.user_info,
      company_info: user.centerInfo.company_info,
      jd: user.centerInfo.jd
    };
  },
  {
    ...userModel.actions,
    getSystemInfos: commonModel.actions.getSystemInfos
  }
)
class MyCompany extends Component {

  state = {
    companyParam: {},
    industries: [],
    company_type: [],
    scales: [],
    cities: [],
  }

  transformArray = (source) => {
    return source.map(item => {
      let desData = {
        value: item.id,
        label: item.name,
      }
      if (item.list) desData["children"] = this.transformArray(item.list);
      return desData;
    })
  }

  componentDidMount() {
    const { getPersonageInfo, bindedLogin } = this.props;
    commonData(["industries", "company_type", "scales", "cities"]).then(res => {
      this.setState({
        industries: res.industries,
        company_type: res.company_type,
        scales: res.scales,
        cities: this.transformArray(res.cities ? res.cities.cityList : []),
      })
    });
    bindedLogin();
    getPersonageInfo().then(res => {
      if (res.code === 0) {
        let company_info = res.data.company_info;
        let user_info = res.data.user_info;
        this.onFieldChange("name", company_info.name, "companyParam");
        this.onFieldChange("contact_no", company_info.contact_no, "companyParam");
        this.onFieldChange("address", company_info.address, "companyParam");
        this.onFieldChange("desc", company_info.desc, "companyParam");
        this.onFieldChange("logo", company_info.logo, "companyParam");
        this.onFieldChange("industries", [company_info.industry], "companyParam");
        this.onFieldChange("company_type", [company_info.company_type], "companyParam");
        this.onFieldChange("scales", [company_info.scale], "companyParam");
        this.onFieldChange("cities", [company_info.province, company_info.city, company_info.region], "companyParam");
        this.onFieldChange("business_license", company_info.business_license, "companyParam");
      }
    });
  }

  onFieldChange = (field, val, obj) => {
    let stateObj = {};
    if (obj) {
      this.state[obj][field] = val;
    } else {
      stateObj[field] = val;
    }  
    this.setState({...this.state, ...stateObj});
  }

  uploadCerSuccess = (file) => {
    if (file.code === 0) {
      this.onFieldChange("business_license", file.data, "companyParam");
    }
  }

  uploadLogoSuccess = (file) => {
    if (file.code === 0) {
      this.onFieldChange("logo", file.data, "companyParam");
    }
  }

  beforeUpload = (file) => {
    console.log(file)
  }

  saveClick= () => {
    // TODO verify & process
    const {editCompany, history} = this.props;
    const {companyParam} = this.state;
    if (underscore.isEmpty(companyParam.name)) {
      Toast.info("公司名称不能为空！", 1);
      return;
    }
    if (!companyParam.company_type[0]) {
      Toast.info("企业性质不能为空！", 1);
      return;
    }
    if (!companyParam.desc) {
      Toast.info("公司描述不能为空！", 1);
      return;
    }
    if (companyParam.desc.length < 20) {
      Toast.info("公司描述最少20个字符！", 1);
      return;
    }
    let companyPostParam = {};
    companyPostParam.name = companyParam.name;
    companyPostParam.company_type = companyParam.company_type[0];
    companyPostParam.industry = companyParam.industries[0];
    companyPostParam.scale = companyParam.scales[0];
    companyPostParam.province_id = companyParam.cities[0];
    companyPostParam.city_id = companyParam.cities[1];
    companyPostParam.region_id = companyParam.cities[2];
    companyPostParam.contact_no = companyParam.contact_no;
    companyPostParam.address = companyParam.address;
    companyPostParam.desc = companyParam.desc;
    if (companyParam.logo) {
      companyPostParam.logo = companyParam.logo;
    }
    if (companyParam.business_license) {
      companyPostParam.business_license = companyParam.business_license;
    }
    editCompany(companyPostParam).then(res => {
      if (res.code === 0) {
        Toast.info("保存成功！", 1);
        history.push("/center");
      } else {
        Toast.info(res.transformError, 1);
      }
    })
  }

  deleteLicense = (e) => {
    e.stopPropagation();
    this.onFieldChange("business_license", "", "companyParam");
  }

  render() {
    const { userDetail, user_info = {}, company_info = {}, jd ={}, history} = this.props;
    const { industries, company_type, scales, cities, companyParam = {} } = this.state;
    return (
      <div className="mycompany-page">
        <TopNavBar className="" title="企业信息" />
        {
          company_info.status === "0" && <div className="auth-status fail"><CustomIcon type="iconwarning-circle"/><span>审核失败，请重新提交认证</span></div>
        }
        {
          company_info.status === "1" && <div className="auth-status"><CustomIcon type="iconwarning-circle"/><span>未认证</span></div>
        }
        {
          company_info.status === "2" && <div className="auth-status ready"><CustomIcon type="iconcheck-circle"/><span>已认证</span></div>
        }
        {
          company_info.status === "3" && <div className="auth-status ready"><CustomIcon type="iconwarning-circle"/><span>待审核</span></div>
        }
        <List className="mycompany-edit-form">
          <List.Item className="upload-logo"
            extra={<Upload action="/wap/v2_0/common/uploadFile" data={{ type: "attachment" }} onSuccess={this.uploadLogoSuccess} beforeUpload={this.beforeUpload}>
              <img src={companyParam.logo ? companyParam.logo : require("assets/imgs/default-company.png")} className="default-company" />
            </Upload>}>
            企业logo
          </List.Item>
          <InputItem 
            value={companyParam.name}
            onChange={(val) => this.onFieldChange("name", val, "companyParam")}
            placeholder="请输入"><span className="require">*</span>公司名称</InputItem>
          <Picker 
            data={industries} cols={1} value={companyParam.industries}
            onChange={(val) => this.onFieldChange("industries", val, "companyParam")}
            extra={<div className="do-sel">请选择</div>}>
            <List.Item>所属行业</List.Item>
          </Picker>
          <Picker 
            data={company_type} cols={1} value={companyParam.company_type}
            onChange={(val) => this.onFieldChange("company_type", val, "companyParam")}
            extra={<div className="do-sel">请选择</div>}>
            <List.Item><span className="require">*</span>企业性质</List.Item>
          </Picker>
          <Picker 
            data={scales} cols={1} value={companyParam.scales}
            onChange={(val) => this.onFieldChange("scales", val, "companyParam")}
            extra={<div className="do-sel">请选择</div>}>
            <List.Item>企业规模</List.Item>
          </Picker>
          <InputItem 
            value={companyParam.contact_no}
            onChange={(val) => this.onFieldChange("contact_no", val, "companyParam")}
            placeholder="请输入">公司电话</InputItem>
            {/* <CustomIcon className="iconright" type="iconright" /> */}
          <Picker 
            data={cities} value={companyParam.cities}
            onChange={(val) => this.onFieldChange("cities", val, "companyParam")}
            extra={<div className="do-sel">请选择</div>}>
            <List.Item >所在地区</List.Item>
          </Picker>
          <InputItem 
            value={companyParam.address}
            onChange={(val) => this.onFieldChange("address", val, "companyParam")}
            placeholder="请输入">详细地址</InputItem>
          <div className="desc-wrapper">
            <TextareaItem title={<React.Fragment><span className="require">*</span>公司描述</React.Fragment>}
              value={companyParam.desc} className="desc-textarea" rows={3}
              onChange={(val) => this.onFieldChange("desc", val, "companyParam")}
              placeholder="请输入"></TextareaItem>
          </div>
        </List>
        <div className="upload-wrapper">
          <Upload action="/wap/v2_0/common/uploadFile" data={{ type: "attachment" }} onSuccess={this.uploadCerSuccess} beforeUpload={this.beforeUpload}>
            {companyParam.business_license
              ?
              <ImgContainer className="license" src={companyParam.business_license} alt="上传营业执照">
                <div className="delete-btn" onClick={this.deleteLicense}></div>
              </ImgContainer>
              :
              <div className="upload-cer"><React.Fragment><CustomIcon type="iconchuangjian" />营业执照</React.Fragment></div>
            }
          </Upload>
        </div>
        <div className="detail-intro">
          <div>企业认证审核标准</div>
          <div>1、所填企业名称（或简称）必须与营业执照上企业名称一致。</div>
          <div>2、营业执照需清晰且在有效期内。</div>
          <div>3、全国企业信用信息公示系统上可查询到且信息一致有效。</div>
          <div>4、如已办三证合一，需提供最新的有统一社会信用代码</div>
        </div>
        {
          company_info.status !== "3" && company_info.status !== "2" && <div className="save-btn" onClick={this.saveClick}>保存</div>
        }
        
      </div>
    );
  }
}

export default MyCompany;
