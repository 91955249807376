import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./position-detail.scss";
import { Toast, Flex, Menu, Steps } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import CustomModal from "components/modal/modal";
import TopNavBar from "components/top-navbar/navbar";
import userModel from "store/reducers/userModel";
import myPositionModel from "store/reducers/myPositionModel";

const Step = Steps.Step;
const menuFilter = [
  {
    value: "1",
    label: "全部职位"
  },
  {
    value: "2",
    label: "待核实"
  },
  {
    value: "3",
    label: "进行中"
  },
  {
    value: "4",
    label: "已暂停"
  },
  {
    value: "5",
    label: "已完成"
  },
  {
    value: "6",
    label: "已取消"
  }
];

function createMarkup(html = "") {
  if (!html) html = "";
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}

@connect(
  ({ myPositionModel }) => {
    return { 
      position: myPositionModel,
      positionProgress: myPositionModel.positionProgress,
      progressNum: myPositionModel.positionProgress.progressNum,
    };
  },
  {
    ...userModel.actions, ...myPositionModel.actions
  }
)
class PositionDetail extends Component {
  static async getInitialProps({ req, res, match, history, location, ...ctx }) {
    return { whatever: "stuff" };
  }

  state = {
    stopVisible: false
  };

  componentDidMount() {
    const {getPositionDetail, getPositionProgress, match} = this.props;
    getPositionDetail({jd_id: match.params.id}).then(res => {
      if (res.code === 0) {
        
      } else {
        Toast.info(res.transformError);
      }
    })

    getPositionProgress({jd_id: match.params.id}).then(res => {})
  }

  onFieldChange = (field, val) => {
    let stateObj = {};
    stateObj[field] = val;
    this.setState({ ...stateObj });
  };


  menuChange = item => {
    let currentMenuLabel = "";
    menuFilter.forEach(menu => {
      if (menu.value === item[0]) {
        currentMenuLabel = menu.label;
      }
    });
    this.setState({
      menuShow: false,
      menuSel: item,
      menuLabel: currentMenuLabel
    });
  };

  cityShow = (province, city, region) => {
    if (province === city) return city + " " + region;
    return province + " " + city + " " + region;
  };

  getSteps = (current, statusNum) => {
    const {positionProgress} = this.props;
    if (current === 0) {
      return <Step key={current} status={`${current == statusNum ? "process" : "finish"}`} 
        title={<div className="step-des-title">提交需求：<span className="step-des">{positionProgress.status_submit_request && positionProgress.status_submit_request.time}</span></div>} />;
    }
    if (current === 1) {
      return <Step key={current} status={`${current == statusNum ? "process" : "finish"}`}
        title={<div className="step-des-title">推荐人选：<span className="step-des">{positionProgress.status_recommend_profile && positionProgress.status_recommend_profile.time}</span></div>} />;
    }
    if (current === 2) {
      return <Step key={current} status={`${current == statusNum ? "process" : "finish"}`}
        title={<div className="step-des-title">面试入职：<span className="step-des">{positionProgress.status_interview && positionProgress.status_interview.time}</span></div>} />;
    }
    if (current === 3) {
      return <Step key={current} status={`${current == statusNum ? "process" : "finish"}`}
        title={<div className="step-des-title">暂停职位</div>} />;
    }
    if (current === 4) {
      return <Step key={current} status={`${current == statusNum ? "process" : "finish"}`}
        title={<div className="step-des-title">服务完成：<span className="step-des">{positionProgress.status_service_completed && positionProgress.status_service_completed.time}</span></div>} />;
    }
    if (current === 5) {
      return <Step key={current} status="finish"
        description={<div className="step-des-title">关闭原因：<span className="step-des">{positionProgress.cancel_reason}</span></div>}
        title={<div className="step-des-title">职位关闭：<span className="step-des">{positionProgress.cancel_time}</span></div>} />;
    }
  }

  // createSteps = () => {
  //   const {positionProgress} = this.props;
  //   let steps = [];
  //   let statusNum = 0;
  //   if (positionProgress.status === 3 || positionProgress.status === 4 || positionProgress.status === 5) {
  //     statusNum = positionProgress.last_status;
  //   } else {
  //     statusNum = positionProgress.status;
  //   }
  //   for (let current = 0; current <= statusNum; current++) {
  //     steps.push(this.getSteps(current, positionProgress.status));
  //   }
  //   if (positionProgress.status === 3) {
  //     steps.push(this.getSteps(3, 3));
  //   }
  //   if (positionProgress.status === 4 || positionProgress.status === 5) {
  //     steps.push(this.getSteps(positionProgress.status, positionProgress.status));
  //   }
  //   return steps;
  // }
  createSteps = () => {
    const {positionProgress = []} = this.props;
    console.log(positionProgress)
    return positionProgress.map((item, index) => {
      return (
        <Step key={index} 
          description={item.status == 5 ? <div className="step-des-title">关闭原因：<span className="step-des">{item.cancel_reason}</span></div> : null}
          title={<div className="step-des-title">{item.status_name}：<span className="step-des">{item.time}</span></div>}
        />
      )
    })
  }

  stopPosition = (type) => {
    if (type === 1) {
      this.setState({
        stopVisible: true,
        // beginVisible: false,
        stopType: type
      })
    } else {
      this.setState({
        beginVisible: true,
        // stopVisible: false,
        stopType: type
      })
    }
    
  }

  confirmSure = () => {
    const {stopPosition, match, getPositionProgress, getPositionDetail} = this.props;
    stopPosition({jd_id: match.params.id, type: this.state.stopType}).then(res => {
      if (res.code === 0) {
        getPositionDetail({jd_id: match.params.id}).then(res => {
        })
        getPositionProgress({jd_id: match.params.id}).then(res => {})
      } else {
        Toast.info(res.transformError, 1);
      }
      this.setState({
        stopVisible: false,
        beginVisible: false
      })
    })
  }

  confirmCancel = () => {
    this.setState({
      stopVisible: false,
      beginVisible: false
    })
  }

  render() {
    const { stopVisible, beginVisible } = this.state;
    const { history, position: {positionDetail}, progressNum, positionProgress, match} = this.props;
    let positionBase = [];
    positionDetail.work_type_name && positionBase.push(positionDetail.work_type_name);
    positionDetail.service_type_name && positionBase.push(positionDetail.service_type_name);
    positionDetail.require_num && positionBase.push(positionDetail.require_num + "人");
    let city = this.cityShow(
      positionDetail.province_name,
      positionDetail.city_name,
      positionDetail.region_name
    );
    city && positionBase.push(city);

    let topExtra = null;
    if (positionDetail.status === 1 || positionDetail.status === 2) {
      topExtra = (
        <span onClick={() => this.stopPosition(1)}>
          <CustomIcon type="iconzanting" className="stop-icon"/> 暂停职位
        </span>
      )
    }
    if (positionDetail.status === 3) {
      topExtra = (
        <span onClick={() => this.stopPosition(2)}>
          <CustomIcon type="iconkaishi" className="stop-icon"/> 开始职位
        </span>
      )
    }

    return (
      <div className="position-detail">
        <TopNavBar
          className="topbar"
          title="职位详情"
          extra={topExtra}
        />
        <CustomModal 
          title="暂停职位" content="暂停职位后将无法接受最新人选信息，确定暂停？"
          visible={stopVisible} onSure={this.confirmSure} onCancel={this.confirmCancel}></CustomModal>
        <CustomModal 
          title="开始职位" content="确定开始职位吗？"
          visible={beginVisible} onSure={this.confirmSure} onCancel={this.confirmCancel}></CustomModal>
        <div className="position-base">
          <Flex className="base" justify="between">
            <div className="position ellipsis">{positionDetail.post_name}</div>
            {/* <div className="status ready">待核实</div> */}
            {positionDetail.status === 0 && <div className="status ready">{positionDetail.status_name}</div>}
            {(positionDetail.status === 1 || positionDetail.status === 2)
              && <div className="status ing">{positionDetail.status_name}</div>}
            {(positionDetail.status === 3 || positionDetail.status === 4 || positionDetail.status === 5)
              && <div className="status unop">{positionDetail.status_name}</div>}
          </Flex>
          <Flex className="other">{positionBase.join(" | ")}</Flex>
        </div>
        <div className="position-info">
          <Flex className="detail-header" justify="between">
            <Flex className="title" align="center"><span className="line"></span><span className="text">职位信息</span></Flex>
            {
              !(positionDetail.status === 4 || positionDetail.status === 5) && 
                <div className="operate-btn" onClick={() => history.push("/position-edit/" + match.params.id + "/detail")}>
                  <CustomIcon type="iconedit"/>编辑
                </div>
            }
          </Flex>
          {
            positionDetail.jd_desc &&
              <div className="section pos-des">
                <div className="section-title">职位描述</div>
                <div className="section-content" dangerouslySetInnerHTML={createMarkup(positionDetail.jd_desc)}></div>
              </div>
          }  
          {
            positionDetail.requirements &&
              <div className="section pos-des">
                <div className="section-title">任职要求</div>
                <div className="section-content" dangerouslySetInnerHTML={createMarkup(positionDetail.requirements)}></div>
              </div>
          }  
          {!(!positionDetail.education_name && !positionDetail.work_time_name && !positionDetail.salary_name &&
            (!positionDetail.benefits || positionDetail.benefits.length === 0)) &&
            <div className="section">
              <div className="section-title">其他描述</div>
              {positionDetail.education_name && <div className="section-content">学历：{positionDetail.education_name}</div> }
              {positionDetail.work_time_name && <div className="section-content">工作经验：{positionDetail.work_time_name}</div> }
              {positionDetail.salary_name && <div className="section-content">薪资：{positionDetail.salary_name}</div> }
            </div>
          }
          {positionDetail.benefits && positionDetail.benefits.length !== 0 &&
            <div className="section">
              <div className="section-title">福利</div>
              <div className="section-content">
                {positionDetail.benefits.map((item, index) => {
                  return <div key={index} className="tag">{item.name}</div>
                })}
              </div>
            </div>
          }
          {
            positionDetail.address &&
              <div className="section">
                <div className="section-title">工作地址</div>
                <div className="section-content">{positionDetail.address}</div>
              </div>
          }
        </div>
        <div className="position-data">
          <Flex className="detail-header" justify="between">
            <Flex className="title" align="center"><span className="line"></span><span className="text">招聘数据(人才招聘)</span></Flex>
            <div className="operate-btn" onClick={() => history.push("/position-talent/all/" + match.params.id)}>查看全部<CustomIcon type="iconright"/></div>
          </Flex>
          <Flex className="data-map" justify="between" >
            <Flex className="data-item" wrap="wrap" justify="center" onClick={() => history.push("/position-talent/recommend/" + match.params.id)}>
              <div className="pop-num">
                {positionDetail.profile_num && positionDetail.profile_num.recommended_num}
              </div>
              <div className="text"> {positionDetail.new_rmd_num > 0 && <div className="red-point"></div>}已推荐</div>
            </Flex>
            <Flex className="data-item" wrap="wrap" justify="center" onClick={() => history.push("/position-talent/reserve/" + match.params.id)}>
              <div className="pop-num">{positionDetail.profile_num && positionDetail.profile_num.reserved_num}</div><div className="text">已预约</div>
            </Flex>
            <Flex className="data-item" wrap="wrap" justify="center" onClick={() => history.push("/position-talent/interview/" + match.params.id)}>
              <div className="pop-num">{positionDetail.profile_num && positionDetail.profile_num.arrange_interview_num}</div><div className="text">安排面试</div>
            </Flex>
          </Flex>
        </div>
        <div className="position-progress"> 
          <Flex className="detail-header" justify="between">
            <Flex className="title" align="center"><span className="line"></span><span className="text">职位进展</span></Flex>
          </Flex>
          <Steps current={positionProgress.length - 1} className="progress-step">
            {this.createSteps()}
          </Steps>
        </div>
      </div>
    );
  }
}

export default PositionDetail;
