import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "underscore";
import { Link } from "react-router-dom";
import "./resume-detail.scss";
import {
  Button,
  Flex,
  Menu,
  Accordion,
  Icon,
  InputItem
} from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import talentModel from "store/reducers/talentModel";
import CustomIcon from "components/custom-icon/custom-icon";
import { openwindow } from "utils/util";
import * as talentService from "services/talentService";
import ImgContainer from "components/img";
import HomeTopHeader from "pages/home/home-top-header";
import { StickyContainer, Sticky } from "react-sticky";
import CollapseMenu from "components/collapse-menu/collapse-menu";

function createMarkup(html = "") {
  if (!html) html = "";
  return { __html: html.replace(/\r\n|\r|\n/g, "<br/>") };
}
@connect(
  ({ talentModel, user }) => {
    return { ...talentModel, user };
  },
  {
    ...talentModel.actions
  }
)
export default class ResumeDetail extends Component {
  state = {
    isloading: true
  };
  componentDidMount() {
    const { getTalentDetail } = this.props;
    const { params = {} } = this.props.match;
    let talentParam = {};
    talentParam.profile_id = params.id
    talentParam.jd_id = params.jd_id;
    getTalentDetail(talentParam).then(() => this.setState({ isloading: false }));
  }

  /**
   * @description 收藏或取消收藏
   */
  onCollect = () => {
    const { setUpdateState, resumeInfo = {} } = this.props;
    const { params = {} } = this.props.match;
    talentService.isCollect({ profile_id: params.id }).then(res => {
      if (res.code === 0) {
        setUpdateState({
          filed: "resumeInfo",
          value: {
            ...resumeInfo,
            is_collection: resumeInfo.is_collection === 0 ? 1 : 0
          }
        });
      }
    });
  };
  goOrder = (jd, status_value, online) => {
    if (
      (this.statusShow(jd) && status_value == 0) ||
      status_value == 5 ||
      (!this.statusShow(jd) && online == 1)
        ? false
        : true
    )
      return;
    this.props.history.push("/interview/" + this.props.resumeInfo.id);
  };

  statusShow = status => {
    if (JSON.stringify(status) == "{}") {
      return false;
    }
    return true;
  };
  isAble = info => {};

  render() {
    const { resumeInfo = {}, history, user } = this.props;
    const { is_intern = 0, is_collection, jd = {}, online, id } = resumeInfo;
    const { status_value } = jd;
    if (this.state.isloading) {
      return <Icon style={{ margin: "40% 45%" }} type="loading" />;
    } else {
      return (
        <StickyContainer className="resume-detail-container">
          <HomeTopHeader history={history} user={user} />
          <div
            className="header"
            style={
              id && {
                background: `url(${require(`assets/imgs/resume-bg${is_intern}.jpg`)})`,
                backgroundSize: "100% 100%"
              }
            }
          >
            <BaseInfo resumeInfo={resumeInfo} />
          </div>
          <div className="record">
            {is_intern ? (
              <React.Fragment>
                <EducationRecord resumeInfo={resumeInfo} />
                <IntershipRecord resumeInfo={resumeInfo} />
                <CertificateRecord resumeInfo={resumeInfo} />
                <InternIntentionRecord resumeInfo={resumeInfo} />
                <EvaluateRecord resumeInfo={resumeInfo} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <WorkRecord resumeInfo={resumeInfo} />
                <EducationRecord resumeInfo={resumeInfo} />
                <IntentionRecord resumeInfo={resumeInfo} />
                <EvaluateRecord resumeInfo={resumeInfo} />
              </React.Fragment>
            )}
            {JSON.stringify(resumeInfo) !== "{}" && (
              <div className="bottom-panel-box">
                <div className="handle-area clear">
                  <div className="collect-btn" onClick={this.onCollect}>
                    <CustomIcon
                      className="iconshoucang"
                      type={`${is_collection === 0 ? "iconsc" : "iconscs"}`}
                    />
                    <br />
                    <span className="single-size">
                      {`${is_collection === 0 ? "" : "已"}收藏`}
                    </span>
                  </div>
                  <div className="sonsult-btn">
                    <Button
                      type="ghost"
                      onClick={() =>
                        openwindow(
                          "https://p.qiao.baidu.com/cps/chat?siteId=13265485&userId=25613354",
                          800,
                          500
                        )
                      }
                      className="am-button-borderfix btn-style"
                    >
                      在线咨询
                    </Button>
                  </div>
                  <div
                    className="subscribe-btn"
                    onClick={this.goOrder.bind(this, jd, status_value, online)}
                  >
                    <Button
                      type="primary"
                      className={`am-button-borderfix btn-style ${
                        (this.statusShow(jd) && status_value == 0) ||
                        status_value == 5 ||
                        (!this.statusShow(jd) && online == 1)
                          ? false
                          : "btn-disable"
                      }`}
                      disabled={
                        (this.statusShow(jd) && status_value == 0) ||
                        status_value == 5 ||
                        (!this.statusShow(jd) && online == 1)
                          ? false
                          : true
                      }
                    >
                      {this.statusShow(jd)
                        ? jd.status_label
                        : online === 0
                        ? "已下架"
                        : "立即预约"}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <CollapseMenu/>
        </StickyContainer>
      );
    }
  }
}
export class BaseInfo extends React.PureComponent {
  render() {
    const { resumeInfo = {} } = this.props;
    const { is_intern, id } = resumeInfo;
    let imgSrc = require("assets/imgs/man.jpg");
    if (resumeInfo.picture) {
      imgSrc = resumeInfo.picture;
    } else if (resumeInfo.is_intern === 0) {
      imgSrc =
        resumeInfo.sex == "男"
          ? require("assets/imgs/man.jpg")
          : require("assets/imgs/woman.jpg");
    } else if (resumeInfo.is_intern === 1) {
      imgSrc =
        resumeInfo.sex == "男"
          ? require("assets/imgs/student-man.jpg")
          : require("assets/imgs/student-woman.jpg");
    }
    // 基础信息1
    let baseFirsts = [];
    // resumeInfo.sex && baseFirsts.push(resumeInfo.sex);
    resumeInfo.education && baseFirsts.push(resumeInfo.education);
    if (!is_intern) {
      resumeInfo.worktime && baseFirsts.push(resumeInfo.worktime + "年经验");
      resumeInfo.forward_city && baseFirsts.push(resumeInfo.forward_city);
      resumeInfo.school && baseFirsts.push(resumeInfo.school);
    }

    // 基础信息2
    if (!is_intern) {
      // resumeInfo.marry && baseFirsts.push(resumeInfo.marry);
      resumeInfo.salary_expect &&
        baseFirsts.push("期望薪资" + resumeInfo.salary_expect + "K/月");
    } else {
      resumeInfo.school && baseFirsts.push(resumeInfo.school);
      resumeInfo.graduation_time &&
        baseFirsts.push(resumeInfo.graduation_time + "毕业");
    }
    return (
      <div className="top-info-box clear">
        <div className="right-id">ID:{resumeInfo.no || ""}</div>
        <div className="base-info clear">
          <div className="base-left">{id && <img src={imgSrc} />}</div>
          <div className="base-right">
            <div className="name">
              {resumeInfo.name} {resumeInfo.position_name}{" "}
              {!!resumeInfo.age && resumeInfo.age}
              {!!resumeInfo.age && "岁"}
            </div>
            <div className="row">{baseFirsts.join(" | ")}</div>
          </div>
        </div>
      </div>
    );
  }
}

// 工作经历
class WorkRecord extends React.PureComponent {
  state = {
    openStatus: false
  };
  onOpen = () => {
    this.setState({ openStatus: !this.state.openStatus });
  };
  render() {
    const { resumeInfo } = this.props;
    const { openStatus } = this.state;
    if (!resumeInfo.job || resumeInfo.job.length === 0) {
      return null;
    }
    return (
      <div className="work-record-box">
        <div className="profile-section">
          <div className="section-title">
            <span className="section icon  icon-experience" />
            <span className="section title">工作经历</span>
            <span className="section line" />
          </div>
          <div
            style={{
              height: `${openStatus ? "auto" : "166px"}`,
              overflow: "hidden"
            }}
          >
            {resumeInfo.job.map((item, index) => {
              return (
                <div className="info" key={index}>
                  <div className="name">
                    {item.company_name}
                    {item.company_scale && `(${item.company_scale})`}
                  </div>
                  <div className="time">
                    {item.begin_time} - {item.end_time}
                  </div>
                  <div className="other-info">
                    {item.department && `部门：${item.department}`}
                    {item.department && item.position && (
                      <span className="line" />
                    )}
                    {item.position && `职位：${item.position}`}
                  </div>
                  <div className="duty">工作职责：</div>
                  <div
                    className="des-info"
                    dangerouslySetInnerHTML={createMarkup(item.duty)}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="is-show" onClick={this.onOpen}>
          全部{openStatus ? "关闭" : "展开"}
          <ImgContainer
            className="open-img"
            style={{
              transform: openStatus ? "rotate(180deg)" : "rotate(0deg)"
            }}
            src={require("assets/imgs/icons/icon-resume-fold.png")}
          />
        </div>
      </div>
    );
  }
}
// 教育经历
class EducationRecord extends React.PureComponent {
  render() {
    const { resumeInfo } = this.props;
    const { is_intern } = resumeInfo;
    if (!resumeInfo.edu || resumeInfo.edu.length === 0) {
      return null;
    }
    return (
      <div className="education-record-box">
        <div className="profile-section">
          <div className="section-title">
            <span className="section icon  icon-edu" />
            <span className="section title">教育经历</span>
            <span className="section line" />
          </div>
          {resumeInfo.edu.map((item, index) => {
            return (
              <div className="info" key={index}>
                <div className="name">{item.school_name}</div>
                <div className="time">
                  {item.begin_time} - {item.end_time}
                </div>
                <div className="other-info">
                  {is_intern === 0
                    ? item.is_unified
                    : item.is_unified === "是"
                    ? "统招"
                    : "非统招"}
                  {item.is_unified && item.major_name && (
                    <span className="line" />
                  )}
                  {item.major_name && `专业：${item.major_name}`}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

// 求职意向
class IntentionRecord extends React.PureComponent {
  render() {
    const {
      resumeInfo: { job_inten = [] }
    } = this.props;
    if (!job_inten[0] && !job_inten[1]) {
      return null;
    }
    return (
      <div className="intention-record-box">
        <div className="profile-section">
          <div className="section-title">
            <span className="section icon icon-intention" />
            <span className="section title">求职意向</span>
            <span className="section line" />
          </div>
          {job_inten[0] && (
            <div className="info">
              <div className="name">离职原因</div>
              <div className="des-info">
                <span dangerouslySetInnerHTML={createMarkup(job_inten[0])} />
              </div>
            </div>
          )}
          {job_inten[1] && (
            <div className="info">
              <div className="name">公司期望</div>
              <div className="des-info">
                <span dangerouslySetInnerHTML={createMarkup(job_inten[1])} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

// 顾问评价
class EvaluateRecord extends React.PureComponent {
  render() {
    const {
      resumeInfo: { hh_appraisal = {} }
    } = this.props;
    if (
      // !hh_appraisal.avatar &&
      // !hh_appraisal.name &&
      // !hh_appraisal.update_time &&
      !hh_appraisal.appraisal
    ) {
      return null;
    }
    return (
      <div className="evaluate-record-box">
        <div className="profile-section">
          <div className="section-title">
            <span className="section icon icon-evaluate" />
            <span className="section title">顾问评价</span>
            <span className="section line" />
          </div>
          <div className="help-ev">
            <div className="help-item">
              <div className="helper">
                <ImgContainer
                  src={
                    hh_appraisal.avatar
                      ? hh_appraisal.avatar
                      : require("assets/imgs/icon-guwen.png")
                  }
                />
                <span>{hh_appraisal.name}</span>
              </div>
              <div className="ev-word">
                <div className="ev-arrow" />
                <div
                  className="appraisal"
                  dangerouslySetInnerHTML={createMarkup(hh_appraisal.appraisal)}
                />
              </div>
            </div>
            {/* )
          })} */}
          </div>
        </div>
      </div>
    );
  }
}
// 实习经历
class IntershipRecord extends React.PureComponent {
  render() {
    const { resumeInfo } = this.props;
    if (!resumeInfo.intern || resumeInfo.intern.length === 0) {
      return null;
    }
    return (
      <div className="intership-record-box">
        <div className="profile-section">
          <div className="section-title">
            <span className="section icon  icon-experience" />
            <span className="section title">实习经历</span>
            <span className="section line" />
          </div>
          {resumeInfo.intern.map((item, index) => {
            return (
              <div className="info" key={index}>
                <div className="name">
                  {item.company}
                  {item.company_scale && `(${item.company_scale})`}
                </div>
                <div className="time single-buttom">
                  {item.begin_time} - {item.end_time}
                </div>
                <div className="name">实习描述：</div>
                <div className="des-info">
                  <div dangerouslySetInnerHTML={{ __html: item.work_desc }} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
// 证书及认证
class CertificateRecord extends React.PureComponent {
  render() {
    const { resumeInfo } = this.props;
    if (!resumeInfo.skills || resumeInfo.skills.length === 0) {
      return null;
    }
    return (
      <div className="intership-record-box">
        <div className="profile-section">
          <div className="section-title">
            <span className="section icon  icon-certify" />
            <span className="section title">证书及认证</span>
            <span className="section line" style={{ width: "68%" }} />
          </div>
          {resumeInfo.skills.map((item, index) => {
            return (
              <div className="info" key={index}>
                <div className="name">{item.name}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
// 实习生求职意向
class InternIntentionRecord extends React.PureComponent {
  render() {
    const {
      resumeInfo: { job_inten = {} }
    } = this.props;
    if (
      !job_inten.expected_position &&
      !job_inten.intern_time &&
      !job_inten.forward_city &&
      !job_inten.weekly_working &&
      !job_inten.expect_daily_salary &&
      !job_inten.available
    ) {
      return null;
    }
    return (
      <div className="intership-record-box">
        <div className="profile-section">
          <div className="section-title">
            <span className="section icon  icon-intention" />
            <span className="section title">求职意向</span>
            <span className="section line" />
          </div>
          <div className="info">
            {job_inten.expected_position && (
              <div className="name">
                期望职位：
                <span className="single-color">
                  {job_inten.expected_position}
                </span>
              </div>
            )}
            {job_inten.intern_time && (
              <div className="name">
                实习时间：
                <span className="single-color">{job_inten.intern_time}</span>
              </div>
            )}
            {job_inten.forward_city && (
              <div className="name">
                期望城市：
                <span className="single-color">{job_inten.forward_city}</span>
              </div>
            )}
            {job_inten.weekly_working && (
              <div className="name">
                实习天数：
                <span className="single-color">
                  {job_inten.weekly_working}/周
                </span>
              </div>
            )}
            {/* {job_inten.expect_daily_salary && (
              <div className="name">
                期望日薪：
                <span className="single-color">
                  {job_inten.expect_daily_salary}元
                </span>
              </div>
            )}*/}
            {job_inten.available && (
              <div className="name">
                到岗时间：
                <span className="single-color">{job_inten.available}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
