/*
 * @Descripttion: 
 * @Date: 2021-06-21 14:53:06
 * @LastEditors: ci25578
 * @LastEditTime: 2021-06-22 14:01:57
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import "./introduce-detail.scss";
import { Flex } from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import HomeTopHeader from "pages/home/home-top-header";
import BottomFiexdPanel from "components/bottom-fied-panel/bottom-fied-panel";
import CollapseMenu from "components/collapse-menu/collapse-menu";

@connect(
  ({ user }) => {
    return { user };
  }
)
class IntroduceQuality extends Component {
  render() {
    const { history, user } = this.props;
    return (
      <div className="introduce-detail-page">
        {/* <TopNavBar title="服务介绍" /> */}
        <HomeTopHeader history={history} user={user} nosticky/>
        <div className="detail-1-cross">
          <div className="title">人才招聘</div>
          <div className="spliter"></div>
        </div>
        <div className="intro">
        针对中低端，基础性，通用性，非核心业务类岗位人才流动率大，招聘难，招聘周期长等问题，提供人才招聘服务，满足企业紧急的用人需求。
        </div>
        <div className="detail-2-cross"></div>
        <div className="content">
          <Flex className="title" align="center"><span className="line"></span>服务内容</Flex>
          <div className="content-detail">
          针对细分的通用类岗位，为企业量身定制招聘方案，开展需求分析，候选人匹配和推荐，面试评估，人才测评，背景调查服务等全方位人才招聘服务。
          </div>
        </div>
        <div className="detail-3-cross"></div>
        <div className="content service-value">
          <Flex className="title" align="center" justify="end"><span className="line"></span>服务价值</Flex>
          <div className="content-detail">
            <div className="content-detail-p content-detail-1">
              <span className="p-start">提高管理效率：</span>整合多样化渠道，从即派人才库精准匹配，快速找到最符合企业需求的人才，缩短招聘周期。
            </div>
            <div className="content-detail-p content-detail-2">
              <span className="p-start">降低招聘成本：</span>迅速填补岗位人才空缺，减少人力资源部门在招聘上的访寻，时间和人力成本。
            </div>
            <div className="content-detail-p content-detail-3">
              <span className="p-start">优化招聘流程：</span>专业化的招聘团队，帮助人力资源部门建立，优化标准的招聘流程。
            </div>
          </div>
        </div>
        <div className="detail-4-cross"></div>
        <div className="content">
          <Flex className="title" align="center"><span className="line"></span>案例分析</Flex>
          <div className="content-detail">
            <div className="content-detail-p content-detail-1">
              <span className="p-start">挑战：</span>以客服水平专业闻名的本土金融机构，急需100余名客服人员支持短期项目。
            </div>
            <div className="content-detail-p content-detail-2">
              <span className="p-start">方案：</span>成立专属寻访团队，应客户之需，针对其项目特点提供服务。入职前告知可能面临的压力及困难，确保候选人对职位有合理预期；入职后观察候选人工作现状，倾听难处给予疏导，增强归属感与稳定性。
            </div>
            <div className="content-detail-p content-detail-3">
              <span className="p-start">成果：</span>3个月内成功推荐60名客服入职，确保项目如期运行项目运作期间，推荐候选人稳定性。
            </div>
          </div>
        </div>
        <CollapseMenu status="singleCode"/>
        <div className="icp">
          <div>©2014-2016科锐数字科技（苏州）有限公司 </div>
          <div>苏ICP备14059286号-2 </div>
          <div className="report">朝阳区人力资源与社会保障局 监督电话:57596212，65090445</div>
          <div>
            <a href="https://www.geetemp.com/geetemp/default/business-license.jpg">营业执照 </a>| 
            <a href="https://www.geetemp.com/geetemp/default/hrs-license.jpg"> 服务许可证名称320510190032</a>
          </div>
        </div>
        <BottomFiexdPanel history={this.props.history} />
      </div>
    );
  }
}

export default IntroduceQuality;
