import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./login.scss";
import { Button, Flex, Toast } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import TopNavBar from "components/top-navbar/navbar";
import userModel from "store/reducers/userModel";
import { parseQueryString } from "utils/url";
import { isWeChatBrowser, getDomain } from "utils/util";
import ImgContainer from "components/img";
// var wx = require('weixin-js-sdk');
@connect(
  ({ user }) => {
    return { user };
  },
  {
    ...userModel.actions
  }
)
class LoginPage extends Component {
  static async getInitialProps({ req, res, match, history, location, ...ctx }) {
    return { whatever: "stuff" };
  }

  state = {
    loginType: true, // true-验证码登录， false-密码登录
    account: "",
    password: "",
    verify: "",
  };

  componentDidMount() {
    const {location} = this.props;
    let search = parseQueryString(decodeURIComponent(location.search));
    // if (!search.state && isWeChatBrowser()) { // 微信浏览器无state
    //   window.location.href = getDomain() + "/wap/v2_0/weixin/login?currentUrl=";
    // }
  }

  changeLoginType = () => {
    this.setState({
      loginType: !this.state.loginType
    });
  };

  loginClick = () => {
    const { generalLogin, quickLogin, history, location } = this.props;
    const { account, password, verify, loginType } = this.state;
    let search = parseQueryString(decodeURIComponent(location.search));
    let wechatKey = undefined;
    let isWechat = isWeChatBrowser();
    if (isWechat) {
      // wechatKey = search.state;
      wechatKey = localStorage.getItem("searchState");
    }
    console.log('wechatKey',wechatKey,'search',search)
    // let loginSearchParams = [];
    // // if (search.redirect) loginSearchParams.push(search.redirect);
    // if (search.state) loginSearchParams.push(search.state);
    if (loginType) {
      console.log('来了key登陆')
      quickLogin({
        account: account,
        code: verify,
        state: wechatKey,
      }).then(res => {
        if (res.code === 0) {
          history.push(search.redirect ? search.redirect : "/");
        } else if (res.code === 5040) {
          history.push("/complete" + (isWechat ? "?state=" + wechatKey : ""));
        } else if (res.code !== 0) {
          Toast.info(res.transformError, 1);
        }
      });
    } else {
      generalLogin({
        account: account,
        password: password,
        state: wechatKey,
      }).then(res => {
        if (res.code === 0) {
          history.push(search.redirect ? search.redirect : "/");
        } else if (res.code === 5040) {
          history.push("/complete" + (isWechat ? "?state=" + wechatKey : ""));
        } else if (res.code !== 0) {
          Toast.info(res.transformError, 1);
        }
      });
    }
  };

  onFieldChange = (field, val) => {
    let stateObj = {};
    stateObj[field] = val;
    this.setState({ ...stateObj });
  };

  getVerifyCode = () => {
    const { sendQuickMsg } = this.props;
    const { loginType, account, verify, password } = this.state;

    if (!/^1[0-9]{10}$/.test(account)) {
      Toast.info("手机号码不正确或为空", 1);
      return Promise.reject();
    }

    return sendQuickMsg({ phone: account });
  };

  render() {
    const { loginType, account, verify, password } = this.state;
    const { history, sendQuickMsg } = this.props;

    let isDisabled = false;
    if (loginType) {
      isDisabled = !(account && verify);
    } else {
      isDisabled = !(account && password);
    }

    return (
      <div className="login-container">
        <TopNavBar className="" title="登录" />
        <div className="login-core-wrapper">
          <Flex className="logo-area" justify="between" align="end">
            <ImgContainer
              className="logo"
              src={require("assets/imgs/logo.png")}
              alt=""
            />
            <span className="login-type" onClick={this.changeLoginType}>
              {loginType ? "密码登录" : "快捷登录"}
            </span>
          </Flex>
          <CustomInput
            className="login-user"
            icon="iconmobile"
            placeholder="请输入手机号"
            maxLength={11}
            value={account}
            onChange={val => this.onFieldChange("account", val)}
          />
          {loginType ? (
            <CustomInput
              className="login-verifycode"
              icon="iconsafetycertificate"
              needVerifyCode={this.getVerifyCode}
              placeholder="请输入验证码"
              maxLength={6}
              value={verify}
              onChange={val => this.onFieldChange("verify", val)}
            />
          ) : (
            <CustomInput
              type="password"
              className="login-password"
              icon="iconlock"
              placeholder="请输入密码"
              value={password}
              onChange={val => this.onFieldChange("password", val)}
            />
          )}
          <Button
            type="primary"
            className="login-btn"
            onClick={this.loginClick}
            disabled={isDisabled}
          >
            登录
          </Button>
          <Flex className="operate-area" justify="between" align="end">
            <span
              className="register"
              onClick={() => history.push("/register")}
            >
              立即注册
            </span>
            <span
              className="find-password"
              onClick={() => history.push("/reset")}
            >
              忘记密码？
            </span>
          </Flex>
          {/* <div className="third-login-area">
            <Flex className="divider" justify="between" align="center">
              <div className="divider-line" />
              <div className="divider-title">其他方式登录</div>
              <div className="divider-line" />
            </Flex>
            <Flex className="third-login" justify="between" align="center">
              <CustomIcon type="iconwechat-fill" className="wechat" />
              <CustomIcon type="iconQQ" className="qq" />
              <CustomIcon type="iconweibo" className="weibo" />
            </Flex>
          </div> */}
        </div>
      </div>
    );
  }
}

export default LoginPage;
