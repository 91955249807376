

import React, { PureComponent } from "react";
import { Flex, NavBar } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import "components/modal/modal.scss";
import "./collapse-menu.scss";
const root = process.env.BROWSER ? require("../../client") : null;
export default class CollapseMenu extends PureComponent {

  state= {
    openType: "expand",
    modalVisible: false,
  }

  menuCollapseClick = () => {
    this.setState({
      openType: "collapse"
    })
  }
  menuExpandClick = () => {
    const {status} = this.props;
    if (status === "singleCode") {
      this.setState({
        modalVisible: true
      })
    } else {
      this.setState({
        openType: "expand"
      })
    }
    
  }
  guideClick = () =>  {
    this.setState({
      modalVisible: true
    })
  }

  homeClick = () =>  {
    root.history.push("/");
  }

  onCancel = () => {
    this.setState({
      modalVisible: false
    })
  }

  render() {
    const {openType, modalVisible} = this.state;
    const {status, className} = this.props;
    return (
      <React.Fragment>
        <div className="collapse-menu-wrapper" >
          {
            openType === 'expand' && status !== "singleCode" ?
              <div className="menu-expand-list" >
                <div className="menu-item menu-sel" onClick={this.homeClick}>
                  <i className="icon-menu-home" />
                  <div className="text">首页</div>
                </div>
                <div className="menu-item menu-sel" onClick={this.guideClick}>
                  <i className="icon-menu-followCode" />
                  <div className="text">关注</div>
                </div>
                <div className="menu-item" onClick={this.menuCollapseClick}>
                  <i className="icon-menu-down" />
                </div>
              </div>
              :
              <div className="menu-collapse-list">
                <div className="menu-item" onClick={this.menuExpandClick}>
                  <i className={`${status === "singleCode" ? "icon-menu-followCode" : "icon-menu-expand"}`} />
                </div>
              </div>
          }
        </div>
        <div className={`custom-confirm-modal collapse-menu-modal`}>
          {modalVisible ? (
            <React.Fragment>
              <div className="custom-modal-mask" onClick={this.onCancel}/>
              <Flex className="custom-modal-wrap" justify="center" onClick={this.onCancel}>
                <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
                  <div className="modal-header">
                    <img src={require("assets/imgs/collapse-menu-logo.png")} alt="" />
                  </div>
                  <div className="modal-content">
                    <div className="title">
                      回复“入群”加入全国人事财务交流群
                    </div>
                    <img src={require("assets/imgs/collapse-menu-code.png")} alt="" className="code"/>
                  </div>
                  {/* <div className="modal-operate" onClick={this.onCancel}>
                    <img src={require("assets/imgs/home/ic_close.png")} alt=""/>
                  </div> */}
                </div>
              </Flex>
            </React.Fragment>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}
