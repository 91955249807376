import React, { PureComponent } from "react";
import { InputItem, Flex, Icon } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import "./custom-input.scss";

/**
 * needVerifyCode 传验证码调用，不传则不会出现验证码
 */
export default class CustomInput extends PureComponent {
  state = {
    isSend: false,
    countSeconds: 60,
  }

  sendVerify = () => {
    const {needVerifyCode} = this.props;
    if (this.timer) return;
    if (!needVerifyCode) return;
    needVerifyCode().then(res => {
      if (res.code === 0) {
        this.setState({ isSend: true });
        this.timer = setInterval(() => {
          this.setState(state => {
            return { countSeconds: state.countSeconds - 1 };
          });
          const { countSeconds } = this.state;
          if (countSeconds === 0) {
            clearInterval(this.timer);
            this.timer = null;
            this.setState({ countSeconds: 60, isSend: false });
          }
        }, 1000);
      }
    })
  }
  render() {
    const { icon, child, className = "", needVerifyCode, extra, ...props} = this.props;
    const { isSend, countSeconds } = this.state;
    return (
      <InputItem {...props} 
        className={`${className} custom-input`}
        extra={needVerifyCode ? (isSend ? <span className="send-extra-count">倒计时{countSeconds}秒</span> : <span className="send-extra">获取验证码</span>) : extra}
        onExtraClick={this.sendVerify} >
        {child ? child : icon && <CustomIcon type={icon}/> }
      </InputItem>
    )
  }
}