import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./talent.scss"; //BaseInfo继承了这个scss样式不要删除
import "./interview.scss";
import {
  Button,
  Flex,
  Menu,
  Accordion,
  Icon,
  InputItem,
  DatePicker,
  Checkbox,
  List,
  ListView,
  Toast
} from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import talentModel from "store/reducers/talentModel";
import { BaseInfo } from "./resume-detail";
import BottomPanel from "./BottomPanel";
import CustomIcon from "components/custom-icon/custom-icon";
import moment from "moment";
import * as talentService from "services/talentService";
import { SSL_OP_EPHEMERAL_RSA } from "constants";

const CheckboxItem = Checkbox.CheckboxItem;
const Item = List.Item;
const nowTimeStamp = Date.now();
const now = `${moment().get("year")}/${moment().get("month") +
  1}/${moment().get("date") + 1} 08:00`;

let formatSet = "YYYY/MM/DD  HH:mm";
function MyBody(props) {
  return <div>{props.children}</div>;
}
@connect(
  ({ talentModel }) => {
    return { ...talentModel };
  },
  {
    ...talentModel.actions
  }
)
export default class Interview extends Component {
  constructor(props) {
    super(props);
    const getRowData = (dataBlob, sectionID, rowID) => {
      return dataBlob[sectionID][rowID];
    };
    const dataSource = new ListView.DataSource({
      getRowData,
      rowHasChanged: (row1, row2) => {
        return true;
      }
    });
    this.state = {
      date: now,
      dataSource,
      isLoading: false
    };
  }
  componentDidMount() {
    const {
      getInterviewList,
      getTalentDetail,
      interviewListParams
    } = this.props;
    const { params = {} } = this.props.match;
    params.jd_id && this.checkChange({ id: Number(params.jd_id) }, "init"); //在创建职位后返回默认选中
    getTalentDetail({profile_id: params.id}); //头信息请求
    getInterviewList(interviewListParams); //获取职位列表
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.interviewListInfo.list !== nextProps.interviewListInfo.list
    ) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(
          nextProps.interviewListInfo.list
        ),
        isLoading: true
      });
    }
    if (
      (nextProps.interviewListInfo.list || []).length === 0 ||
      !(nextProps.interviewListInfo.page || {}).is_more
    ) {
      this.setState({ isLoading: false });
    }
  }
  // convertTimte = time => {
  //   let index = time.lastIndexOf(":");
  //   let cut = time.substring(index + 1, time.length);
  //   if (cut == "00" || cut == "30") {
  //     time = time.replace(cut, cut);
  //   } else {
  //     time = time.replace(cut, "00");
  //   }
  //   return time;
  // };
  /**
   * @description 选择时间
   */
  onChangeVal = (name, val) => {
    const { setUpdateState, interviewParam } = this.props;
    let appointment_time = undefined;
    if (name === "appointment_time") {
      appointment_time = moment(val).format(formatSet);
    }
    setUpdateState({
      filed: "interviewParam",
      value: { ...interviewParam, [name]: val, appointment_time }
    });
  };
  /**
   * @description 选择职位
   * @param {object} data
   * @param {object} init //初始是设置回显选中
   */
  checkChange = (data, init) => {
    const {
      setUpdateState,
      interviewParam,
      interviewListInfo = {}
    } = this.props;
    let jd_id;
    if (interviewParam.jd_id == data.id && !init) {
      jd_id = "";
    } else {
      jd_id = data.id;
    }
    setUpdateState({
      filed: "interviewParam",
      value: { ...interviewParam, jd_id }
    });
    if (!init) {
      this.setState({
        dataSource: this.state.dataSource.cloneWithRows(interviewListInfo.list)
      });
    }
  };
  /**
   * @description 创建职位
   */
  createPosition = val => {
    const { params = {} } = this.props.match;
    this.props.history.push("/createposition/" + params.id);
  };
  /**
   * @description 提交面试
   */
  submit = val => {
    const { resumeInfo = {}, interviewParam = {} } = this.props;
    if (!interviewParam.appointment_time && !interviewParam.jd_id) return;
    let dataParam = {
      ...interviewParam,
      profile_id: resumeInfo.id,
      appointment_time: moment(interviewParam.appointment_time).format(
        "YYYY-MM-DD HH:mm"
      )
    };
    talentService.appointmentTalent(dataParam).then(res => {
      if (res.code === 0) {
        this.props.history.push("/position");
      } else {
        Toast.info(res.transformError);
      }
    });
  };
  onEndReached = event => {
    const {
      setUpdateState,
      getInterviewList,
      interviewListParams,
      interviewListInfo = {}
    } = this.props;
    const { page } = this.props.interviewListParams;
    if (
      (interviewListInfo.page || {}).page >=
      (interviewListInfo.page || {}).countpage
    )
      return;
    const params = {
      ...interviewListParams,
      page: page + 1
    };
    setUpdateState({
      filed: "interviewListParams",
      value: params
    });
    getInterviewList(params, "nextPage");
  };

  componentWillUnmount() {
    this.props.setUpdateState({
      filed: "interviewParam",
      value: {}
    });
    this.props.setUpdateState({
      filed: "interviewListInfo",
      value: {}
    });
    this.props.setUpdateState({
      filed: "interviewListParams",
      value: {
        status: 99, //可预约
        page: 1,
        pages: 10
      }
    });
  }
  render() {
    const { resumeInfo, interviewParam = {} } = this.props;
    const { dataSource, isLoading, list } = this.state;
    console.log(interviewParam);
    const row = (rowData, sectionID, rowID) => {
      return (
        <Item
          key={rowData.id}
          extra={
            <CheckboxItem
              // value={interviewParam.jd_id}
              key={rowData.id}
              checked={interviewParam.jd_id === rowData.id}
              onChange={() => this.checkChange(rowData)}
            />
          }
        >
          <div className="row clear">
            <div className="left-icon">
              <img src={require("assets/imgs/position-company-left.jpg")} />
            </div>
            <div className="position-info">
              <p>{rowData.post_name}</p>
              <p>
                {rowData.service_type_name}
                {rowData.service_type_name && rowData.require_num && (
                  <i className="line" />
                )}
                {rowData.require_num && `招聘${rowData.require_num}人`}
              </p>
            </div>
          </div>
        </Item>
      );
    };

    return (
      <div className="interview-container">
        <TopNavBar className="nav" title="预约面试" />
        <BaseInfo resumeInfo={resumeInfo} isNav={false} />
        <div className="interval ellipsis">
          {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map(
            (item, index) => {
              return (
                <span key={index}>
                  <span className="rhombus-second" />
                  <span className="rhombus-first" />
                </span>
              );
            }
          )}
        </div>
        <div className="time">
          <List>
            <DatePicker
              value={
                interviewParam.appointment_time
                  ? new Date(interviewParam.appointment_time)
                  : null
              }
              onChange={date => {
                this.onChangeVal("appointment_time", date);
              }}
            >
              <List.Item arrow="horizontal">选择预约面试时间</List.Item>
            </DatePicker>
          </List>
        </div>
        <div className="relevance">
          <span className="left-line" />
          <span className="name">关联职位</span>
          <span className="des">可将候选人关联到您目前进行中的职位</span>
        </div>
        <div className="position-list">
          <ListView
            dataSource={dataSource}
            renderBodyComponent={() => <MyBody />}
            renderRow={row}
            useBodyScroll
            renderFooter={() => (
              <Flex align="center" justify="center">
                {isLoading ? "加载中..." : ""}
              </Flex>
            )}
            scrollRenderAheadDistance={500}
            onEndReached={this.onEndReached}
            onEndReachedThreshold={10}
            scrollEventThrottle={20}
          />
          {list && list.length === 0 && (
            <Flex className="no-data-view" align="center" justify="center">
              <div>暂无数据...</div>
            </Flex>
          )}
        </div>
        <div className="create-box">
          <div className="btn" onClick={this.createPosition}>
            <CustomIcon className="iconchuangjian" type="iconchuangjian" />
            创建职位
          </div>
        </div>
        <BottomPanel
          onClick={this.submit}
          style={
            interviewParam.appointment_time && interviewParam.jd_id
              ? null
              : { background: "#ececec" }
          }
        >
          确定
        </BottomPanel>
      </div>
    );
  }
}
