import React, { PureComponent } from "react";
import { Button, Flex } from "@geetemp/gee-ui-mobile";
import "./modal.scss";

/**
 * needVerifyCode 传验证码调用，不传则不会出现验证码
 */
export default class CustomConfirmModal extends PureComponent {
  state = {
  };

  onCancel = () => {
    const { onCancel} = this.props;
    if (onCancel) {
      onCancel();
    }
  }

  onSure = () => {
    const { onSure} = this.props;
    if (onSure) {
      onSure();
    }
  }

  render() {
    const { visible, className = "", title, content} = this.props;
    return (
      <div className={`${className} custom-confirm-modal`}>
        {visible ? (
          <React.Fragment>
            <div className="custom-modal-mask" onClick={this.onCancel}/>
            <Flex className="custom-modal-wrap" justify="center" onClick={this.onCancel}>
              <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
                <div className="modal-title">{title}</div>
                <div className="modal-content">
                  {content}
                </div>
                <div className="modal-operate">
                  <div className="cancel btn" inline onClick={this.onCancel}>
                    取消 
                  </div>
                  <div className="sure btn" type="primary" inline onClick={this.onSure}>
                    确定 
                  </div>
                </div>
              </div>
            </Flex>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
