import React, { Component } from "react";
import { connect } from "react-redux";
import "./introduce.scss";
import { StickyContainer } from "react-sticky";
import HomeTopHeader from "pages/home/home-top-header";
import CollapseMenu from "components/collapse-menu/collapse-menu";
// 第一屏
class Detail extends React.PureComponent {
  render() {

    const { user = {}, userLogin, isLogin } = this.props;
    return (
      <React.Fragment>
        <div className="first-page">
          <div className="first-page-inner">
            <div className="title">
              高效的<span>白领人才</span>招聘小程序
            </div>
            <div className="slot-li">免费发布职位</div>
            <div className="slot-li">平台定向推广</div>
            <div className="slot-li">提升招聘效率</div>
            <img className="inner-1" src={require("assets/imgs/introduce/weixin-inner1.png")} alt="" />
          </div>
        </div>
        <SecondPage />
        <ThirdPage />
        <FourthPage/>
        <FifthPage />
      </React.Fragment>
    );
  }
}
// 第二屏
class SecondPage extends React.PureComponent {
  render() {

    return (
      <div className="second-page">
        <div className="page-inner">
          <img className="qrcode" src={require("assets/imgs/introduce/weixin-qrcode.png")} alt="" />
          <div className="main-text">微信扫一扫 使用小程序</div>
          <div className="title-text">即派优职 | 白领求职招聘找工作</div>
        </div>
      </div>
    );
  }
}
// 第三屏
class ThirdPage extends React.PureComponent {
  render() {
    return (
      <div className="third-page">
        <div className="page-inner">
          <div className="func func-1">
            <img className="func-img" src={require("assets/imgs/introduce/func-1.png")} alt="" />
            <div className="text">急招/热门/名企职位</div>
          </div>
          <div className="func func-2">
            <img className="func-img" src={require("assets/imgs/introduce/func-2.png")} alt="" />
            <div className="text">优先置顶功能</div>
          </div>
          <div className="func func-3">
            <img className="func-img" src={require("assets/imgs/introduce/func-3.png")} alt="" />
            <div className="text">职位管理</div>
          </div>
          <div className="func func-4">
            <img className="func-img" src={require("assets/imgs/introduce/func-4.png")} alt="" />
            <div className="text">覆盖范围广</div>
          </div>
          <div className="func func-5">
            <img className="func-img" src={require("assets/imgs/introduce/func-5.png")} alt="" />
            <div className="text">招聘速度快</div>
          </div>
          <div className="func func-6">
            <img className="func-img" src={require("assets/imgs/introduce/func-6.png")} alt="" />
            <div className="text">人才质量高</div>
          </div>
        </div>
      </div>
    );
  }
}
// 第四屏
class FourthPage extends React.PureComponent {
  render() {

    return (
      <div className="fourth-page">
        <img src={require("assets/imgs/introduce/bg2-text.png")} alt="" />
      </div>
    );
  }
}
// 第六屏
class FifthPage extends React.PureComponent {
  render() {
    return (
      <div className="fifth-page">
        <img className="gee" src={require("assets/imgs/introduce/gee-text.png")} alt="" />
        <img className="company" src={require("assets/imgs/introduce/company-text.png")} alt="" />
      </div>
    );
  }
}

@connect(({ user }) => {
  return { user };
})
class Introduce extends Component {

  render() {
    const { history, user } = this.props;
    return (
      <StickyContainer className="introduce-page weixin-page">
        <HomeTopHeader history={history} user={user} nosticky />
        <Detail/>
        <CollapseMenu status="singleCode"/>
      </StickyContainer>
    );
  }
}

export default Introduce;
