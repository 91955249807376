import React from "react";
import { connect } from "react-redux";
import { Route, Link, Redirect } from "react-router-dom";
import { Icon } from "@geetemp/gee-ui-mobile";
import infointroductionModal from "store/reducers/infointroductionModel";
import "./infointroduction.scss";
import * as infoSevice from "services/infointroductionService";
import TopNavBar from "components/top-navbar/navbar";
import { DynamicList } from "./infointroduction";
// import userModel from "store/reducers/userModel";
@connect(
  ({ infointroductionModal, user }) => {
    return { ...infointroductionModal, user };
  },
  {
    ...infointroductionModal.actions
    // userLogin: userModel.actions.toggleLoginAndRegist
  }
)
export default class DynamicDetail extends React.PureComponent {
  static namespace = "infointroductionModal";
  /**
   * @description 获取动态详情和获取推荐列表
   */
  // static async getInitialProps({ pathname, query = {}, req, res }) {
  //   const result = await infoSevice.dynamicDetail({ id: query.id });
  //   const resultRecommend = await infoSevice.recommendList({
  //     article_id: query.id
  //   });
  //   if (result.code === 0 || resultRecommend.code === 0)
  //     console.log("ww", result);
  //   return { dataDetail: result.data, dataRecommend: resultRecommend.data };
  // }

  /**
   * @description 获取推荐列表
   */
  // static async getInitialProps() {
  // const resultRecommend = await infoSevice.recommendList();
  // if (resultRecommend.code === 0) return { dataRecommend: resultRecommend.data };
  // }

  componentDidMount() {
    const { match, getDynamicDetail, getRecommend } = this.props;
    const { params } = match;
    this.getList(params.id);
  }
  getList = id => {
    const { match, getDynamicDetail, getRecommend } = this.props;
    getDynamicDetail({ id: id });
    getRecommend({ article_id: id });
  };
  go = id => {
    this.props.history.push("/dynamicdetail/" + id);
    this.getList(id);
  };
  render() {
    const { dataDetail = {}, dataRecommend = [] } = this.props;
    return (
      <div className="dynamic-detail">
        <TopNavBar className="nav" title="动态详情" />
        <div className="detail-box clear">
          <div className="info">
            <div className="head-info">
              <div className="title">
                <span className="name">
                  [<span className="type">{dataDetail.type_name}</span>]
                </span>
                <span> {dataDetail.title}</span>
              </div>
              <div className="time clear">
                <div className="left">
                  <span>{dataDetail.date}</span>
                  {dataDetail.source_name}
                </div>
              </div>
            </div>
            <div
              className="show-all-info"
              dangerouslySetInnerHTML={{ __html: dataDetail.content }}
            />
          </div>
          <div className="recommend">
            {!!dataRecommend.length && (
              <div className="title">
                <span className="line" />
                其他推荐
              </div>
            )}
            {dataRecommend &&
              dataRecommend.map((item, index) => (
                <div onClick={this.go.bind(this, item.id)} key={index}>
                  <DynamicList key={index} item={item} />
                </div>
              ))}
          </div>
        </div>
        <div className="company-name">
          ©2014-2016科锐数字科技（苏州）有限公司 <br />
          苏ICP备14059286号-2
        </div>
      </div>
    );
  }
}
