import React, { Component } from "react";
import { connect } from "react-redux";
import "./introduce.scss";
import { StickyContainer, Sticky } from "react-sticky";
import { Button, Flex, NoticeBar } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import HomeTopHeader from "pages/home/home-top-header";
import { Link } from "react-router-dom";
import BottomFiexdPanel from "components/bottom-fied-panel/bottom-fied-panel";
import ImgContainer from "components/img";

@connect(
  ({ user }) => {
    return { user };
  }
)
class Introduce extends Component {
  static async getInitialProps({ req, res, match, history, location, ...ctx }) {
    return { whatever: "stuff" };
  }

  render() {
    const { history, user } = this.props;
    return (
      <StickyContainer className="introduce-page">
        <HomeTopHeader history={history} user={user} nosticky/>
        <div className="top-banner">
          <div className="h-title">高效的青年白领灵活用工共享平台</div>
          <div className="h-info">
            大数据和智能推荐技术，搭建泛灵活用工，供需调度，SNS求职的三维平台，提供人才招聘，岗位外包，离岸招聘等一站式用工服务，为企业赋能。
          </div>
        </div>
        <div className="introduce-list">
          <Flex className="introduce-item" align="center" onClick={() => history.push("/introduce-job")}>
            <ImgContainer src={require("assets/imgs/introduce/jobs.png")} alt="" className="introduce-img"/>
            <div className="jobs">
              <div className="title">岗位外包</div>
              <div className="">非核心岗位人员需求量大，</div>
              <div className="">人选质量难保证，时间紧张等</div>
            </div>
            <CustomIcon type="iconright" className="right-icon"/>
          </Flex>
          <Flex className="introduce-item" align="center" onClick={() => history.push("/introduce-offshore")}>
            <ImgContainer src={require("assets/imgs/introduce/offshore.png")} alt="" className="introduce-img offshore"/>
            <div className="jobs">
              <div className="title">离岸招聘</div>
              <div className="">孕产期员工替补，</div>
              <div className="">项目式，淡旺季人员短缺</div>
            </div>
            <CustomIcon type="iconright" className="right-icon"/>
          </Flex>
          <Flex className="introduce-item" align="center" onClick={() => history.push("/introduce-quality")}>
            <ImgContainer src={require("assets/imgs/introduce/quality.png")} alt="" className="introduce-img quality"/>
            <div className="jobs">
              <div className="title">人才招聘</div>
              <div className="">人才访寻推荐，电话面试，</div>
              <div className="">人才测评和背景调查等服务</div>
            </div>
            <CustomIcon type="iconright" className="right-icon"/>
          </Flex>
        </div>
        <BottomFiexdPanel history={this.props.history} />
      </StickyContainer>
    );
  }
}

export default Introduce;
