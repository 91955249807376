import React, { PureComponent } from "react";
import { Button, Flex } from "@geetemp/gee-ui-mobile";
import "components/modal/modal.scss";

/**
 * needVerifyCode 传验证码调用，不传则不会出现验证码
 */
export default class ActivityModal extends PureComponent {
  state = {
  };

  onCancel = () => {
    const { onCancel} = this.props;
    if (onCancel) {
      onCancel();
    }
  }

  onSure = () => {
    const { onSure} = this.props;
    if (onSure) {
      onSure();
    }
  }

  pubClick = () => {
    const {history} = this.props;
    history.push("/weixin");
  }

  servClick = () => {
    const {history} = this.props;
    history.push("/sendserve");
  }

  render() {
    const { visible, className = "", children, content} = this.props;
    return (
      <div className={`${className} custom-confirm-modal activity-modal`}>
        {visible ? (
          <React.Fragment>
            <div className="custom-modal-mask" onClick={this.onCancel}/>
            <Flex className="custom-modal-wrap" justify="center" onClick={this.onCancel}>
              <div className="custom-modal" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                  <img src={require("assets/imgs/home/activity-header.png")} alt="" className="header-img" />
                </div>
                <div className="modal-content">
                  <div className="pub-btn" onClick={this.pubClick}>
                  小程序发布职位<img src={require("assets/imgs/home/free-tag.png")} alt="" className="tag"/>
                  </div>
                  <div className="serv-btn" onClick={this.servClick}>
                    即派灵活用工服务<img src={require("assets/imgs/home/re-tag.png")} alt="" className="tag"/>
                  </div>
                </div>
                <div className="modal-operate" onClick={this.onCancel}>
                  <img src={require("assets/imgs/home/ic_close.png")} alt=""/>
                </div>
              </div>
            </Flex>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
