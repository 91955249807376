import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import underscore from "underscore";
import { Button, Flex, Checkbox, Picker, Toast } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import TopNavBar from "components/top-navbar/navbar";
import "./complete-info.scss";
import userModel from "store/reducers/userModel";
import { commonData } from "services/commonService";
import { parseQueryString } from "utils/url";
import { isWeChatBrowser } from "utils/util";

@connect(
  ({ profile, user }) => {
    return { ...profile, user };
  },
  {
    ...userModel.actions,
  }
)
class CompleteInfoPage extends Component {
  state = {
    userChannel: [],
    channelVal: "",
    agreement: false,
    completeParam: {
      company_name: "",
      name: "",
      email: "",
      position_name: "",
    }
  }

  componentDidMount() {
    const {bindedLogin} = this.props;
    // bindedLogin();
    commonData(["user_channel"]).then(res => {
      this.setState({
        userChannel: res.user_channel
      })
    });
    let complete = sessionStorage.getItem("complete") ? JSON.parse(sessionStorage.getItem("complete")) : null;
    if (complete) {
      this.setState({
        completeParam: complete
      })
    }
  }

  channelChange = (val) => {
    const {userChannel} = this.state;
    userChannel.forEach(item => {
      if (item.value === val[0]) {
        this.onFieldChange("channelLabel", item.label);
        this.onFieldChange("channelVal", val);
        this.onFieldChange("channelVal", val, "completeParam");
        this.onFieldChange("channelLabel", item.label, "completeParam");
      }
    })
  }

  onFieldChange = (field, val, obj) => {
    const {completeParam} = this.state;
    let stateObj = {};
    if (obj) {
      this.state[obj][field] = val;
    } else {
      stateObj[field] = val;
    }  
    this.setState({...this.state, ...stateObj});
    sessionStorage.setItem("complete", JSON.stringify(completeParam))
  }

  completeClick = () => {
    const {completeParam, channelVal} = this.state;
    const {completeUserInfo, history, location} = this.props;
    const emailReg = /^[a-zA-Z0-9!#$%&\'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&\'*+\/=?^_`{|}~-]+)*@(?:[-_a-zA-Z0-9][-_a-zA-Z0-9]*\.)*(?:[a-zA-Z0-9][-a-zA-Z0-9]{0,62})\.(?:(?:[a-zA-Z]{2}\.)?[a-zA-Z]{2,})$/;
    if (underscore.isEmpty(completeParam.company_name)) {
      Toast.info("企业名称不能为空", 1);
      return;
    }
    if ( underscore.isEmpty(completeParam.name)) {
      Toast.info("姓名不能为空", 1);
      return;
    }
    if ( underscore.isEmpty(completeParam.email)) {
      Toast.info("企业邮箱不能为空", 1);
      return;
    }
    if (underscore.isEmpty(completeParam.position_name)) {
      Toast.info("职位不能为空", 1);
      return;
    }
    if (!completeParam.channelVal) {
      Toast.info("渠道不能为空", 1);
      return;
    }
    if (!emailReg.test(completeParam.email)) {
      Toast.info("企业邮箱格式不正确", 1);
      return;
    }
    let search = parseQueryString(decodeURIComponent(location.search));
    let wechatKey = undefined;
    if (isWeChatBrowser()) {
      wechatKey = search.state;
    }
    completeUserInfo({
      ...completeParam,
      channel: completeParam.channelVal[0],
      state: wechatKey
    }).then(res => {
      if (res.code === 0) {
        history.push("/");
        sessionStorage.removeItem("complete");
      } else {
        Toast.info(res.transformError, 1);
      }
    })
  }

  agreementClick = (e) => { 
    const {history} = this.props;
    const {completeParam} = this.state;
    e.preventDefault();
    sessionStorage.setItem("complete", JSON.stringify(completeParam))
    history.push("/agreement");
  }
  render() {
    const { history } = this.props;
    const { userChannel, channelVal, channelLabel, agreement, completeParam } = this.state;
    return (
      <div className="complete-container">
        <TopNavBar className="" title="完善信息" />
        <div className="complete-core-wrapper">
          <div className="complete-status">
            <div className="ok-icon"><CustomIcon type="iconchenggong1"/></div>
            <div className="congratulation">恭喜您，注册成为即派用户！</div>
            <div className="completetip">完善信息，享受即派更多服务</div>
          </div>
          <CustomInput 
            value={completeParam.company_name}
            onChange={(val) => this.onFieldChange("company_name", val, "completeParam")}
            className="complete-company" icon="iconqiyezhuangtai" placeholder="请输入企业名称"></CustomInput>
          <CustomInput 
            value={completeParam.name}
            onChange={(val) => this.onFieldChange("name", val, "completeParam")}
            className="complete-name" icon="iconuser" placeholder="请输入您的姓名"></CustomInput>
          <CustomInput 
            value={completeParam.email}
            onChange={(val) => this.onFieldChange("email", val, "completeParam")}
            className="complete-email" icon="iconmail" placeholder="请输入您的企业邮箱"></CustomInput>
          <CustomInput 
            value={completeParam.position_name}
            onChange={(val) => this.onFieldChange("position_name", val, "completeParam")}
            className="complete-position" icon="iconidcard" placeholder="您的职位"></CustomInput>
          <Picker 
            data={userChannel} cols={1}
            onChange={this.channelChange} format={(labels) => <CustomIcon type="icondown"></CustomIcon>}
            value={completeParam.channelVal}
            extra={<CustomIcon type="icondown"></CustomIcon>}>
            <CustomInput editable={false} className="complete-position"
              value={completeParam.channelLabel}
              icon="iconappstore" placeholder="您是通过何种渠道了解到即派？"></CustomInput>
          </Picker>
          <Checkbox checked={completeParam.agreement}
            className="agreement-checkbox" onChange={e => this.onFieldChange("agreement", e.target.checked, "completeParam")}>
            <span className="read">我已阅读并同意</span><a className="agreement" onClick={this.agreementClick}>《即派用户使用协议》</a>
          </Checkbox>
          <Button type="primary"
            onClick={this.completeClick}
            disabled={!completeParam.agreement} className="complete-btn">完成</Button>
        </div>
      </div>
    );
  }
}

export default CompleteInfoPage;
