import Model from "../model";
import * as infoSevice from "services/infointroductionService";
export default Model.getInstance(
  class extends Model {
    namespace = "infointroductionModal";
    state = {
      statusParam: {
        page: 1,
        pages: 10
      },
      dataList: {}, //动态列表
      dataDetail: {}, //动态详情
      dataRecommend: [] //推荐列表
    };

    actions = {
      async getDynamicList(params, nextPage) {
        if (!nextPage) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "dataList", value: {list:[]} }
          });
        }
        let res = await infoSevice.dynamicList(params);
        if (res.code === 0) {
          this.dispatch({
            type: `${!nextPage ? "setUpdateState" : "updateList"}`,
            payload: { filed: "dataList", value: res.data }
          });
        }
        return res;
      },
      async getDynamicDetail(param) {
        let result = await infoSevice.dynamicDetail(param);
        if (result.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "dataDetail", value: { ...result.data } }
          });
        }
        return result;
      },
      async getRecommend(param) {
        let res = await infoSevice.recommendList(param);
        if (res.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "dataRecommend", value: res.data }
          });
        }
        return res;
      }
    };

    reducers = {
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      updateList(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = {
          ...value,
          list: state[filed].list.concat(value.list),
          page: value.page
        };
        return { ...state };
      }
    };
  }
);
