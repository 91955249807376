import xhr from "./index";

// 职位列表
export function getPositions(param) {
  return xhr({ url: "/wap/v2_0/jd/list", body: param, method: "GET" });
}

// 职位详情
export function getPositionDetail(param) {
  return xhr({ url: "/wap/v2_0/jd/info", body: param, method: "GET" });
}
// 创建职位或派服务
export function positionAdd(param) {
  return xhr({ url: "/wap/v2_0/jd/submit", body: param, method: "POST" });
}

// 职位进展
export function getPositionProgress(param) {
  return xhr({ url: "/wap/v2_0/jd/logs", body: param, method: "GET" });
}

// 职位进展
export function getPositionProfiles(param) {
  return xhr({ url: "/wap/v2_0/jd/profiles", body: param, method: "GET" });
}

// 职位进展
export function pauseJD(param) {
  return xhr({ url: "/wap/v2_0/jd/pause", body: param, method: "POST" });
}

// 职位进展
export function editJD(param) {
  return xhr({ url: "/wap/v2_0/jd/edit", body: param, method: "POST" });
}
