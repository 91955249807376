import xhr from "./index";
// 人选列表
function talentList(param) {
  return xhr({ url: "/wap/v2_0/profile/list", body: param, method: "POST" });
}
// 我的收藏或预约列表
function collectOrOrder(param) {
  return xhr({
    url: "/wap/v2_0/center/myProfile",
    body: param,
    method: "POST"
  });
}
// 社会人选详情和实习生人选详情
function talentDetail(param) {
  return xhr({
    url: `/wap/v2_0/profile/info`,
    body: param,
    method: "GET"
  });
}
// 收藏或取消收藏
function isCollect(param) {
  return xhr({
    url: "/wap/v2_0/center/collection",
    body: param,
    method: "POST"
  });
}
// 职位列表（可预约status：99）
function interviewList(param) {
  return xhr({
    url: "/wap/v2_0/jd/list",
    body: param,
    method: "GET"
  });
}
// 预约人选
function appointmentTalent(param) {
  return xhr({
    url: "/wap/v2_0/jd/interview",
    body: param,
    method: "POST"
  });
}

export { talentList, collectOrOrder, talentDetail, isCollect, interviewList,appointmentTalent };
