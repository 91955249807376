import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./position-card.scss";
import { Button, Flex, Menu } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import TopNavBar from "components/top-navbar/navbar";
import userModel from "store/reducers/userModel";

class PositionCard extends Component {

  state = {
  };

  goTalent = (e) => {
    const { history, positionData } = this.props;
    e.stopPropagation(); 
    history.push("/position-talent/all/" + positionData.id)
  }

  goRecommend = (e) => {
    const { history, positionData } = this.props;
    e.stopPropagation(); 
    history.push("/position-talent/recommend/" + positionData.id)
  }

  goEdit = (e) => {
    const { history, positionData } = this.props;
    e.stopPropagation(); 
    history.push("/position-edit/" + positionData.id + "/list")
  }

  render() {
    const { loginType, menuShow, menuSel, menuLabel } = this.state;
    const { history, positionData } = this.props;
    return (
      <div className="position-card" onClick={()=>history.push("/position-detail/" + positionData.id)}>
        <Flex className="position-card-header" justify="between" >
          <div className="name ellipsis">{positionData.post_name}</div>
          {positionData.status === 0 && <div className="status ready">{positionData.status_name}</div>}
          {(positionData.status === 1 || positionData.status === 2)
            && <div className="status ing">{positionData.status_name}</div>}
          {(positionData.status === 3 || positionData.status === 4 || positionData.status === 5)
            && <div className="status unop">{positionData.status_name}</div>}
        </Flex>
        <Flex className="position-card-content" wrap="wrap">
          <div className="content-item publish-time"><CustomIcon className="info-icon" type="icontime-circle"/>发布时间：{positionData.create_time_str}</div>
          <div className="content-item publish-city">
            <CustomIcon className="info-icon" type="icondizhi"/>
            工作城市：
            {positionData.province_name}{positionData.province_name == positionData.city_name ? "" : positionData.city_name}{positionData.region_name}
          </div>
          <div className="content-item publish-type"><CustomIcon className="info-icon" type="iconappstore"/>服务类型：{positionData.service_type_name}</div>
        </Flex>
        <Flex className="position-card-footer" justify="between">
          <div className="status-num" onClick={this.goRecommend}>
            <div className="num">{positionData.recommended_profiles_num}</div>
            <div className="status">已推荐</div>
          </div>
          <div className="spliter"></div>
          <div className="status-num" onClick={this.goTalent}>
            <div className="num">{positionData.all_profiles_num}</div>
            <div className="status">全部人选</div>
          </div>
          
          <div className="edit-btn" style={{visibility: (positionData.status === 4 || positionData.status === 5) ? "hidden" : ""}} onClick={this.goEdit}>补全职位信息</div>
        </Flex>
      </div>
    );
  }
}

export default PositionCard;
