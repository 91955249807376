import React, { PureComponent } from "react";
import { InputItem, Flex, Icon } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import "./home-menu.scss";

/**
 * needVerifyCode 传验证码调用，不传则不会出现验证码
 */
export default class HomeMenu extends PureComponent {
  state = {
    
  };

  componentDidUpdate(prevProps) {
    const {visible} = this.props
    if (prevProps.visible != visible && visible) {
      window.document.body.style.overflow = "hidden";
    }
    if (prevProps.visible != visible && !visible) {
      window.document.body.style.overflow = "auto";
    }
  }

  componentWillUnmount() {
    window.document.body.style.overflow = "auto";
  }

  // 点击
  currentMenuClick = (item) => {
    const {onClick} = this.props;
    if (onClick) {
      onClick(item);
    }
  }

  renderMenu = menuData => {
    const { showArrow, onClose, value } = this.props;
    return (
      menuData &&
      menuData.map((item, index) => {
        return <MenuItem currentSel={value} key={index} data={item} 
          onClose={onClose}
          showArrow={showArrow} onMenuClick={this.currentMenuClick}/>;
      })
    );
  };

  close = () => {
    const {onClose} = this.props;
    if (onClose) {
      onClose();
    }
  }

  render() {
    const { menuData, visible, className, header } = this.props;
    const { isSend, countSeconds } = this.state;
    return (
      visible ?
      <div className={`home-menu`}>
        <div className="home-mask"/>
        <div className="home-menu-wrapper" onClick={this.close}>
          <div className={`${className ? className : ""} menu`} onClick={(e) => e.stopPropagation()}>
            <div className="menu-header" >
              {header}
            </div>
            <div className="menu-content">{this.renderMenu(menuData)}</div>
          </div>
        </div>
      </div> : null
    );
  }
}

class MenuItem extends PureComponent {
  state = {
    showChild: false, 
  };

  componentDidUpdate(prevProps) {}

  // 目前暂不需要多级菜单，故功能未全部实现，如需添加应在此处处理
  renderChild = menuData => {
    const { showArrow, onClose } = this.props;
    let childRender = [];
    if (menuData.child) {
      childRender.push(
        <div key={1} className="menu-child-wrapper">
          {menuData.child.map((item, index) => {
            return <MenuItem key={index} showArrow={showArrow} data={item} onClose={onClose}/>;
          })}
        </div>
      );
    }
    return childRender;
  };

  menuClick = (e) => {
    const {data, onMenuClick, onClose} = this.props;
    e.stopPropagation();
    if (data.child) {
      this.setState({
        showChild: !this.state.showChild
      })
      return;
    }
    if (data.click) {
      data.click();
    }
    if (onMenuClick) {
      onMenuClick(data);
    }
    if (onClose) {
      onClose();
    }
  }

  render() {
    const { data, showArrow, currentSel = {} } = this.props;
    const {showChild} = this.state;
    return (
      <div className={`menu-item ${currentSel.value === data.value ? "sel" : ""}`} onClick={this.menuClick}>
        <Flex justify={showArrow ? "between" : "center"} align="center" className="menu-item-content">
          <div className="label">{showArrow && <span className="line"></span>}{data.label}</div>
          <div className="arrow">{showArrow ? <CustomIcon type={showChild ? "icontop" : "iconright"} /> : null}</div>
        </Flex>
        {
          showChild && this.renderChild(data)
        }
      </div>
    );
  }
}
