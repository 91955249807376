import Model from "../model";
import dotProp from "dot-prop-immutable";
import * as commonService from "services/commonService";
import * as talentService from "services/talentService";
import { Toast } from "@geetemp/gee-ui-mobile";
export default Model.getInstance(
  class extends Model {
    namespace = "talentModel";
    state = {
      searchStatus: {
        show1: false,
        show2: false,
        show3: false
      },
      searchParams: {
        cityName: "",
        city: [],
        positionName: "职能类别",
        position: [],
        internName: "实习生",
        is_intern: [],
        "per-page": 10,
        page: 1
      },
      topMask: {
        pageMaskStatus: false //页面蒙版
      },
      commonData: {}, //静态选项
      talentListInfo: {}, //人选列表
      collectOrderList: {}, //我的预约或收藏列表信息
      collectOrderParam: {
        page: 1,
        "per-page": 10
      }, //我的预约或收藏列表参数
      resumeInfo: {}, //简历详情,
      interviewListInfo: {}, //可预约的人选列表
      interviewListParams: {
        status: 99, //可预约
        page: 1,
        pages: 10
      },
      interviewParam: {} ,//预约面试参数
    };

    actions = {
      async getCommonData(dataName) {
        let commonData = await commonService.commonData(dataName);
        this.dispatch({
          type: "setInitState",
          payload: { filed: "commonData", value: commonData }
        });
      },
      // 人选列表
      async getTalentList(param, nextPage) {
        let res = await talentService.talentList(param);
        if (res.code === 0) {
          this.dispatch({
            type: `${!nextPage ? "setUpdateState" : "updateList"}`,
            payload: { filed: "talentListInfo", value: res.data }
          });
        }
        return res
      },
      // 我的预约或收藏列表
      async getCollectOrder(param, nextPage) {
        if (!nextPage) {
          this.dispatch({
            type: "setUpdateState",
            payload: {
              filed: "collectOrderList",
              value: { list: [] }
            }
          });
        }
        let res = await talentService.collectOrOrder(param);
        if (res.code === 0) {
          this.dispatch({
            type: `${!nextPage ? "setUpdateState" : "updateList"}`,
            payload: { filed: "collectOrderList", value: res.data, }
          });
        }
        return res;
      },
      // 获取人选详情
      async getTalentDetail(param) {
        this.dispatch({
          type: "setUpdateState",
          payload: { filed: "resumeInfo", value: {} }
        });
        let res = await talentService.talentDetail(param);
        if (res.code === 0) {
          this.dispatch({
            type: "setUpdateState",
            payload: { filed: "resumeInfo", value: res.data }
          });
        } else {
          Toast.info(res.transformError);
        }
        return res;
      },
      // 预约人选列表
      async getInterviewList(param,nextPage) {
        let res = await talentService.interviewList(param);
        if (res.code === 0) {
          this.dispatch({
            type: `${!nextPage ? "setUpdateState" : "updateList"}`,
            payload: { filed: "interviewListInfo", value: res.data }
          });
        } else {
          Toast.info(res.transformError);
        }
      }
    };

    reducers = {
      //改变数据的name（因为Menu需要特定key）
      setInitState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        if (value.index_cities) {
          state[filed] = {
            ...state[filed],
            index_cities: value.index_cities.map(item => {
              return { label: item.name, value: item.id };
            })
          };
        }
        if (value.profile_position) {
          state[filed] = {
            ...state[filed],
            profile_position: value.profile_position.map(item => {
              let children = item.list.map(item2 => {
                return { label: item2.label, value: item2.value };
              });
              return {
                label: item.label,
                value: item.value,
                children: children
              };
            })
          };
        }
        return { ...state };
      },
      setUpdateState(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = value;
        return { ...state };
      },
      updateList(state, { payload }) {
        const { filed, value } = payload;
        state[filed] = {
          ...value,
          list:(state[filed].list||[]).concat(value.list),
          page: value.page
        };
        return { ...state };
      }
    };
  }
);
