import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Button, Flex, Checkbox, Toast } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import TopNavBar from "components/top-navbar/navbar";
import "./reset.scss";
import userModel from "store/reducers/userModel";


@connect(
  ({ profile, user }) => {
    return { ...profile, user };
  },
  {
    ...userModel.actions,
  }
)
class ResetPage extends Component {
  state = {
    resetParam: {}
  }

  getVerifyCode = () => {
    const { sendSmsWithGrap } = this.props;
    const { resetParam } = this.state;

    if (!/^1[0-9]{10}$/.test(resetParam.phone)) {
      Toast.info("手机号码不正确或为空", 1);
      return Promise.reject();
    }

    return sendSmsWithGrap({phone: resetParam.phone});
  }

  onFieldChange = (field, val, obj) => {
    let stateObj = {};
    if (obj) {
      this.state[obj][field] = val;
    } else {
      stateObj[field] = val;
    }  
    this.setState({...this.state, ...stateObj});
  }

  resetClick = () => {
    const {forgetPwdReset, history} = this.props;
    const { resetParam } = this.state;
    if (!/^1[0-9]{10}$/.test(resetParam.phone)) {
      Toast.info("手机号码不正确或为空", 1);
      return;
    }
    if (!resetParam.code) {
      Toast.info("验证码不能为空", 1);
      return;
    }
    if (!resetParam.new_password) {
      Toast.info("密码不能为空", 1);
      return;
    }
    if (!resetParam.confirm_password) {
      Toast.info("确认密码不能为空", 1);
      return;
    }
    if (resetParam.new_password !== resetParam.confirm_password) {
      Toast.info("两次密码不一致，请重新输入", 1);
      return;
    }
    forgetPwdReset(resetParam).then(res => {
      if (res.code === 0) {
        Toast.info("设置成功", 1);
        history.push("/login");
      } else {
        Toast.info(res.transformError)
      }
    })
  }
  
  render() {
    const { agreement } = this.state;
    const { history} = this.props;
    return (
      <div className="register-container">
        <TopNavBar className="" title="忘记密码" />
        <div className="register-core-wrapper">
          <CustomInput maxLength={11}
            onChange={(val) => this.onFieldChange("phone", val, "resetParam")} 
            className="login-user" icon="iconmobile" placeholder="请输入手机号"></CustomInput>
          <CustomInput maxLength={6}
            onChange={(val) => this.onFieldChange("code", val, "resetParam")}
            className="login-verifycode" icon="iconsafetycertificate" 
            needVerifyCode={this.getVerifyCode} placeholder="请输入验证码"></CustomInput>
          <CustomInput 
            onChange={(val) => this.onFieldChange("new_password", val, "resetParam")}
            type="password" className="login-password" icon="iconlock" placeholder="请输入新密码"></CustomInput>
          <CustomInput 
            onChange={(val) => this.onFieldChange("confirm_password", val, "resetParam")}
            type="password" className="login-password" icon="iconlock" placeholder="请再次输入新密码"></CustomInput>
          <Checkbox className="agreement-checkbox" onChange={e => this.onFieldChange("agreement", e.target.checked)}>
            <span className="read">我已阅读并同意</span><a className="agreement" onClick={(e) => { e.preventDefault(); history.push("/agreement"); }}>《即派用户使用协议》</a>
          </Checkbox>
          <Button 
            onClick={this.resetClick} type="primary"
            className="login-btn" disabled={!agreement}>重置密码</Button>
        </div>
      </div>
    );
  }
}

export default ResetPage;
