import React, { Component } from "react";
import { connect } from "react-redux";
import "./center.scss";
import { Toast, Flex, NoticeBar } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import { Link } from "react-router-dom";
import userModel from "store/reducers/userModel";
import commonModel from "store/reducers/commonModel";
import ImgContainer from "components/img";
import * as commonService from "services/commonService";

@connect(
  ({ user, common }) => {
    return {
      ...common,
      user,
      userDetail: user.info,
      user_info: user.centerInfo.user_info,
      company_info: user.centerInfo.company_info,
      jd: user.centerInfo.jd
    };
  },
  {
    ...userModel.actions,
    getSystemInfos: commonModel.actions.getSystemInfos
  }
)
class Center extends Component {
  state = {
    newMsg: 0
  }
  componentDidMount() {
    const { getPersonageInfo, getSystemInfos } = this.props;
    getPersonageInfo();
    getSystemInfos({ page: 1, pages: 1 });
    commonService.getCommonDataWithoutCache(["new_msg"]).then(res => {
      if (res.code === 0) {
        this.setState({
          newMsg: res.data.new_msg
        })
      }
    });
  }

  loginOutClick = () => {
    const { logout, history } = this.props;
    logout().then(res => {
      if (res.code == 0) {
        history.push("/");
      }
    });
  };

  completeCompany = () => {
    const { company_info, history } = this.props;
    if (company_info.status == "3") {
      Toast.info("企业信息审核中", 1);
      return;
    }
    history.push("/mycompany/" + company_info.status);
  };

  leftClick = () => {
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const {
      user_info = {},
      company_info = {},
      jd = {},
      history,
      systemInfos = []
    } = this.props;
    const {newMsg} = this.state;
    let companyStatus = "";
    if (company_info.status == "1") {
      companyStatus = "(未认证)";
    } else if (company_info.status == "2") {
      companyStatus = "(已认证)";
    } else if (company_info.status == "3") {
      companyStatus = "(审核中)";
    } else if (company_info.status == "0") {
      companyStatus = "(认证失败)";
    }

    let userStatus = "";
    if (user_info.is_complete == 0) {
      userStatus = "(待完善)";
    } else if (user_info.is_complete == 1) {
      userStatus = "(已完善)";
    }

    if (user_info.phone) {
      user_info.phone = user_info.phone.slice(0, 3) + "****" + user_info.phone.slice(7)
    }

    return (
      <div className="center-page">
        <div className="center-top">
          <div className="top-header">
            <CustomIcon className="back-btn" type="iconleft" onClick={this.leftClick}/>
            <span>个人中心</span>
          </div>
          <div className="account-info">
            <div className="account-avatar">
              <ImgContainer
                src={user_info.avatar ? user_info.avatar : require("assets/imgs/center-avatar.png")}
                alt=""
                className="avatar"
              />
            </div>
            <div className="account">
              <Flex className="info" align="center">
                <span className="name">{user_info.name}</span>
                <CustomIcon type="iconmobile" />
                <span className="phone">{user_info.phone}</span>
              </Flex>
              <div className="company ellipsis">{company_info.name}</div>
            </div>
          </div>
          <Flex className="accoutn-other" justify="between">
            <Flex
              className="company-info"
              align="center"
              onClick={this.completeCompany}
            >
              <CustomIcon type="iconqiye-white" className="qiye" />
              企业信息{companyStatus}
            </Flex>
            <div className="spliter" />
            <Flex
              className="person-info"
              align="center"
              onClick={() => history.push("/personinfo")}
            >
              <CustomIcon type="iconperson-info" className="info" />
              个人信息{userStatus}
            </Flex>
          </Flex>
        </div>
        {systemInfos.length !== 0 && (
          <NoticeBar className="sysinfo-notice" 
            marqueeProps={{ loop: true}}
            icon={<CustomIcon className="sound-icon" type="iconsound"/>}  
            mode="link" action={<React.Fragment>{newMsg ? <div className="badge-container"><span className="badge">{newMsg}</span></div> : null}<CustomIcon type="iconright"/></React.Fragment>}
            onClick={() => history.push("/systemmessage")}>{`[${systemInfos[0].typeName}]${systemInfos[0].content}`}</NoticeBar>
        )}
        <div className="position-data">
          <Flex className="title" align="baseline">
            <CustomIcon type="iconzhiwei" />
            <span className="text">我的职位</span>
          </Flex>
          <Flex className="data-map" justify="between">
            <Flex className="data-item" wrap="wrap" justify="center" onClick={() => { sessionStorage.setItem("positionType", '0');history.push("/position")}}>
              <div className="pop-num all">{jd.all_num}</div>
              <div className="text">全部</div>
            </Flex>
            <Flex className="data-item" wrap="wrap" justify="center" onClick={() => { sessionStorage.setItem("positionType", '1'); history.push("/position")}}>
              <div className="pop-num ready">{jd.to_be_verified_num}</div>
              <div className="text">待核实</div>
            </Flex>
            <Flex className="data-item" wrap="wrap" justify="center" onClick={() => { sessionStorage.setItem("positionType", '2');history.push("/position")}}>
              <div className="pop-num ing">{jd.processing_num}</div>
              <div className="text">进行中</div>
            </Flex>
            <Flex className="data-item" wrap="wrap" justify="center" onClick={() => { sessionStorage.setItem("positionType", '3');history.push("/position")}}>
              <div className="pop-num stop">{jd.paused_num}</div>
              <div className="text">已暂停</div>
            </Flex>
          </Flex>
        </div>
        <div className="position-link">
          <Flex
            className="link-item"
            justify="between"
            onClick={() => history.push("/mycollectorder/1")}
          >
            <div className="link">
              <CustomIcon type="icontime-circle" />
              我预约的人选
            </div>
            <CustomIcon type="iconright" className="go-link" />
          </Flex>
          <Flex
            className="link-item"
            justify="between"
            onClick={() => history.push("/mycollectorder/2")}
          >
            <div className="link">
              <CustomIcon type="iconsc" />
              我收藏的人选
            </div>
            <CustomIcon type="iconright" className="go-link" />
          </Flex>
          <Flex
            className="link-item"
            justify="between"
            onClick={() => history.push("/feedback")}
          >
            <div className="link">
              <CustomIcon type="iconyijianfankui1" />
              意见反馈
            </div>
            <CustomIcon type="iconright" className="go-link" />
          </Flex>
          <Flex
            className="link-item"
            justify="between"
            onClick={() => history.push("/agreement")}
          >
            <div className="link">
              <CustomIcon type="iconyijianfankui" />
              服务协议
            </div>
            <CustomIcon type="iconright" className="go-link" />
          </Flex>
        </div>
        <div className="login-out" onClick={this.loginOutClick}>
          退出账号
        </div>
      </div>
    );
  }
}

export default Center;
