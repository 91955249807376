import xhr from "./index";

/** 信息介绍 */
//即派动态列表
function dynamicList(param) {
  return xhr({ url: "/wap/v2_0/article/list", body: param, method: "get" });
}
//推荐列表
function recommendList(param) {
  return xhr({
    url: "/wap/v2_0/article/recommend",
    body: param,
    method: "get"
  });
}
//即派动态详情
function dynamicDetail(param) {
  return xhr({ url: "/wap/v2_0/article/info", body: param, method: "get" });
}

export { dynamicList, dynamicDetail, recommendList };
