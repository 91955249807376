import React, { Component } from "react";
import { connect } from "react-redux";
import "./create-position.scss";
import {
  Button,
  Flex,
  List,
  InputItem,
  Picker,
  TextareaItem,
  Toast
} from "@geetemp/gee-ui-mobile";
import TopNavBar from "components/top-navbar/navbar";
import RadioBtn from "components/radio-btn/radio-btn";
import myPositionModel from "store/reducers/myPositionModel";
import BottomPanel from "pages/talentPool/BottomPanel";
import { convertCity } from "utils/util";
import * as positionService from "services/positionService";
import CustomIcon from "components/custom-icon/custom-icon";

const Item = List.Item;

@connect(
  ({ myPositionModel }) => {
    return { ...myPositionModel };
  },
  {
    ...myPositionModel.actions
  }
)
export default class CreatePosition extends Component {
  componentDidMount() {
    this.props.getCommonData(["cities"]);
  }
  /**
   * @description 设置参数数据
   * @param {string} name
   * @param {string} val
   */
  onChangeVal = (name, val) => {
    const { setUpdateState, createPositionParams } = this.props;
    setUpdateState({
      filed: "createPositionParams",
      value: { ...createPositionParams, [name]: val }
    });
  };
  /**
   * @description 是否通过验证
   */
  noFilled = nameObj => {
    const { post_name, require_num, jd_desc, city = [] } = nameObj;
    const [province_id] = city;
    if (!post_name) {
      Toast.info("请输入职位名称", 2);
      return;
    }
    if (!require_num) {
      Toast.info("请输入招聘人数", 2);
      return;
    }
    if (!province_id) {
      Toast.info("工作城市至少选到市", 2);
      return;
    }
    if (!jd_desc) {
      Toast.info("请输入职位描述", 2);
      return;
    }
    return true;
  };
  /**
   * @description 提交创建职位
   */
  submit = val => {
    const { createPositionParams = {} } = this.props;
    if (this.noFilled(createPositionParams)) {
      const { city = [] } = createPositionParams;
      const [province_id, city_id, region_id] = city;
      let dataParams = {
        ...createPositionParams,
        province_id,
        city_id,
        region_id
      };
      delete dataParams.city;

      positionService.positionAdd(dataParams).then(res => {
        if (res.code === 0) {
          Toast.info("保存成功");
          const { params = {} } = this.props.match;
          this.props.history.push(
            "/interview/" + params.profile_id + "/" + (res.data || {}).jd_id
          );
        } else {
          Toast.info(res.transformError);
        }
      });
    }
  };
  componentWillUnmount() {
    this.props.setUpdateState({
      filed: "createPositionParams",
      value: { work_type: 1, service_type: 3 }
    });
  }

  jopOption = [
    { value: 1, label: "全职" },
    { value: 2, label: "兼职" },
    { value: 3, label: "实习" }
  ];
  serveOption = [
    { value: 3, label: "岗位外包" },
    { value: 2, label: "离岸招聘" },
    { value: 1, label: "人才招聘" }
  ];
  render() {
    const { createPositionParams = {}, commonData = {} } = this.props;
    return (
      <div className="create-position-box">
        <TopNavBar title="创建职位" />
        <div className="job-type">
          <RadioBtn
            checked={createPositionParams.work_type}
            paramName="work_type"
            onClick={this.onChangeVal}
            option={this.jopOption}
          />
        </div>
        <div className="form-box">
          <List>
            <InputItem
              value={createPositionParams.post_name}
              onChange={this.onChangeVal.bind(this, "post_name")}
              placeholder="请输入"
            >
              <span className="required">*</span>职位名称
            </InputItem>
            <InputItem
              type="money"
              value={createPositionParams.require_num}
              onChange={this.onChangeVal.bind(this, "require_num")}
              placeholder="请输入"
            >
              <span className="required">*</span>招聘人数
            </InputItem>
            <div className="city-sel">
              <Picker
                value={createPositionParams.city}
                data={convertCity((commonData.cities || {}).cityList)}
                title="工作城市"
                onOk={this.onChangeVal.bind(this, "city")}
                extra={
                  <div className="do-sel">
                    请选择
                    <CustomIcon className="iconright" type="iconright" />
                  </div>
                }
              >
                <List.Item arrow="horizontal">
                  <span className="required">*</span>工作城市
                </List.Item>
              </Picker>
            </div>

            <InputItem
              value={createPositionParams.address}
              onChange={this.onChangeVal.bind(this, "address")}
              placeholder="请输入"
            >
              工作地址
            </InputItem>
            <div className="serve-type">
              <Item
                extra={
                  <RadioBtn
                    checked={createPositionParams.service_type}
                    paramName="service_type"
                    onClick={this.onChangeVal}
                    option={this.serveOption}
                    fieldNames={{ label: "label", value: "value" }}
                  />
                }
              >
                <div className="row">
                  <span className="required">*</span>服务类型
                </div>
              </Item>
            </div>
            <div className="position-des">
              <Item>
                <div className="row">
                  <span className="required">*</span>职位描述
                </div>
              </Item>
            </div>
            <TextareaItem
              className="nobottom"
              value={createPositionParams.jd_desc}
              onChange={this.onChangeVal.bind(this, "jd_desc")}
              placeholder="请详细填写职位描述..."
            />
            <div className="position-des">
              <Item>
                <div className="row">
                  任职要求
                </div>
              </Item>
            </div>
            <TextareaItem
              value={createPositionParams.requirements}
              onChange={this.onChangeVal.bind(this, "requirements")}
              placeholder="请详细填写任职要求..."
            />
          </List>
          <BottomPanel onClick={this.submit}>保存</BottomPanel>
        </div>
      </div>
    );
  }
}
