import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Button, Flex, Checkbox, Toast } from "@geetemp/gee-ui-mobile";
import CustomIcon from "components/custom-icon/custom-icon";
import CustomInput from "components/custom-input/custom-input";
import TopNavBar from "components/top-navbar/navbar";
import "./register.scss";
import userModel from "store/reducers/userModel";
import { parseQueryString } from "utils/url";
import { isWeChatBrowser, getDomain } from "utils/util";

@connect(
  ({ profile, user }) => {
    return { ...profile, user };
  },
  {
    ...userModel.actions,
  }
)
class RegisterPage extends Component {
  state = {
    registerParam: {}
  }

  getVerifyCode = () => {
    const { sendMsg } = this.props;
    const { registerParam } = this.state;

    if (!/^1[0-9]{10}$/.test(registerParam.phone)) {
      Toast.info("手机号码不正确或为空", 1);
      return Promise.reject();
    }

    return sendMsg({phone: registerParam.phone});
  }

  onFieldChange = (field, val, obj) => {
    let stateObj = {};
    if (obj) {
      this.state[obj][field] = val;
    } else {
      stateObj[field] = val;
    }  
    this.setState({...this.state, ...stateObj});
  }

  registerClick = () => {
    const {regist, history,location} = this.props;
    const { registerParam } = this.state;
    let isWechat = isWeChatBrowser();
    let search = parseQueryString(decodeURIComponent(location.search));
    let wechatKey = undefined;
    if (isWechat) {
      wechatKey = search.state;
    }
    if (!registerParam.phone) {
      Toast.info("手机号码不能为空", 1);
      return;
    }
    if (!registerParam.code) {
      Toast.info("验证码不能为空", 1);
      return;
    }
    if (!registerParam.password) {
      Toast.info("密码不能为空", 1);
      return;
    }
    regist(registerParam).then(res => {
      if (res.code === 0) {
        // history.push("/complete" + (isWechat ? "?state=" + wechatKey : ""));
        // if (isWechat) {
        //   window.location.href = getDomain() + "/wap/v2_0/weixin/login?currentUrl=complete";
        // } else {
          history.push("/complete");
        // }
      } else {
        Toast.info(res.transformError);
      }
    })
  }

  render() {
    const { agreement } = this.state;
    const { history} = this.props;
    return (
      <div className="register-container">
        <TopNavBar className="" title="注册" />
        <div className="register-core-wrapper">
          <CustomInput maxLength={11}
            onChange={(val) => this.onFieldChange("phone", val, "registerParam")}
            className="login-user" icon="iconmobile" placeholder="请输入手机号"></CustomInput>
          <CustomInput maxLength={6}
            onChange={(val) => this.onFieldChange("code", val, "registerParam")}
            className="login-verifycode" icon="iconsafetycertificate" 
            needVerifyCode={this.getVerifyCode} placeholder="请输入验证码"></CustomInput>
          <CustomInput 
            onChange={(val) => this.onFieldChange("password", val, "registerParam")}
            type="password" className="login-password" icon="iconlock" placeholder="请输入密码"></CustomInput>
          <Checkbox className="agreement-checkbox" onChange={e => this.onFieldChange("agreement", e.target.checked)}>
            <span className="read">我已阅读并同意</span>
            <a className="agreement" onClick={(e) => { e.preventDefault();  history.push("/agreement"); }}>《即派用户使用协议》</a>
          </Checkbox>
          <Button onClick={this.registerClick} type="primary" disabled className="login-btn" disabled={!agreement}>注册</Button>
          <Flex className="operate-area" justify="end" align="end">
            <span className="now-login" onClick={() => this.props.history.push("/login")}>立即登录</span>
          </Flex>
        </div>
      </div>
    );
  }
}

export default RegisterPage;
